import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SignInModel } from "../Pages/Session/SignIn/models/signin.model";

@Injectable({ providedIn: "root" })
export class UserService {
  private _isClientUser$ = new BehaviorSubject<boolean>(true);
  isClientUserObservable = this._isClientUser$.asObservable();

  get isClientUser() {
    return this._isClientUser$.value;
  }

  set isClientUser(newValue: boolean) {
    this._isClientUser$.next(newValue);
  }

  get user() {
    if (localStorage.getItem("bidUser"))
      return JSON.parse(localStorage.getItem("bidUser")) as SignInModel;
    else return JSON.parse(sessionStorage.getItem("bidUser")) as SignInModel;
  }
}
