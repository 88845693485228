import { Injectable, SkipSelf } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { FornecedorModel } from "../Shared/Models/Fornecedores/fornecedor.model";
import { BaseService } from "./base.service";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { ToastaService } from "ngx-toasta";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { FornecedorAvaliacaoNodeModel } from "../Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model";
import { AvaliacaoAtualizacaoModel } from "../Shared/Models/Fornecedores/avaliacao-atualizacao.model";
import { Observable } from "rxjs";
import { distinctUntilChanged, pluck, tap } from "rxjs/operators";
import { CadastrarServicoModel } from "../Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/cadastrar-servico.model";
import { AnuncioModel } from "../Shared/Models/Anuncios/anuncio.model";
import { DadosPerfilModel } from "../Pages/perfil-fornecedor/components/perfil-fornecedor/dados-perfil.model";
import { CidadeModel } from "../Shared/Models/Base/cidade.model";

@Injectable({
  providedIn: "root",
})
export class FornecedorService extends BaseService {
  private readonly url = environment.endpoints.backend + "/fornecedor";

  private httpBackend: HttpClient;

  constructor(
    toastaService: ToastaService,
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private loader: LoadingBarService,
    private _httpBackend: HttpBackend
  ) {
    super(toastaService, authenticationHelper);
    this.httpBackend = new HttpClient(_httpBackend);
  }

  pesquisarFornecedores(
    dadosPesquisa: any
  ): Observable<RespostaModel<FornecedorModel[]>> {
    return this.httpBackend.post<RespostaModel<FornecedorModel[]>>(
      `${this.url}/Pesquisar`,
      dadosPesquisa
    );
  }

  obterServicos(email: string): Observable<RespostaModel<any[]>> {
    return this.http.get<RespostaModel<any[]>>(
      `${this.url}/ObterServicos?email=${email}`
    );
  }

  adicionarAvaliacao(avaliacao: FornecedorAvaliacaoNodeModel) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(`${this.url}/AdicionarAvaliacao`, avaliacao)
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  atualizarAvaliacao(avaliacoes: AvaliacaoAtualizacaoModel) {
    return new Promise<FornecedorAvaliacaoNodeModel[]>((resolve, reject) => {
      return this.http
        .post<RespostaModel<FornecedorAvaliacaoNodeModel[]>>(
          `${this.url}/AtualizarAvaliacao`,
          avaliacoes
        )
        .subscribe(
          (response) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Resposta enviada com sucesso!";
            this.toastaService.success(this.toastOptions);
            this.loader.complete();
            resolve(response.dados);
          },
          (error) => {
            this.loader.complete();
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  buscarFornecedorPorNomeFantasia(nomeFantasia: string) {
    return new Promise<FornecedorModel>((resolve, reject) => {
      return this.http
        .get<RespostaModel<FornecedorModel>>(
          `${this.url}/ObterPeloNomeFantasia?nomeFantasia=${nomeFantasia}`
        )
        .subscribe(
          (response) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  postCadastrarNovoServico(
    fornecedorId: string,
    novoServico: CadastrarServicoModel
  ) {
    return this.http.post(
      `${this.url}/${fornecedorId}/servicoOfertado`,
      novoServico
    );
  }

  obterDetalhesFornecedorPorId(
    fornecedorId: string
  ): Observable<DadosPerfilModel> {
    return this.http
      .get(`${this.url}/obterPorId/${fornecedorId}`)
      .pipe(pluck("dados"));
  }

  getObterPorCidadeServico(dataToSearch: {
    cidadeId?: number;
    categoriaId?: number;
    nomeServico?: string;
    zonaRegiao?: string;
  }): Observable<Array<FornecedorModel>> {
    let params = new HttpParams();

    if (dataToSearch.cidadeId)
      params = params.set("cidadeId", dataToSearch.cidadeId.toString());
    if (dataToSearch.nomeServico)
      params = params.set("nomeServico", dataToSearch.nomeServico.toString());
    if (dataToSearch.categoriaId)
      params = params.set("categoriaId", dataToSearch.categoriaId.toString());
    if (dataToSearch.zonaRegiao)
      params = params.set("zonaRegiao", dataToSearch.zonaRegiao.toString());

    return this.http
      .get<Array<FornecedorModel>>(`${this.url}/ObterPorCidadeServico`, {
        params,
      })
      .pipe(pluck("dados"));
  }
  obterOrcamentosRecebidos(
    fornecedorId: string,
    ultimoMes: boolean
  ): Observable<Array<AnuncioModel>> {
    let params = new HttpParams();

    params = params.set("fornecedorId", fornecedorId);
    params = params.set("ultimoMes", ultimoMes.toString());

    return this.http
      .get<Array<FornecedorModel>>(`${this.url}/ObterOrcamentosRecebidos`, {
        params,
      })
      .pipe(pluck("dados"));
  }

  obterCidadesAtuacao(fornecedorId: string): Observable<Array<CidadeModel>> {
    return this.http
      .get<Array<CidadeModel>>(
        `${this.url}/ObterCidadesAtuacao/${fornecedorId}`
      )
      .pipe(pluck("dados"));
  }
}
