import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { UserService } from "./../../../../../../Services/user.service";
import { Component, Input, OnInit } from "@angular/core";
import { MeuPlanoModel } from "src/app/Shared/Models/Base/meu-plano.model";
import { Router } from "@angular/router";
import { PagamentoService } from "../../../../../../Services/pagamento.service";
import Swal from "sweetalert2";
import { AssinaturaModel } from "../../../../../../Shared/Models/assinatura.model";
import { Observable } from "rxjs";
import { TOKEN_NAME } from '../../../../../../Services/helpers/authentication-helper.service';
import { ToastaService, ToastOptions } from 'ngx-toasta';

export enum TipoPlano {
  Gratis = 1,
  Pago = 654,
}

@Component({
  selector: "app-meu-plano",
  templateUrl: "./meu-plano.component.html",
  styleUrls: ["./meu-plano.component.scss"],
})
export class MeuPlanoComponent implements OnInit {
  @Input()
  dadosDoPlano: MeuPlanoModel;

  tipoPlano = TipoPlano;
  user: SignInModel;
  historico$: Observable<AssinaturaModel[]>;

  toastOptions: ToastOptions = {
    title: '',
    msg: '',
    showClose: true,
    timeout: 5000,
    theme: 'material'
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private pagamentoService: PagamentoService,
    private toastaService: ToastaService
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.buscaHistorico();
  }

  redirectMyPlan() {
    this.router.navigate(["/premium"]);
  }

  cancelarAssinatura() {
    Swal.fire({
      title: "Cancelar plano Premium",
      icon: "question",
      text: "Confirmando essa ação você cancelará sua assinatura e perderá todos os beneficios, você tem certeza disso?",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Manter plano premium",
      confirmButtonText: "Sim, cancelar plano",
    }).then((action) => {
      if (action.isConfirmed) {
        this.pagamentoService
          .cancelarAssinatura(this.user.id, this.user.tipoUsuario)
          .then(response => {
            this.toastOptions.title = 'Sucesso';
            this.toastOptions.msg = 'Sua assinatura foi cancelada.';
            this.toastaService.success(this.toastOptions);
            localStorage.setItem('bidUser', JSON.stringify(response));
            localStorage.setItem(TOKEN_NAME, response.accessToken);

            this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/perfil-fornecedor']).then(() => {
                console.log(`After navigation I am on:${this.router.url}`);
              });
            });
          });
      }
    });
  }

  buscaHistorico() {
    this.historico$ = this.pagamentoService.obterHistorico(
      this.user.id,
      this.user.tipoUsuario
    );
  }
}
