import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { ServicosOfertadosAgrupadosModel } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/servicos-ofertados.model";
import { DadosPerfilModel } from "src/app/Pages/perfil-fornecedor/components/perfil-fornecedor/dados-perfil.model";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { UserService } from "src/app/Services/user.service";
import { OrcamentosModalComponent } from "src/app/Shared/components/orcamentos-modal/orcamentos-modal.component";
import { PerfilService } from "../../../../../../Services/perfil.service";
import { RedesSociaisEnum } from "../../../../../../Shared/Models/Base/Enums/rede-social.enum";
import { DetalhesFornecedorService } from "../../detalhes-fornecedor.service";
import { AvaliacaoModalComponent } from "./components/avaliacao-modal/avaliacao-modal.component";
import { CompartilharModalModalComponent } from "./components/compartilhar-modal/compartilhar-modal.component";
import { LoginModalComponent } from "./components/login-modal/login-modal.component";
import { TipoPlanoEnum } from "src/app/Shared/Models/Planos/Enums/tipo-plano.enum";
import { WHATSAPP_URL } from "src/app/Shared/utils/constants.utils";

enum MENU_OPTIONS {
  Perfil = 1,
  ProdutosServicos = 2,
  Perguntas = 3,
  Localizacao = 4,
}

@Component({
  selector: "app-detalhes-fornecedor",
  templateUrl: "./detalhes-fornecedor.component.html",
  styleUrls: ["./detalhes-fornecedor.component.scss"],
})
export class DetalhesFornecedorComponent implements OnInit {
  menuOptions = MENU_OPTIONS;
  selectedMenu = 1;

  fornecedorDetalhes = {} as DadosPerfilModel;
  servicos: Array<ServicosOfertadosAgrupadosModel> = [];
  fornecedorId: string;
  categoria: string;
  exibirModal = false;
  user: SignInModel;
  isPremium = false;
  redesSociais = {} as {
    Instagram: string;
    WhatsApp: string;
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: DetalhesFornecedorService,
    private userService: UserService,
    public dialog: MatDialog,
    private perfilService: PerfilService
  ) {
    this.user = userService.user;
    this.fornecedorId = this.activatedRoute.snapshot.params.id;
    this.categoria = this.activatedRoute.snapshot.params.categoria;
  }

  ngOnInit() {
    this.buscaDetalhesFornecedor();
    this.buscarServicos();
    this.obterRedesSociais();
  }

  openModal() {
    this.dialog.open(OrcamentosModalComponent, {
      width: "250px",
      data: {
        fornecedor: { id: this.fornecedorId, categoria: this.categoria },
        user: this.user,
      },
    });
  }

  redirectToWhatsapp() {
    window.open(WHATSAPP_URL, "_blank");
  }

  openCompartilharModal() {
    this.dialog.open(CompartilharModalModalComponent, {
      width: "250px",
      data: {
        fornecedor: this.fornecedorDetalhes!.nomeFantasia || "",
        link: window.location.href,
        user: this.user,
      },
    });
  }

  validarPermissaoAvaliar(): boolean {
    if (this.user && this.fornecedorDetalhes.avaliacoes) {
      const avaliacao = this.fornecedorDetalhes.avaliacoes.find(
        (x) => x.email === this.user.email
      );

      let jaAvaliado = !!avaliacao;
      return !jaAvaliado;
    } else {
      return true;
    }
  }

  openAvaliacaoModal() {
    if (!this.user) {
      this.dialog.open(LoginModalComponent, {
        width: "200px",
        data: {
          redirect: window.location.pathname,
        },
      });
    } else {
      this.dialog
        .open(AvaliacaoModalComponent, {
          data: {
            fornecedor: this.fornecedorDetalhes,
            usuarioLogado: this.user,
            fornecedorId: this.fornecedorId,
          },
        })
        .beforeClosed()
        .subscribe((response) => {
          this.buscaDetalhesFornecedor();
          this.validarPermissaoAvaliar();
        });
    }
  }

  private obterRedesSociais() {
    this.perfilService.obterRedesSociais(this.fornecedorId).then((resp) => {
      console.log("resp", resp);
      this.redesSociais.Instagram =
        resp.redeSociais.find(
          (rede) => rede.tipoRedeSocial === RedesSociaisEnum.Instagram
        ).link || "";

      this.redesSociais.WhatsApp =
        "https://wa.me/" +
          resp.redeSociais.find(
            (rede) => rede.tipoRedeSocial === RedesSociaisEnum.WhatsApp
          ).link || "";
    });
  }
  openInstagram() {
    console.log(this.redesSociais);
    window.open(this.redesSociais.Instagram, "blank");
  }

  openWhatsApp() {
    window.open(this.redesSociais.WhatsApp, "blank");
  }

  private buscaDetalhesFornecedor() {
    this.service
      .buscaDetalhesPorFornecedorId(this.fornecedorId)
      .subscribe((response) => {
        this.fornecedorDetalhes = response;
        this.isPremium = this.fornecedorDetalhes.tipoPlano !== TipoPlanoEnum.Gratis;
      });
  }

  private buscarServicos() {
    this.service
      .buscaServicosPorFornecedorId(this.fornecedorId)
      .subscribe((response) => {
        this.servicos = response;
      });
  }
}
