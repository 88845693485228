import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PropostaModel } from '../Shared/Models/Anuncios/proposta.model';
import { TipoUsuarioEnum } from '../Shared/Models/Base/Enums/role.enum';
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { AnuncioFechadoNotificacaoModel } from '../Shared/Models/Notificacoes/anuncio-fechado-notificacao.model';
import { AnuncioNotificacaoModel } from '../Shared/Models/Notificacoes/anuncio-notificacao.model';
import { AvaliacaoNotificacaoModel } from '../Shared/Models/Notificacoes/avaliacao-notificacao.model';
import { TipoNotificacaoEnum } from '../Shared/Models/Notificacoes/Enums/tipo-notificacao.enum';
import { NotificacaoModel } from '../Shared/Models/Notificacoes/notificacao.model';
import { ObterNotificacoesModel } from '../Shared/Models/Notificacoes/obter-notificacoes.model';
import { OrcamentoNotificacaoModel } from '../Shared/Models/Notificacoes/orcamento-notificacao.model';
import { PropostaAtualizacaoNotificacaoModel } from '../Shared/Models/Notificacoes/proposta-atualizacao-notificacao.model';
import { PropostaNotificacaoModel } from '../Shared/Models/Notificacoes/proposta-notificacao.model';
import { AuthenticationHelper } from './helpers/authentication-helper.service';

@Injectable()
export class NotificacaoService {

    private readonly url = environment.endpoints.backend + '/notificacao';
    private httpHeaders: HttpHeaders = new HttpHeaders({ loading: 'false' });

    constructor(private http: HttpClient, 
        private router: Router, 
        private authenticationHelper: AuthenticationHelper) { }

    obterNotificacao(clienteId: string): Observable<RespostaModel<ObterNotificacoesModel>> {
        return this.http.get<RespostaModel<ObterNotificacoesModel>>(`${this.url}/${clienteId}`, { headers: this.httpHeaders });
    }

    pesquisarNotificacoes(clienteId: string, pequisa: any): Observable<RespostaModel<any>> {
        return this.http.post<RespostaModel<any>>(`${this.url}/${clienteId}/Pesquisar`, pequisa);
    }

    marcarNotificacaoLida(clienteId: string, notificacaoId: string) {
        this.http.get(`${this.url}/${clienteId}/Marcar/${notificacaoId}`)
            .subscribe();
    }

    redirecionar(notificacao: NotificacaoModel) {

        if (notificacao.tipoNotificacao == TipoNotificacaoEnum.Anuncio) {
            const anuncio = notificacao as AnuncioNotificacaoModel;
            this.router.navigate(['/bids', anuncio.anuncioId]);
         }
         else if (notificacao.tipoNotificacao == TipoNotificacaoEnum.Orcamento) {
            const orcamento = notificacao as OrcamentoNotificacaoModel;
            this.router.navigate(['/bids', orcamento.anuncioId]);
         }
         else if (notificacao.tipoNotificacao == TipoNotificacaoEnum.AnuncioFechado) {
            const anuncioFechado = notificacao as AnuncioFechadoNotificacaoModel;
            this.router.navigate(['/bids', anuncioFechado.anuncioId]);
         }
         else if (notificacao.tipoNotificacao == TipoNotificacaoEnum.Proposta) {
            const proposta = notificacao as PropostaNotificacaoModel;
            this.router.navigate(['/bids', proposta.anuncioId]);
         }
         else if (notificacao.tipoNotificacao == TipoNotificacaoEnum.PropostaAtualizacao) {
            const propostaFavorita = notificacao as PropostaAtualizacaoNotificacaoModel;
            this.router.navigate(['/bids', propostaFavorita.anuncioId]);
         }
         else if (notificacao.tipoNotificacao == TipoNotificacaoEnum.Avaliacao) {
   
            if (this.authenticationHelper.obterTipoUsuario() == TipoUsuarioEnum.Fornecedor) {
               const usuarioLogadoId = this.authenticationHelper.obterTokenModel().cliente;
               this.router.navigate(['/conta', usuarioLogadoId, { outlets: { conta: ['avaliacoes'] } }]);
            }
            else {
               const avaliacao = notificacao as AvaliacaoNotificacaoModel;
               const url = avaliacao.autor.replace(/ /g, '-');
               this.router.navigate(['/fornecedores', url, { aba: 1 }]);
            }
         }
         else if (notificacao.tipoNotificacao == TipoNotificacaoEnum.ServicosOfertados) {
            this.router.navigate(['bids/meus-interesses']);
         }
    }
}