export enum CategoriaServicoEnum {
  Nenhum = -1,
  Assessoria = 1,
  Aluguel = 2,
  Bar = 3,
  Barraquinhas = 4,
  Doces = 5,
  Buffet = 6,
  Cozinheiros = 7,
  Decoração = 8,
  Estrutura = 9,
  Espaços = 10,
  FotoFilmagem = 11,
  Lanches = 12,
  Papelaria = 13,
  Entretenimento = 14,
  FestasDelivery = 15,
  Garçom = 16,
  localeCompare
}