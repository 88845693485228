import { Component, ElementRef, OnInit, ViewChild, HostListener,  } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { PerfilService } from "src/app/Services/perfil.service";
import { UserService } from "src/app/Services/user.service";
import { TipoUsuarioEnum } from "src/app/Shared/Models/Base/Enums/role.enum";
import { TipoPlanoEnum } from "src/app/Shared/Models/Planos/Enums/tipo-plano.enum";
import { WHATSAPP_URL } from "src/app/Shared/utils/constants.utils";
import { EmbryoService } from "../../../Services/Embryo.service";
import { SignInModel } from "../../Session/SignIn/models/signin.model";
import { DadosPerfilModel } from "../../perfil-fornecedor/components/perfil-fornecedor/dados-perfil.model";
import { Observable } from "rxjs";
import { FornecedorMelhorAvaliadoVmModel } from "src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-melhor-avaliado-vm.model";
import { map } from "rxjs/operators";
import { FornecedorModel } from "src/app/Shared/Models/Fornecedores/fornecedor.model";
import { event } from "jquery";

declare const PagSeguro: any;

@Component({
  selector: "app-homeone",
  templateUrl: "./HomeOne.component.html",
  styleUrls: ["./HomeOne.component.scss"],
})
export class HomeoneComponent implements OnInit {
  @ViewChild("plans", { static: false }) plansElement: ElementRef;
  @ViewChild("slickModal", { static: false }) slickModal: any;
  @ViewChild("slickModal2", { static: false }) slickModal2: any;

  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }

  pagSeguro: any;

  mobileView: boolean;
  images: string[];
  anuncios = [];
  todosAnuncios = [];
  hasNextSlide = false;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    prevArrow:
      '<i class="fas fa-angle-left clr-gry" style="cursor:pointer;position: absolute;left: -30px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>',
    nextArrow:
      '<i class="fas fa-angle-right clr-gry" style="cursor:pointer;top:0;position: absolute;right: -30px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>',
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  slideConfigEmAndamento = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    prevArrow: (window.innerWidth <= 768) ?
      '<i class="fas fa-angle-left clr-gry" style="z-index=1;cursor:pointer;top:-100px;position: absolute;left: -15px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>' :
      '<i class="fas fa-angle-left clr-gry" style="cursor:pointer;position: absolute;left: -40px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>',
    nextArrow: (window.innerWidth <= 768) ?
      '<i class="fas fa-angle-right clr-gry" style="z-index=1;cursor:pointer;top:-100px;position: absolute;right: -15px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>' : 
      '<i class="fas fa-angle-right clr-gry" style="cursor:pointer;top:0;position: absolute;right: 25px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  slideConfigToHome = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  slideConfigToFornecedor = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 5000,
    autoplay: false,
    dots: true,
    infinite: false,
    lazyLoad: "progressive",
    prevArrow:
      '<i class="fas fa-angle-left clr-gry" style="cursor:pointer;position: absolute;left: -30px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>',
    nextArrow:
      '<i class="fas fa-angle-right clr-gry" style="cursor:pointer;top:0;position: absolute;right: -30px;height: 100%;display: flex;align-items: center;font-size: 30px;color: #1d70b7;"></i>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  menuOptions = [
    // { label: "Todos", iconPath: "", value: 0 },
    {
      label: "Todos",
      iconPath: "assets/images/home/icons/all.svg",
      value: 0,
    },
    {
      label: "Assessoria",
      iconPath: "assets/images/home/icons/assessoria.svg",
      value: 1,
    },
    {
      label: "Aluguel",
      iconPath: "assets/images/home/icons/aluguel.svg",
      value: 2,
    },
    { label: "Bar", iconPath: "assets/images/home/icons/bar.svg", value: 3 },
    {
      label: "Barraquinhas",
      iconPath: "assets/images/home/icons/barraquinhas.svg",
      value: 4,
    },
    { label: "Doces", iconPath: "assets/images/home/icons/doce.svg", value: 5 },
    {
      label: "Buffet",
      iconPath: "assets/images/home/icons/buffet.svg",
      value: 6,
    },
    {
      label: "Decoração",
      iconPath: "assets/images/home/icons/decoracao.svg",
      value: 8,
    },
    {
      label: "Fotos / Vídeo",
      iconPath: "assets/images/home/icons/camera.svg",
      value: 11,
    },
    {
      label: "Entretenimento",
      iconPath: "assets/images/home/icons/entretenimento.svg",
      value: 9,
    },
  ];
  activeMenu = 1;
  user: SignInModel;
  isClientUser: boolean;
  tipoPlano: TipoPlanoEnum;
  fornecedoresBemAvaliados$: Observable<FornecedorMelhorAvaliadoVmModel[]>;
  fornecedoresBemAvaliadosPorCategoria$: Observable<FornecedorMelhorAvaliadoVmModel[]>;
  isClientUserParam = false;
  fornecedoresPorPesquisa: Array<FornecedorMelhorAvaliadoVmModel> | null = null;

  constructor(
    private anuncioService: AnuncioService,
    public embryoService: EmbryoService,
    private userService: UserService,
    private router: Router,
    private perfilService: PerfilService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.verifyScreenSize();
    this.user = this.userService.user;

    this.images = [
      "assets/images/home/001.jpg",
      "assets/images/home/002.jpg",
      "assets/images/home/003.jpg",
      "assets/images/home/004.jpg",
      "assets/images/home/005.jpg",
      "assets/images/home/006.jpg",
      "assets/images/home/007.jpg",
    ];

    this.userService.isClientUserObservable.subscribe((client) => {
      this.isClientUser = client;
    });

    this.ativarVisualizacaoHomeDeClienteParaFornecedor();

    this.anuncioService.obterMenu().subscribe((resposta) => {
      this.todosAnuncios = resposta.dados.slice();
      this.anuncios = resposta.dados.slice();
    });

    if (this.user) this.obtemDadosPerfil();

    this.obterFornecedoresMelhoresAvaliados();

    if (this.mobileView) {
      // console.log('Mudar para mobile aqui!!!');
    }
  }

  private verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }

  mudarTipoCliente() {
    this.userService.isClientUser = false;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  mudarCategoriaServico(catedoria: number) {
    this.activeMenu = catedoria;
    console.log(catedoria);
    if (catedoria === 0) {
      this.anuncios = this.todosAnuncios.slice();
      this.fornecedoresPorPesquisa = null;
    } else {
      this.anuncios = this.todosAnuncios.filter(
        (x) => x.categoria === catedoria
      );
      
      this.obterFornecedoresMelhoresAvaliadosPorCategoria(catedoria);

      this.fornecedoresBemAvaliadosPorCategoria$.subscribe(f => 
        this.fornecedoresPorPesquisa = f);
    }
  }

  redirectToWhatsapp() {
    window.open(WHATSAPP_URL, "_blank");
  }

  redirectToRegister() {
    this.router.navigate(["/session/customer-type"]);
  }

  redirectToMyPlan() {
    this.router.navigate(["/premium"]);
  }
  redirectToNewBid() {
    this.router.navigate(["/perfil/novo-bid"]);
  }
  obtemDadosPerfil() {
    this.perfilService
      .obterDadosPerfil(this.user.id, this.user.tipoUsuario)
      .then((resp: DadosPerfilModel) => {
        this.tipoPlano = resp.meuPlano.tipoPlano;
      });
  }
  abrirDetalhesDoBid(id: string) {
    this.router.navigate(["detalhes-bid"], {
      queryParams: { id: id, route: "perfil-fornecedor" },
    });
  }
  scrollToPlans() {
    this.plansElement.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  afterChange(e) {
    var nextSlide = e.nextSlide;
    var currentSlide = e.currentSlide;
    if (
      (typeof nextSlide === "undefined" || typeof nextSlide === null) &&
      currentSlide !== 0 &&
      !this.hasNextSlide
    ) {
      this.hasNextSlide = false;
      this.slickModal.slickGoTo(0);
    } else {
      if (!this.hasNextSlide) {
        this.hasNextSlide = true;
        var qtdeSlides = e.slick.slideCount;
        this.slickModal.slickGoTo(Math.trunc(qtdeSlides / 4) * 4);
      } else {
        this.hasNextSlide = false;
      }
    }
  }

  beforeChange(e) {
    this.hasNextSlide = true;
  }

  private obterFornecedoresMelhoresAvaliados() {
    this.fornecedoresBemAvaliados$ =
      this.perfilService.obterTodosOsFornecedores();
    console.log(this.fornecedoresBemAvaliados$, "fornecedores");
  }

  private obterFornecedoresMelhoresAvaliadosPorCategoria(categoriaId?: number) {
    this.fornecedoresBemAvaliadosPorCategoria$ =
      this.perfilService.getObterPorCidadeServico({categoriaId});
  }  

  private ativarVisualizacaoHomeDeClienteParaFornecedor() {
    this.isClientUserParam =
      this.activatedRoute.snapshot.queryParams["isClientUserParam"] || false;
    if (this.isClientUserParam) this.isClientUser = true;
  }
}
