import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NotificacaoService } from 'src/app/Services/notificacao.service';
import { NotificacaoModel } from 'src/app/Shared/Models/Notificacoes/notificacao.model';

@Directive({
  selector: '[appNotificacaoMarcarLido]'
})
export class NotificacaoMarcarLidoDirective {

  private clicks = new Subject();
  private subscription: Subscription;

  @Input() clienteId: string;
  @Input() notificacao: NotificacaoModel;

  @Output() bidClick = new EventEmitter();

  @HostListener('click', ['$event']) clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    
    if (!this.notificacao.lido) {
      this.notificacaoService
        .marcarNotificacaoLida(this.clienteId, this.notificacao.id);
      this.notificacao.lido = true;
    }
    
    this.clicks.next(event);
  }

  constructor(
    private notificacaoService: NotificacaoService
  ) 
  { }

  ngOnInit(): void {
    this.subscription = this.clicks
      .subscribe(e => this.bidClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
