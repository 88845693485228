import { Component, OnInit } from "@angular/core";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { NotificacaoService } from "src/app/Services/notificacao.service";
import { UserService } from "src/app/Services/user.service";
import { NotificacaoModel } from "src/app/Shared/Models/Notificacoes/notificacao.model";

@Component({
  selector: "app-notificacoes",
  templateUrl: "./notificacoes.component.html",
  styleUrls: ["./notificacoes.component.scss"],
})
export class NotificacoesComponent implements OnInit {
  user: SignInModel;
  notificacoes: Array<NotificacaoModel> = [];

  constructor(
    public userService: UserService,
    private notificacaoService: NotificacaoService
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.getNotificacoes();
  }

  private getNotificacoes() {
    this.notificacaoService
      .obterNotificacao(this.user.id)
      .subscribe((response) => {
        this.notificacoes = response.dados.notificacoes;
      });
  }
}
