import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phoneFormated",
})
export class FormatPhonePipe implements PipeTransform {
  transform(phone: string) {
    if (phone) {
      let phoneMaskModel = {} as {
        country: string;
        areaCode: string;
        firstSection: string;
        lastSection: string;
        phoneFormated: string;
      };

      switch (phone.length) {
        case 14:
          phoneMaskModel.country = phone.slice(0, 3);
          phoneMaskModel.areaCode = phone.slice(3, 5);
          phoneMaskModel.firstSection = phone.slice(5, 10);
          phoneMaskModel.lastSection = phone.slice(10, 15);
          phoneMaskModel.phoneFormated = `${phoneMaskModel.country} (${phoneMaskModel.areaCode}) ${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        case 13:
          phoneMaskModel.country = phone.slice(0, 3);
          phoneMaskModel.areaCode = phone.slice(3, 5);
          phoneMaskModel.firstSection = phone.slice(5, 10);
          phoneMaskModel.lastSection = phone.slice(10, 14);
          phoneMaskModel.phoneFormated = `${phoneMaskModel.country} (${phoneMaskModel.areaCode}) ${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        case 12:
          phoneMaskModel.country = phone.slice(0, 2);
          phoneMaskModel.areaCode = phone.slice(2, 4);
          phoneMaskModel.firstSection = phone.slice(4, 8);
          phoneMaskModel.lastSection = phone.slice(8, 13);
          phoneMaskModel.phoneFormated = `+${phoneMaskModel.country} (${phoneMaskModel.areaCode}) ${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        case 11:
          phoneMaskModel.areaCode = phone.slice(0, 2);
          phoneMaskModel.firstSection = phone.slice(2, 7);
          phoneMaskModel.lastSection = phone.slice(7, 12);
          phoneMaskModel.phoneFormated = `(${phoneMaskModel.areaCode}) ${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        case 10:
          phoneMaskModel.areaCode = phone.slice(0, 2);
          phoneMaskModel.firstSection = phone.slice(2, 6);
          phoneMaskModel.lastSection = phone.slice(6, 11);
          phoneMaskModel.phoneFormated = `(${phoneMaskModel.areaCode}) ${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        case 9:
          phoneMaskModel.firstSection = phone.slice(0, 5);
          phoneMaskModel.lastSection = phone.slice(5, 10);
          phoneMaskModel.phoneFormated = `${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        case 8:
          phoneMaskModel.firstSection = phone.slice(0, 4);
          phoneMaskModel.lastSection = phone.slice(4, 9);
          phoneMaskModel.phoneFormated = `${phoneMaskModel.firstSection}-${phoneMaskModel.lastSection}`;
          break;

        default:
          return phone;
      } 
      return phoneMaskModel.phoneFormated;
    }
  }
}
