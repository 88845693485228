import { ImagemModel } from '../../Base/imagem.model';

export class FornecedorAvaliacaoNodeModel {

    public fornecedorId: string;

    public email: string;
    public nota: number;
    public estaLogado: boolean;
    public estaAbertoParaResposta: boolean;

    public usuarioId: string;

    public autorId: string;
    public autor: string;
    public texto: string;
    public imagem: string;
    public nome: string;


    public data: Date;

    public respostas: Array<FornecedorAvaliacaoNodeModel>
        = new Array<FornecedorAvaliacaoNodeModel>();

    public imagens?: ImagemModel[];

    constructor(values?: Partial<FornecedorAvaliacaoNodeModel>) {
        Object.assign(this, values);
    }
}
