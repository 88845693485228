import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {

  @Input() images: string[];

  showCarousel = false;

  constructor() { }

  ngOnInit() {
    this.buildImages();
  }

  shuffle(arra1) {
    let ctr = arra1.length;
    let temp;
    let index;

    while (ctr > 0) {
      index = Math.floor(Math.random() * ctr);
      ctr--;
      temp = arra1[ctr];
      arra1[ctr] = arra1[index];
      arra1[index] = temp;
    }

    return arra1;
  }

  buildImages() {
    if (this.images === null) {
      return;
    }

    this.showCarousel = true;

    /*this.images = [
      'assets/images/home/001.jpg',
      'assets/images/home/002.jpg',
      'assets/images/home/003.jpg',
      'assets/images/home/004.jpg',
      'assets/images/home/005.jpg',
      'assets/images/home/006.jpg',
      'assets/images/home/007.jpg'
    ];*/

    this.images = this.shuffle(this.images);
  }

}
