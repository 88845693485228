import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { EnviarOrcamentoVmModel } from "../Shared/Models/Anuncios/ViewModels/enviar-orcamento-vm.model";
import { BaseService } from "./base.service";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { ToastaService } from "ngx-toasta";
import { OrcamentoPesquisarVmModel } from "../Shared/Models/Anuncios/ViewModels/orcamento-pesquisar-vm.model";
import { ExcluirPropostaOrcamentoVmModel } from "../Shared/Models/Anuncios/ViewModels/excluir-pedido-orcamento-vm.model";
import { ObterOrcamentoVmModel } from "../Shared/Models/Anuncios/ViewModels/obter-orcamento-vm.model";

@Injectable()
export class OrcamentoService extends BaseService {
  private readonly url = environment.endpoints.backend + "/Orcamento";
  private httpHeaders: HttpHeaders = new HttpHeaders({ loading: "false" });

  constructor(
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    toastaService: ToastaService
  ) {
    super(toastaService, authenticationHelper);
  }

  obterOrcamentos(
    dadosOrcamento: ObterOrcamentoVmModel,
    exibirLoading: boolean = true
  ) {
    const requestHeader = exibirLoading ? undefined : this.httpHeaders;
    return this.http.post<RespostaModel<any>>(
      `${this.url}/ObterOrcamentos`,
      dadosOrcamento,
      {
        headers: requestHeader,
      }
    );
  }

  obterMeusOrcamentos(clienteId: string) {
    return this.http.get<RespostaModel<any>>(
      `${this.url}/ObterOrcamentos/?clienteId=${clienteId}`
    );
  }

  enviarOrcamento(orcamento: EnviarOrcamentoVmModel) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.url}/EnviarOrcamento`, orcamento).subscribe(
        (data) => {
          this.toastOptions.title = "Sucesso!";
          this.toastOptions.msg = "Orçamento enviado com sucesso!";
          resolve(null);
        },
        (error) => {
          this.manipuladorDeErro(error);
          reject();
        }
      );
    });
  }
  enviarOrcamentoObservable(orcamento: EnviarOrcamentoVmModel) {
    return this.http.post(`${this.url}/EnviarOrcamento`, orcamento);
  }

  pesquisarOrcamentos(dadosPesquisa: OrcamentoPesquisarVmModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.url}/PesquisarOrcamentos`, dadosPesquisa)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  excluirPedidoOrcamento(orcamento: ExcluirPropostaOrcamentoVmModel) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.url}/ExcluirPropostaOrcamento`, orcamento)
        .subscribe(
          (data) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Pedido de Orçamento excluído com sucesso!";
            this.toastaService.success(this.toastOptions);
            resolve(null);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }
}
