import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { OrcamentoService } from "src/app/Services/orcamento.service";
import Swal from "sweetalert2";
import { EnviarOrcamentoVmModel } from "../../Models/Anuncios/ViewModels/enviar-orcamento-vm.model";
import { AnuncioModel } from "../../Models/Anuncios/anuncio.model";
import { CategoriaServicoEnum } from "../../Models/Servicos/Enums/categoria-servico.enum";

@Component({
  selector: "app-orcamentos-modal",
  templateUrl: "./orcamentos-modal.component.html",
  styleUrls: ["./orcamentos-modal.component.scss"],
})
export class OrcamentosModalComponent implements OnInit {
  @Input()
  fornecedor: { id: string; categoria: CategoriaServicoEnum };

  @Input()
  user: SignInModel;

  orcamentos: Array<AnuncioModel> = [];
  orcamentoSelecionado: string;
  categoriaServicoEnum = CategoriaServicoEnum;
  constructor(
    private anuncioService: AnuncioService,
    private orcamentoService: OrcamentoService,
    private router: Router,
    private dialogRef: MatDialogRef<OrcamentosModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fornecedor: { id: string; categoria: CategoriaServicoEnum };
      user: SignInModel;
    }
  ) {}

  ngOnInit() {
    this.buscarOrcamentos();
  }

  buscarOrcamentos() {
    this.anuncioService
      .buscarOrcamentosPorClienteIdEFornecedorId(
        this.data.user.id,
        this.data.fornecedor.id
      )
      .subscribe((response) => {
        this.orcamentos = response;
      });
  }

  enviarOrcamento() {
    const data = {
      anuncioId: this.orcamentoSelecionado,
      clienteEmail: this.data.user.email,
      clienteId: this.data.user.id,
      fornecedorId: this.data.fornecedor.id,
      tipoUsuario: this.data.user.tipoUsuario,
    } as EnviarOrcamentoVmModel;

    this.orcamentoService.enviarOrcamentoObservable(data).subscribe(
      (r) => {
        Swal.fire("Sucesso", "Orçamento enviado com sucesso!", "success");
        this.closeModal();
      },
      (error) => 
        {
          Swal.fire("Atenção", error.error.mensagem, "warning");
          //this.orcamentoService.manipuladorDeErro(error)
        }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
  redirectToBudget() {
    this.closeModal();
    this.router.navigate(["/perfil/orcamento"]);
  }
}
