
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationHelper } from './helpers/authentication-helper.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationHelper: AuthenticationHelper) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationHelper.estaLogado()) {
      return true;
    }

    this.router.navigate(['/session/signin'], 
      {
        queryParams: {
          redirect: state.url
        }
      }
    );
    return false;
  }
}
