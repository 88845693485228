import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { BidAccessControlDialogModel } from 'src/app/Shared/Models/Base/bid-access-control-dialog.model';

@Component({
  selector: 'app-bid-access-control-dialog',
  templateUrl: './BidAccessControlDialog.component.html',
  styleUrls: ['./BidAccessControlDialog.component.css']
})
export class BidAccessControlDialogComponent implements OnInit {

  constructor(public dialogRef : MatDialogRef<BidAccessControlDialogComponent>,
      private router: Router,
      @Inject(MAT_DIALOG_DATA) public data: BidAccessControlDialogModel
    ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  redirecionarPremium() {
    this.router.navigate(['/premium']);
    this.dialogRef.close();
  }

  exibirMensagemErro() {
    return (this.data && this.data.mensagem);
  }

}
