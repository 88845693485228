import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
} from "@angular/material";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { BarRatingModule } from "ngx-bar-rating";
import { CurrencyMaskModule } from "ngx-currency-mask";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxMaskModule } from "ngx-mask";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { RatingComponent } from "../Global/Rating/Rating.component";
import { TimerCountDownSmallerComponent } from "../Global/TimerCountDownSmaller/TimerCountDownSmaller.component";
import { AvaliacaoModalComponent } from "../Pages/Home/pages/detalhes-fornecedor/components/detalhes-fornecedor/components/avaliacao-modal/avaliacao-modal.component";
import { CompartilharModalModalComponent } from "../Pages/Home/pages/detalhes-fornecedor/components/detalhes-fornecedor/components/compartilhar-modal/compartilhar-modal.component";
import { LoginModalComponent } from "../Pages/Home/pages/detalhes-fornecedor/components/detalhes-fornecedor/components/login-modal/login-modal.component";
import { FornecedorPesquisaComponent } from "../Pages/Home/pages/fornecedor-pesquisa/fornecedor-pesquisa.component";
import { BidButtonComponent } from "./components/bid-button/bid-button.component";
import { InProgressBidsHorizontalComponent } from "./components/in-progress-bids-horizontal/in-progress-bids-horizontal.component";
import { InProgressBidsComponent } from "./components/in-progress-bids/in-progress-bids.component";
import { NewServiceModalComponent } from "./components/new-service-modal/new-service-modal.component";
import { OrcamentosModalComponent } from "./components/orcamentos-modal/orcamentos-modal.component";
import { ServicesAndCompaniesComponent } from "./components/services-and-companies/services-and-companies.component";
import { FormatPhonePipe } from "./pipes/format-phone.pipe";
import { MediaAvaliacaoPipe } from "./pipes/media-avaliacao.pipe";
import { GetCategoriaPorIdPipe } from "./pipes/get-categoria-por-id.pipe";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SlickCarouselModule,
    NgxMaskModule.forRoot({}),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    CurrencyMaskModule,
    // MatCardModule,
    // MatButtonModule,
    // MatMenuModule,
    // MatToolbarModule,
    // MatIconModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    // MatProgressSpinnerModule,
    // MatTableModule,
    // MatExpansionModule,
    // MatSelectModule,
    // MatSnackBarModule,
    MatTooltipModule,
    // MatChipsModule,
    // MatListModule,
    // MatSidenavModule,
    // MatTabsModule,
    // MatProgressBarModule,
    // MatCheckboxModule,
    // MatSliderModule,
    // MatRadioModule,
    // MatGridListModule,
    //GlobalModule,
    FlexLayoutModule,
    ImageCropperModule,
    //GlobalModule,
    FlexLayoutModule,
    ImageCropperModule,
    BarRatingModule,
  ],
  declarations: [
    BidButtonComponent,
    ServicesAndCompaniesComponent,
    InProgressBidsComponent,
    TimerCountDownSmallerComponent,
    InProgressBidsHorizontalComponent,
    FornecedorPesquisaComponent,
    OrcamentosModalComponent,
    CompartilharModalModalComponent,
    NewServiceModalComponent,
    FormatPhonePipe,
    MediaAvaliacaoPipe,
    LoginModalComponent,
    AvaliacaoModalComponent,
    NewServiceModalComponent,
    RatingComponent,
    GetCategoriaPorIdPipe,
  ],
  exports: [
    BidButtonComponent,
    ServicesAndCompaniesComponent,
    InProgressBidsComponent,
    TimerCountDownSmallerComponent,
    InProgressBidsHorizontalComponent,
    NgxMaskModule,
    OrcamentosModalComponent,
    CompartilharModalModalComponent,
    LoginModalComponent,
    AvaliacaoModalComponent,
    FornecedorPesquisaComponent,
    NewServiceModalComponent,
    CurrencyMaskModule,
    FormatPhonePipe,
    MediaAvaliacaoPipe,
    RatingComponent,
    GetCategoriaPorIdPipe,
    MatTooltipModule
  ],
  entryComponents: [
    CompartilharModalModalComponent,
    LoginModalComponent,
    AvaliacaoModalComponent,
    OrcamentosModalComponent,
    NewServiceModalComponent,
  ],
})
export class SharedModule {}
