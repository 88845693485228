import { Component, Input, OnInit } from "@angular/core";
import { PerguntasFrequentesModel } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/models/perguntas-frequentes.model";
import { DetalhesFornecedorService } from "../../../../detalhes-fornecedor.service";

@Component({
  selector: "app-aba-perguntas",
  templateUrl: "./aba-perguntas.component.html",
  styleUrls: ["./aba-perguntas.component.scss"],
})
export class AbaPerguntasComponent implements OnInit {
  @Input()
  fornecedorId: string;

  perguntas: Array<PerguntasFrequentesModel> = [];

  constructor(private service: DetalhesFornecedorService) {}

  ngOnInit() {
    this.obterPerguntas();
  }

  private obterPerguntas() {
    this.service
      .buscaPerguntasPorFornecedorId(this.fornecedorId)
      .subscribe((response) => {
        this.perguntas = response;
      });
  }
}
