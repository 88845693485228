import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { BidAccessControlDialogModel } from "src/app/Shared/Models/Base/bid-access-control-dialog.model";

@Component({
  selector: "app-premium-active-dialog",
  templateUrl: "./PremiumActiveDialog.component.html",
  styleUrls: ["./PremiumActiveDialog.component.css"],
})
export class PremiumActiveDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PremiumActiveDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: BidAccessControlDialogModel
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
