import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PerfilFornecedorComponent } from "./components/perfil-fornecedor/perfil-fornecedor.component";
import { LoggedGuard } from "src/app/Core/guards/logged-user.guard";
import { FornecedorGuard } from "src/app/Core/guards/fornecedor.guard";

const routes: Routes = [
  {
    path: "",
    component: PerfilFornecedorComponent,
    canActivate: [LoggedGuard, FornecedorGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PerfilFornecedorRoutingModule {}
