export enum TipoAcessoEnum {

    Perfil,
    LinkedIn,
    Facebook,
    GooglePlus,
    Instagram,
    Twitter,
    WhatsApp,
    YouTube,
    WebSite

}