import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PropostaModel } from 'src/app/Shared/Models/Anuncios/proposta.model';
import { PropostaService } from 'src/app/Services/proposta.service';
import { saveAs } from 'file-saver/src/FileSaver';

@Component({
   selector: 'embryo-PropostaView',
   templateUrl: './PropostaView.component.html',
   styleUrls: ['./PropostaView.component.scss']
})
export class PropostaViewComponent implements OnInit {

   @Input() propostas;
   @Input() donoDoAnuncio;
   @Input() clienteLogado;
   @Output() marcarFavorito = new EventEmitter<{ index: number, propostas: Array<PropostaModel> }>();
   @Output() marcarSelecionada = new EventEmitter<{ index: number, propostas: Array<PropostaModel> }>();
   @Output() marcarDesafiar = new EventEmitter<{ index: number, propostas: Array<PropostaModel> }>();
   constructor(
      private propostaService: PropostaService
   ) { }

   ngOnInit() {
   }


   marcarComoFavorito(propostas, index) {
      this.marcarFavorito.emit({ index: index, propostas: this.propostas });
   }

   marcarComoDesafiar(propostas, index) {
      this.marcarDesafiar.emit({ index: index, propostas: this.propostas });
   }


   marcarComoSelecionada(propostas, index) {
      this.marcarSelecionada.emit({ index: index, propostas: this.propostas });
   }

   downloadAnexo($event, url, nomeArquivo) {
      $event.preventDefault();
      $event.stopPropagation();
      
      this.propostaService.downloadAnexo(url, nomeArquivo);
   }

}
