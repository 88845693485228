import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { BaseService } from "./base.service";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { Router } from "@angular/router";
import { ToastaService } from "ngx-toasta";
import { TipoUsuarioEnum } from "../Shared/Models/Base/Enums/role.enum";
import { AssinaturaModel } from "../Shared/Models/assinatura.model";
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class PagamentoService extends BaseService {
  private readonly url = environment.endpoints.backend + "/Pagamento";

  constructor(
    toastaService: ToastaService,
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private router: Router
  ) {
    super(toastaService, authenticationHelper);
  }

  realizarAssinaturaRecorrente(assinatura: any) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .post<RespostaModel<any>>(
          `${this.url}/realizar-assinatura-recorrente`,
          assinatura
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  realizarAssinaturaPix(planoId: any) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .post<RespostaModel<any>>(`${this.url}/realizar-assinatura-pix`, {
          planoId,
        })
        .subscribe(
          (res) => {
            if (res && res.dados) {
              resolve(res.dados);
            } else {
              reject();
            }
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  cancelarAssinatura(id: string, tipoUsuario: TipoUsuarioEnum) {
    let params = new HttpParams();
    params = params.set("id", id);
    params = params.set("tipoUsuario", tipoUsuario.toString());

    return new Promise<any>((resolve, reject) => {
      this.http
        .put<RespostaModel<any>>(
          `${this.url}/cancelar-assinatura`,
          {},
          { params }
        )
        .pipe(pluck('dados'))
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  obterHistorico(
    id: string,
    tipoUsuario: TipoUsuarioEnum
  ): Observable<AssinaturaModel[]> {
    let params = new HttpParams();
    params = params.set("id", id);
    params = params.set("tipoUsuario", tipoUsuario.toString());

    return this.http
      .get<RespostaModel<AssinaturaModel[]>>(`${this.url}/busca-historico`, {
        params,
      })
      .pluck("dados");
  }
}
