import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import {
  CadastrarServicoAoServicoOfertadoModel,
  EditarServicoAoServicoOfertadoModel,
} from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/cadastrar-servico.model";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { PerfilService } from "src/app/Services/perfil.service";
import { ServicoModel } from "src/app/Shared/Models/Servicos/servico.model";
import { ImagemModel } from "../../Models/Base/imagem.model";

@Component({
  selector: "new-service-modal",
  templateUrl: "./new-service-modal.component.html",
  styleUrls: ["./new-service-modal.component.scss"],
})
export class NewServiceModalComponent implements OnInit {
  @Input()
  user: SignInModel;
  @Input()
  serviceId: number;
  @Input()
  categoriaId: number;  
  @Input()
  servico: ServicoModel = null;
  @Input()
  isDelete = false;
  @Input()
  isEdit = false;

  file1: File | null = null;
  file2: File | null = null;
  file3: File | null = null;
  eventListenerToImg1: any;
  eventListenerToImg2: any;
  eventListenerToImg3: any;
  primeiraImagem: { base64: string; descricao: string };
  segundaImagem: { base64: string; descricao: string };
  terceiraImagem: { base64: string; descricao: string };
  formAddService!: FormGroup;
  urlImage1 = "";
  urlImage2 = "";
  urlImage3 = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private perfilService: PerfilService,
    private dialogRef: MatDialogRef<NewServiceModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      categoriaId: number;
      serviceId: number;
      user: SignInModel;
      servico: ServicoModel;
      isDelete: boolean;
      removeAll?: boolean;
    }
  ) {
    this.formAddService = formBuilder.group({
      nome: ["", Validators.required],
      valor: ["", Validators.required],
      descricao: ["", Validators.required],
    });

    if (this.data.servico && !this.data.isDelete) {
      this.formAddService.patchValue({
        nome: this.data.servico.nome,
        valor: this.data.servico.valor.toString(),
        descricao: this.data.servico.descricao,
      });

      const properties = ["urlImage1", "urlImage2", "urlImage3"];

      for (let i = 0; i < this.data.servico.imagens.length; i++) {
        this[properties[i]] = this.data.servico.imagens[i].url;
      }
    }
  }

  ngOnInit() {
    //this.buscarOrcamentos();
  }

  setFile1(event) {
    this.urlImage1 = "";
    this.file1 = event.target.files[0];
    this.eventListenerToImg1 = event;
  }
  setFile2(event) {
    this.urlImage2 = "";
    this.file2 = event.target.files[0];
    this.eventListenerToImg2 = event;
  }
  setFile3(event) {
    this.urlImage3 = "";
    this.file3 = event.target.files[0];
    this.eventListenerToImg3 = event;
  }
  imageCropped1(event: any) {
    this.primeiraImagem = {
      base64: event.original.base64,
      descricao: this.file1.name,
    };
  }
  imageCropped2(event: any) {
    this.segundaImagem = {
      base64: event.original.base64,
      descricao: this.file2.name,
    };
  }
  imageCropped3(event: any) {
    this.terceiraImagem = {
      base64: event.original.base64,
      descricao: this.file3.name,
    };
  }

  adicionarServicoAoServicoOfertado() {
    const form = this.formAddService.value as ServicoModel;

    const data = {
      categoria: this.data.categoriaId,
      descricao: form.descricao,
      nome: form.nome,
      valor: form.valor,
    } as CadastrarServicoAoServicoOfertadoModel;
    data.imagens = [];
    if (this.primeiraImagem) data.imagens.push(this.primeiraImagem);

    if (this.segundaImagem) data.imagens.push(this.segundaImagem);

    if (this.terceiraImagem) data.imagens.push(this.terceiraImagem);

    this.perfilService
      .adicionarServicoAoServicoOfertado(
        this.data.user.id,
        this.data.categoriaId,
        this.data.serviceId,
        data
      )
      .subscribe((response) => {
        console.log("resposnse criar servico", response);
        this.dialogRef.close();
      });
  }

  editarServicoAoServicoOfertado() {
    const form = this.formAddService.value as ServicoModel;

    const data = {
      categoria: this.data.categoriaId,
      descricao: form.descricao,
      nome: form.nome,
      valor: form.valor,
    } as CadastrarServicoAoServicoOfertadoModel;
    data.imagens = [];
    if (this.primeiraImagem) data.imagens.push(this.primeiraImagem);

    if (this.segundaImagem) data.imagens.push(this.segundaImagem);

    if (this.terceiraImagem) data.imagens.push(this.terceiraImagem);

    const imagens: Array<ImagemModel> = [];

    if (this.urlImage1 != "") imagens.push(this.data.servico.imagens[0]);
    if (this.urlImage2 != "") imagens.push(this.data.servico.imagens[1]);
    if (this.urlImage3 != "") imagens.push(this.data.servico.imagens[2]);

    console.log('this.data: ', this.data);

    const payload = {
      novoServico: data,
      antigoServico: this.data.servico,
      imagens: imagens,
    } as EditarServicoAoServicoOfertadoModel;

    this.perfilService
      .atualizarServicoAoServicoOfertado(
        this.data.user.id,
        this.data.categoriaId,
        this.data.serviceId,
        payload
      )
      .subscribe((response) => {
        console.log("resposnse criar servico", response);
        this.dialogRef.close();
      });
  }

  closeModal() {
    this.dialogRef.close();
  }
  redirectToBudget() {
    this.closeModal();
    //this.router.navigate(["/perfil/orcamento"]);
  }

  deletarServicoAoServicoOfertado() {
    console.log("deletar serviço!");
    this.perfilService
      .deletarServicoAoServicoOfertado(
        this.data.user.id,
        this.data.serviceId,
        this.data.servico,
        this.data.removeAll
      )
      .subscribe((response) => {
        console.log("resposnse criar servico", response);
        this.dialogRef.close();
      });
  }
}
