import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { PerfilService } from "src/app/Services/perfil.service";
import { UserService } from "src/app/Services/user.service";
import { FornecedorAvaliacaoNodeModel } from "src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model";

@Component({
  selector: "app-avaliacoes",
  templateUrl: "./avaliacoes.component.html",
  styleUrls: ["./avaliacoes.component.scss"],
})
export class AvaliacoesComponent implements OnInit {
  user: SignInModel;
  mostrarRespostaFieldObject = {};
  formRespostas = new FormGroup({});
  avaliacoes: Array<FornecedorAvaliacaoNodeModel> = [];

  constructor(
    public userService: UserService,
    private perfilService: PerfilService
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.obterAvaliacoes();
  }

  mostrarResposta(indexDaResposta: number) {
    this.formRespostas.addControl(
      "resposta" + indexDaResposta,
      new FormControl("", Validators.required)
    );
    this.mostrarRespostaFieldObject[indexDaResposta] = true;
  }

  salvarResposta(indexDaResposta: number) {
    this.mostrarRespostaFieldObject[indexDaResposta] = false;

    this.avaliacoes[indexDaResposta].texto = this.formRespostas.get(
      "resposta" + indexDaResposta
    ).value;
 
    const novaAvaliacao = [...this.avaliacoes];
    novaAvaliacao.map((item) => { 
      item.autor = this.user.nomeUsuario;
      item.autorId = this.user.id;
      item.data = new Date();
      delete item.usuarioId;
      return item;
    });
    this.avaliacoes[indexDaResposta].respostas = novaAvaliacao;

    this.formRespostas.removeControl("resposta" + indexDaResposta);

    this.perfilService
      .adicionarResposta(this.user.id, this.avaliacoes)
      .subscribe((resp) => {
        console.log("resposta gracvadas", resp);

        this.obterAvaliacoes();
      });
  }

  private obterAvaliacoes() {
    this.perfilService
      .obterAvaliacoes(this.user.id)
      .then((resp: Array<FornecedorAvaliacaoNodeModel>) => {
        this.avaliacoes = resp;
      });
  }
}
