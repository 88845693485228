import { Component, OnInit, HostBinding, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MenuItems } from '../../../Core/menu/menu-items/menu-items';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { Subscription } from 'rxjs';
import { TokenModel } from 'src/app/Shared/Models/Base/token.model';

@Component({
  selector: 'embryo-Menu',
  templateUrl: './Menu.component.html',
  styleUrls: ['./Menu.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuComponent implements OnInit, OnDestroy {

  expanded: boolean;
  estaAutenticado: boolean = false;
  mostrarPremiumMenu = false;
  estaAutenticadoSubscription: Subscription;
  tokenData: TokenModel = new TokenModel();
  constructor(public menuItems: MenuItems, public router: Router, private authenticationHelper: AuthenticationHelper) { }

  ngOnInit() {
    this.tokenData = this.authenticationHelper.obterTokenModel();

    this.estaAutenticado = this.authenticationHelper.estaLogado();

    this.mostrarPremium(this.estaAutenticado);

    this.estaAutenticadoSubscription = this.authenticationHelper.clienteEstaAutenticado
    .subscribe(estaAutenticado => {

      this.tokenData = this.authenticationHelper.obterTokenModel();

      this.estaAutenticado = estaAutenticado;

      this.mostrarPremium(estaAutenticado);
    });


  }

  ngOnDestroy(): void {
    this.estaAutenticadoSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {

    this.estaAutenticadoSubscription = this.authenticationHelper.clienteEstaAutenticado
      .subscribe(estaAutenticado => {

        this.tokenData = this.authenticationHelper.obterTokenModel();

        this.estaAutenticado = estaAutenticado;

        this.mostrarPremium(estaAutenticado);
      });

  }


  mostrarPremium(estaAutenticado) {

    if (estaAutenticado) {
      if (this.tokenData.role == 2 && this.tokenData.plano == 1) {
        this.mostrarPremiumMenu = true;
      }
      else {
        this.mostrarPremiumMenu = false;
      }

    } else {
      this.mostrarPremiumMenu = false;
    }
  }

  public onItemSelected(item: any) {
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }


  public redirectTo(subchildState) {
    this.router.navigate([subchildState.state], { queryParams: { category: subchildState.queryState } });
  }

}
