import { PropostaModel } from './proposta.model';

export class FinalizarPropostaModel {
    propostasVm: PropostaModel[];
    propostasVmSelecionadas: PropostaModel[];    
  
    constructor(init?: Partial<FinalizarPropostaModel>) {
      Object.assign(this, init);
    }
  }
  