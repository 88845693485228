import { Pipe, PipeTransform } from "@angular/core";
import { AvaliacaoModel } from "../../Pages/perfil-fornecedor/components/perfil-fornecedor/dados-perfil.model";

@Pipe({
  name: "buscaMediaAvaliacao",
})
export class MediaAvaliacaoPipe implements PipeTransform {
  transform(avaliacoes: Array<AvaliacaoModel>): number {
    if (!avaliacoes) return 0;
    let soma = 0;

    avaliacoes.map((item) => item.nota).forEach((nota) => (soma += nota));

    return Math.floor(soma / avaliacoes.length);
  }
}
