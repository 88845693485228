import { TipoNotificacaoEnum } from './Enums/tipo-notificacao.enum';

export class NotificacaoModel {

    public id: string;
    public titulo: string;
    public conteudo: string;
    public tipoNotificacao: TipoNotificacaoEnum;
    public dataCriacao: Date;
    public lido: boolean;
    public dataLido?: Date;
    
    constructor(init?: Partial<NotificacaoModel>) {
        Object.assign(this, init);
    }
    
}