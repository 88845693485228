import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SessaoService } from 'src/app/Services/sessao.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'embryo-SignIn',
  templateUrl: './CommonSignIn.component.html',
  styleUrls: ['./CommonSignIn.component.scss']
})
export class CommonSignInComponent implements OnInit {

  signinForm: FormGroup;
  submited = false;

  redirect: string;
  constructor(private sessaoService: SessaoService,
    private router: ActivatedRoute) { }

  ngOnInit() {
    this.signinForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      senha: new FormControl('', Validators.compose([Validators.required])),
      rememberMe: new FormControl(false)
    });

    this.router.queryParams
      .filter(params => params.redirect)
      .subscribe(params => {
        this.redirect = params.redirect;
      });
  }

  get form() {
    return this.signinForm.controls;
  }

  submit(): void {
    this.submited = true;

    if (this.signinForm.valid) {
      this.sessaoService.logar(this.signinForm.value, this.redirect);
    }
  }
}
