import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ClipboardService } from "ngx-clipboard";
import { ToastOptions, ToastaService } from "ngx-toasta";
import { CobrancaGeradaComponent } from "src/app/Pages/Premium/PaymentsPix/cobranca-gerada/cobranca-gerada.component";
import { PagamentoService } from "src/app/Services/pagamento.service";
import { PlanoService } from "src/app/Services/plano.service";
import { MeuPlanoModel } from "src/app/Shared/Models/Base/meu-plano.model";
import Swal from "sweetalert2";

@Component({
  selector: "app-pagamento-pix",
  templateUrl: "./pagamento-pix.component.html",
  styleUrls: ["./pagamento-pix.component.scss"],
})
export class PagamentoPixComponent implements OnInit {
  @Output()
  preco = new EventEmitter<number>();

  constructor(
    private clipboard: ClipboardService,
    private planoService: PlanoService,
    private pagamentoService: PagamentoService,
    private toastaService: ToastaService,
    private dialog: MatDialog
  ) {}
  planos: Array<MeuPlanoModel>;

  ngOnInit() {
    this.obterPlanos();
  }

  copiaCodigoPix() {
    this.clipboard.copy("codigo pix");
    Swal.fire("Sucesso", "Código pix copiado para área de transferência");
  }

  obterPlanos() {
    this.planoService.obterPlanosAtivoBoleto().subscribe((resposta) => {
      this.planos = resposta.dados;
      console.log(this.planos);
    });
  }
  realizarPagamentoPix() {
    this.preco.emit(this.planos[0].preco);
    
    this.pagamentoService
      .realizarAssinaturaPix(this.planos[0].id)
      .then((res) => {
        const data = {
          title: "Sucesso",
          msg: "PIX gerado.",
        } as ToastOptions;

        this.toastaService.success(data);
        window.open(res);
      });
  }
}
