import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { ToastaService } from "ngx-toasta";
import { Router } from "@angular/router";
import { PropostaModel } from "../Shared/Models/Anuncios/proposta.model";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { HttpHeaders } from "@angular/common/http";
import { saveAs } from "file-saver";

@Injectable()
export class PropostaService extends BaseService {
  private readonly url = environment.endpoints.backend + "/anuncio";

  constructor(
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    toastaService: ToastaService,
    private router: Router
  ) {
    super(toastaService, authenticationHelper);
  }

  fazerProposta(anuncioId: string, propostaModel: FormData | PropostaModel) {
    return new Promise((resolve, reject) => {
      this.http
        .put<RespostaModel<PropostaModel>>(
          `${this.url}/${anuncioId}/proposta`,
          propostaModel
        )
        .subscribe(
          (res) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Proposta enviada.";
            this.toastaService.success(this.toastOptions);
            resolve(res.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);

            reject();
          }
        );
    });
  }

  atualizarPropostas(
    anuncioId: string,
    propostas: Array<PropostaModel>,
    loading = true
  ) {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      loading: loading.toString(),
    });

    return new Promise((resolve, reject) => {
      this.http
        .put<RespostaModel<Array<PropostaModel>>>(
          `${this.url}/${anuncioId}/proposta/classificao`,
          propostas,
          { headers: httpHeaders }
        )
        .subscribe(
          (res) => {
            resolve(res.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  removerProposta(anuncioId: string, fornecedorId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<RespostaModel<Array<PropostaModel>>>(
          `${this.url}/${anuncioId}/proposta/${fornecedorId}`
        )
        .subscribe(
          (res) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Proposta removida com sucesso.";
            this.toastaService.success(this.toastOptions);
            resolve(res.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  CancelarProposta(anuncioId: string, fornecedorId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<RespostaModel<Array<PropostaModel>>>(
          `${this.url}/${anuncioId}/proposta/CancelarProposta/${fornecedorId}`
        )
        .subscribe(
          (res) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Proposta cancelada com sucesso.";
            this.toastaService.success(this.toastOptions);
            resolve(res.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }  

  removerAnexoProposta(anuncioId: string, fornecedorId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${this.url}/${anuncioId}/proposta/RemoverAnexo/${fornecedorId}`
        )
        .subscribe(
          (res) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Anexo removido da proposta com sucesso.";
            this.toastaService.success(this.toastOptions);
            resolve(true);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  downloadAnexo(url, nomeArquivo) {
    new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", url);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send();
    }).then(
      (data) => {
        saveAs(data, nomeArquivo);
      },
      (error) => {
        this.manipuladorDeErro(error);
      }
    );
  }
}
