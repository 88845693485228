import { Component, OnInit, Input } from '@angular/core';
import { AnuncioService } from 'src/app/Services/anuncio.service';
import { ConfirmationPopupComponent } from '../ConfirmationPopup/ConfirmationPopup.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TipoAnuncioEnum } from 'src/app/Shared/Models/Base/Enums/tipo-anuncio.enum';

@Component({
   selector: 'app-bidCard',
   templateUrl: './BidCard.component.html',
   styleUrls: ['./BidCard.component.scss']
})
export class BidCardComponent implements OnInit {

   @Input() anuncio: any;

   @Input() index: any;

   constructor(private anuncioService: AnuncioService,
      private dialog: MatDialog, ) { }

   ngOnInit() {
   }

   excluir() {
      this.popupConfirmacao('Confirmando essa ação seu BID será excluído, você tem certeza disso ?')
         .subscribe(result => {
            if (result === 1) {
               this.anuncioService.excluir(this.anuncio.id);
            }
         });
   }

   popupConfirmacao(message: string) {
      let confirmationPopup: MatDialogRef<ConfirmationPopupComponent>;
      confirmationPopup = this.dialog.open(ConfirmationPopupComponent);
      confirmationPopup.componentInstance.message = message;

      return confirmationPopup.afterClosed();
   }

   obterDataTerminoBid(dataTerminoBid) {
      if (dataTerminoBid) {
         return new Date(dataTerminoBid);
      }

      return;
   }

   obterMensagemTooltip() {
      let mensagem = 'Visualizar BID';
      if (this.anuncio && this.anuncio.tipoAnuncio == TipoAnuncioEnum.Orcamento) {
         mensagem = 'Visualizar Orçamento';
      }

      return mensagem;
   }

   verificarSeAnuncioEhOrcamento() {
      return this.anuncio && this.anuncio.tipoAnuncio == TipoAnuncioEnum.Orcamento;
   }
}
