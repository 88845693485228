import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressBarModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule,
} from "@angular/material";
import { MatRadioModule } from "@angular/material/radio";
import { CurrencyMaskModule } from "ngx-currency-mask";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxMaskModule } from "ngx-mask";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { FornecedorService } from "src/app/Services/fornecedor.service";
import { NotificacaoService } from "src/app/Services/notificacao.service";
import { OrcamentoService } from "src/app/Services/orcamento.service";
import { SharedModule } from "src/app/Shared/shared.module";
import { AvaliacoesComponent } from "./components/meu-perfil/components/avaliacoes/avaliacoes.component";
import { DadosBancariosComponent } from "./components/meu-perfil/components/dados-bancarios/dados-bancarios.component";
import { MeuPlanoComponent } from "./components/meu-perfil/components/meu-plano/meu-plano.component";
import { PerguntasFrequentesComponent } from "./components/meu-perfil/components/perguntas-frequentes/perguntas-frequentes.component";
import { ProdutosServicosComponent } from "./components/meu-perfil/components/produtos-servicos/produtos-servicos.component";
import { BucarMenorValorServicoPipe } from "./components/meu-perfil/components/servicos/pipes/busca-menor-valor-servico.pipe";
import { ServicosComponent } from "./components/meu-perfil/components/servicos/servicos.component";
import { MeuPerfilComponent } from "./components/meu-perfil/meu-perfil.component";
import { MeusClientesComponent } from "./components/meus-clientes/meus-clientes.component";
import { MeusFornecedoresComponent } from "./components/meus-fornecedores/meus-fornecedores.component";
import { NotificacoesComponent } from "./components/notificacoes/notificacoes.component";
import { GetSituationPipe } from "./components/notificacoes/pipes/get-situation.pipe";
import { OportunidadesComponent } from "./components/oportunidades/oportunidades.component";
import { PerfilFornecedorComponent } from "./components/perfil-fornecedor/perfil-fornecedor.component";
import { PerfilFornecedorRoutingModule } from "./perfil-fornecedor-routing.module";
@NgModule({
  declarations: [
    PerfilFornecedorComponent,
    MeusClientesComponent,
    MeuPerfilComponent,
    ServicosComponent,
    PerguntasFrequentesComponent,
    ProdutosServicosComponent,
    AvaliacoesComponent,
    MeuPlanoComponent,
    MeusFornecedoresComponent,
    NotificacoesComponent,
    GetSituationPipe,
    OportunidadesComponent,
    DadosBancariosComponent,
    BucarMenorValorServicoPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    PerfilFornecedorRoutingModule,
    ImageCropperModule,
    MatProgressBarModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatSelectModule,
    CurrencyMaskModule,
    MatRadioModule,
    NgxMaskModule,
    SlickCarouselModule,
    MatTooltipModule,
  ],
  providers: [OrcamentoService, FornecedorService, NotificacaoService],
  exports: [SlickCarouselModule, MatTooltipModule],
})
export class PerfilFornecedorModule {}
