import { Pipe, PipeTransform } from "@angular/core";
import { ProdutosServicosExtras } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/produtos-servicos/models/produtos-servicos.model";
import { ServicosOfertadosModel } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/servicos-ofertados.model";

@Pipe({
  name: "getPrice",
})
export class MenorPrecoPipe implements PipeTransform {
  transform(services: Array<ServicosOfertadosModel>) {
    let minorValue = 0;

    services.forEach((ser) => {
      ser.servicos.forEach((servico, index) => {
        if (index === 0) minorValue = servico.valor;
        if (servico.valor < minorValue) minorValue = servico.valor;
      });
    });

    return minorValue;
  }
}
