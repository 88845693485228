import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BidSocialShareDialogModel } from 'src/app/Shared/Models/Base/bid-social-share-dialog.model';

@Component({
  selector: 'app-bid-social-share-dialog',
  templateUrl: './bid-social-share-dialog.component.html',
  styleUrls: ['./bid-social-share-dialog.component.css']
})
export class BidSocialShareDialogComponent implements OnInit {

  constructor(
    public dialogRef : MatDialogRef<BidSocialShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BidSocialShareDialogModel
  ) { }

  ngOnInit() {
  }

}
