import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { CategoriaServicoEnum } from "../Shared/Models/Servicos/Enums/categoria-servico.enum";
import { ServicoModel } from "../Shared/Models/Servicos/servico.model";

@Injectable({ providedIn: "root" })
export class ServicoService {
  private readonly url = environment.endpoints.backend + "/servico";

  constructor(private http: HttpClient) {}

  obterServicos(): Observable<RespostaModel<ServicoModel[]>> {
    return this.http.get<RespostaModel<ServicoModel[]>>(`${this.url}`);
  }

  obterServicosPelaCategoria(
    categoria: CategoriaServicoEnum,
    loading: boolean = true
  ): Observable<RespostaModel<ServicoModel[]>> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      loading: loading.toString(),
    });
    return this.http.get<RespostaModel<ServicoModel[]>>(
      `${this.url}/categoria/${categoria}`,
      {
        headers: httpHeaders,
      }
    );
  }
}
