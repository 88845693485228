 
import { Pipe, PipeTransform } from "@angular/core"; 
import { TipoNotificacaoEnum } from "src/app/Shared/Models/Notificacoes/Enums/tipo-notificacao.enum";

@Pipe({
  name: 'getSituation'
})
export class GetSituationPipe implements PipeTransform {
  transform(situation: number) {
    return TipoNotificacaoEnum[situation];
  }
}