import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { PerfilService } from "src/app/Services/perfil.service";
import { UserService } from "src/app/Services/user.service";
import { ProdutosServicosExtras } from "./models/produtos-servicos.model";
import { ImagemModel } from "src/app/Shared/Models/Base/imagem.model";

@Component({
  selector: "app-produtos-servicos",
  templateUrl: "./produtos-servicos.component.html",
  styleUrls: ["./produtos-servicos.component.scss"],
})
export class ProdutosServicosComponent implements OnInit {
  @Input()
  servicosExtras: Array<ProdutosServicosExtras> = [];

  @Output()
  resetViewOfServices = new EventEmitter();

  user: SignInModel;
  form!: FormGroup;
  cadastrarNovoProduto = false;
  file1: File | null = null;
  file2: File | null = null;
  file3: File | null = null;
  eventListenerToImg1: any;
  eventListenerToImg2: any;
  eventListenerToImg3: any;
  primeiraImagem: { base64: string; descricao: string };
  segundaImagem: { base64: string; descricao: string };
  terceiraImagem: { base64: string; descricao: string };

  constructor(
    private formBuilder: FormBuilder,
    private perfilService: PerfilService,
    private userService: UserService
  ) {
    this.user = userService.user;

    this.form = formBuilder.group({
      nome: ["", Validators.required],
      valor: ["", Validators.required],
      descricao: ["", Validators.required],
    });
  }

  ngOnInit() {}

  atualizarServicosExtras() {
    const form = this.form.value;
    const imagens = [] as ImagemModel[];

    if (this.primeiraImagem) {
      imagens.push({
        descricao: this.primeiraImagem.descricao,
        url: this.primeiraImagem.base64,
      } as ImagemModel);
    }
    if (this.segundaImagem) {
      imagens.push({
        descricao: this.segundaImagem.descricao,
        url: this.segundaImagem.base64,
      } as ImagemModel);
    }
    if (this.terceiraImagem) {
      imagens.push({
        descricao: this.terceiraImagem.descricao,
        url: this.terceiraImagem.base64,
      } as ImagemModel);
    }

    const data = {
      descricao: form.descricao,
      valor: form.valor,
      nome: form.nome,
      imagens: imagens,
    } as ProdutosServicosExtras;

    this.perfilService
      .atualizarServicosExtras(this.user.id, data)
      .subscribe(() => {
        this.cadastrarNovoProduto = false;
        this.resetViewOfServices.emit();
      });
  }

  setFile1(event) {
    this.file1 = event.target.files[0];
    this.eventListenerToImg1 = event;
  }
  setFile2(event) {
    this.file2 = event.target.files[0];
    this.eventListenerToImg2 = event;
  }
  setFile3(event) {
    this.file3 = event.target.files[0];
    this.eventListenerToImg3 = event;
  }
  imageCropped1(event: any) {
    this.primeiraImagem = {
      base64: event.original.base64,
      descricao: this.file1.name,
    };
  }
  imageCropped2(event: any) {
    this.segundaImagem = {
      base64: event.original.base64,
      descricao: this.file2.name,
    };
  }
  imageCropped3(event: any) {
    this.terceiraImagem = {
      base64: event.original.base64,
      descricao: this.file3.name,
    };
  }
}
