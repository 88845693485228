import {
  Component,
  Inject,
  Input,
  OnInit
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import Swal from "sweetalert2";

@Component({
  selector: "app-compartilhar-modal",
  templateUrl: "./compartilhar-modal.component.html",
  styleUrls: ["./compartilhar-modal.component.scss"],
})
export class CompartilharModalModalComponent implements OnInit {
  link: string = window.location.href;
  @Input()
  fornecedor: string = "";

  @Input()
  user: SignInModel;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<CompartilharModalModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fornecedor: string;
      user: SignInModel;
    }
  ) {
    this.fornecedor = data.fornecedor;
  }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

  copiar() {
    var copyText = window.location.href;
    navigator.clipboard.writeText(copyText);
    Swal.fire("Sucesso", "Link copiado com sucesso", "success")
    .then(_ => this.closeModal());
  }
}
