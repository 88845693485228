import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatOption, MatSelect } from "@angular/material";
import { Router } from "@angular/router";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { startWith, map, pluck, takeUntil } from "rxjs/operators";
import { CategoriaService } from "src/app/Services/categoria.service";
import { FornecedorService } from "src/app/Services/fornecedor.service";
import { LocalidadeService } from "src/app/Services/localidade.service";
import { ServicoService } from "src/app/Services/servico.service";
import { EstadoModel } from "src/app/Shared/Models/Base/estado.model";
import { FornecedorPesquisarVmModel } from "src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-pesquisar-vm.model";
import { FornecedorModel } from "src/app/Shared/Models/Fornecedores/fornecedor.model";
import { CategoriaServicoEnum } from "src/app/Shared/Models/Servicos/Enums/categoria-servico.enum";
@Component({
  selector: "app-fornecedor-pesquisa",
  templateUrl: "./fornecedor-pesquisa.component.html",
  styleUrls: ["./fornecedor-pesquisa.component.scss"],
  host: {
    "(window:resize)": "onWindowResize($event)",
  },
  encapsulation: ViewEncapsulation.None,
})
export class FornecedorPesquisaComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyMobileButtonSize();
  }

  @Output()
  fornecedores = new EventEmitter<Array<FornecedorModel>>();

  @Output()
  categoria = new EventEmitter<CategoriaServicoEnum>();

  @Output()
  larguraTela = window.innerWidth;

  @Input()
  redirect = false;

  @Input()
  servico: string;

  mobileView: boolean;
  todasCategoriasSeleciondas = false;
  todosServicosSelecionados = false;
  todosEstadosSelecionados = false;

  pesquisaFornecedorForm: FormGroup;

  categorias: any[] = [];
  servicos: any[] = [];
  estados: EstadoModel[] = [];
  cidades: any[] = [];
  buttonSize: "medium" | "small" = "medium";

  public cidadesGruposCtrl: FormControl = new FormControl();
  public cidadesGruposFiltradasCtrl: FormControl = new FormControl();
  public cidadesGruposFiltradas: ReplaySubject<any[]> = new ReplaySubject<
    any[]
  >(1);
  protected _onDestroy = new Subject<void>();

  @ViewChild("categoriaSelect", { static: false }) categoriaSelect: MatSelect;
  @ViewChild("servicoSelect", { static: false }) servicoSelect: MatSelect;
  @ViewChild("estadoSelect", { static: false }) estadoSelect: MatSelect;
  @ViewChild("cidadeSelect", { static: false }) cidadeSelect: MatSelect;

  servicosObervable: Observable<any[]>;
  servicosAutocomplete: any[] = [];
  enderecosObervable: Observable<any[]>;
  enderecosAutocomplete: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private servicoService: ServicoService,
    private categoriaService: CategoriaService,
    private localidadeService: LocalidadeService,
    private router: Router,
    private fornecedorService: FornecedorService
  ) {
    this.criarFormulario();
  }

  ngAfterViewInit(): void {
    this.verifyScreenSize();
    this.verifyMobileButtonSize();

    Promise.all([
      this.carregarCategorias().toPromise(),
      this.carregarEstados().toPromise(),
      this.carregarServicos().toPromise(),
      this.carregarCidades({ id: 35, sigla: "SP", nome: "SP" }),
      this.carregarZonas(),
    ]).then((resposta) => {
      resposta[0].dados.forEach((value) => {
        this.categorias.push({
          descricao: value,
          categoria: CategoriaServicoEnum[value],
        });
      });

      this.estados.push({ id: 35, sigla: "SP", nome: "São Paulo" });

      this.servicos = resposta[2].dados;
      this.servicos = this.servicos.map((x) => {
        x.categoria = CategoriaServicoEnum[x.categoria];
        return x;
      });

      this.categorias.forEach((categoria) => {
        const descricaoCategoria =
          categoria.descricao == "FotoFilmagem"
            ? "Foto Filmagem"
            : categoria.descricao;

        this.servicosAutocomplete.push(descricaoCategoria);

        var servicosPelaCategoria = this.servicos.filter(
          (servico) => servico.categoria == categoria.descricao
        );

        servicosPelaCategoria.forEach((servico) => {
          this.servicosAutocomplete.push(
            descricaoCategoria + ", " + servico.nome
          );
        });
      });

      const descricaoEstado = "São Paulo";

      this.enderecosAutocomplete.push(descricaoEstado);
      this.cidades[0].cidades.forEach((cidade) => {
        this.enderecosAutocomplete.push(descricaoEstado + ", " + cidade.nome);
      });

      var indexEndereco = 0;
      var enderecosComZona = [];
      const zonas = resposta[4].dados;

      this.enderecosAutocomplete.forEach((endereco) => {
        if (indexEndereco !== 0) {
          zonas.forEach((zona) => {
            enderecosComZona.push(endereco + ", " + zona.nome);
          });
        }

        indexEndereco++;
      });

      this.enderecosAutocomplete =
        this.enderecosAutocomplete.concat(enderecosComZona);
    });
  }

  private verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }

  private _filterServicos(value: string): any[] {
    if (value) {
      const filterValue = this.removerSpecials(value.toLowerCase());

      return this.servicosAutocomplete
        .filter((option) =>
          this.removerSpecials(option.toLowerCase().replace(/,/g, "")).includes(
            filterValue
          )
        )
        .slice(0, 10);
    }

    return this.servicosAutocomplete.slice(0, 10);
  }

  private _filterEnderecos(value: string): any[] {
    if (value) {
      const filterValue = this.removerSpecials(value.toLowerCase());

      return this.enderecosAutocomplete
        .filter((option) =>
          this.removerSpecials(option.toLowerCase().replace(/,/g, "")).includes(
            filterValue
          )
        )
        .slice(0, 10);
    }

    return this.enderecosAutocomplete.slice(0, 10);
  }

  private carregarZonas() {
    return this.localidadeService.obterZonas().toPromise();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {
    this.verifyScreenSize();

    this.servicosObervable = this.pesquisaFornecedorForm
      .get("servicoCtrl")!
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filterServicos(value || ""))
      );

    this.enderecosObervable = this.pesquisaFornecedorForm
      .get("enderecoCtrl")!
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filterEnderecos(value || ""))
      );

    this.cidadesGruposFiltradas.next(this.copiarCidadesGrupos(this.cidades));
    this.cidadesGruposFiltradasCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtrarCidadesGrupos();
      });
  }

  private carregarServicos() {
    return this.servicoService.obterServicos();
  }

  private verifyMobileButtonSize() {
    if (window.innerWidth < 468) this.buttonSize = "small";
    else this.buttonSize = "medium";
  }

  removerSpecials(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  onWindowResize(event) {
    this.larguraTela = event.target.innerWidth;
  }

  //#region Categorias
  private carregarCategorias() {
    return this.categoriaService.obterCategorias();
  }

  manipularSelecaoTodasCategorias() {
    this.todasCategoriasSeleciondas = !this.todasCategoriasSeleciondas;

    if (this.todasCategoriasSeleciondas) {
      this.categoriaSelect.options.forEach((item: MatOption) => {
        item.select();
        this.adicionarServicos(item.value);
      });
    } else {
      this.categoriaSelect.options.forEach((item: MatOption) => {
        item.deselect();
        this.removerServicos(item.value);
      });
    }
  }

  private obterCategoriasSelecionadas() {
    let categoriasSelecionadas = [];
    if (this.pesquisaFornecedorForm.value.categoriaCtrl) {
      categoriasSelecionadas = this.pesquisaFornecedorForm.value.categoriaCtrl
        .filter((x) => x !== "-1")
        .map((value) => {
          if (!value) return -1;

          return value.categoria;
        });
    }

    if (this.todasCategoriasSeleciondas) {
      categoriasSelecionadas.push(-1);
    }

    return categoriasSelecionadas;
  }
  //#endregion

  //#region Serviços
  manipularSelecaoTodosServicos() {
    this.todosServicosSelecionados = !this.todosServicosSelecionados;

    if (this.todosServicosSelecionados) {
      this.servicoSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.servicoSelect.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
  }

  private obterServicosSelecionados() {
    let servicosSelecionados = [];
    if (this.pesquisaFornecedorForm.value.servicoCtrl) {
      servicosSelecionados =
        this.pesquisaFornecedorForm.value.servicoCtrl.filter((x) => x !== "-1");
    }
    return "";
  }

  private adicionarServicos(categoria: any) {
    if (!this.servicos) {
      this.servicos = [];
    }

    const itemAdicionado = this.servicos.find(
      (f) => f.categoria === categoria.descricao
    );
    if (itemAdicionado) return;

    this.servicoService
      .obterServicosPelaCategoria(categoria.categoria, false)
      .pipe(pluck("dados"))
      .subscribe((servicos) => {
        this.servicos.push({
          categoria: categoria.descricao,
          servicos,
        });

        this.servicos.sort((a, b) => a.categoria.localeCompare(b.categoria));
      });
  }

  private removerServicos(categoria: any) {
    this.servicos = this.servicos.filter(
      (f) => f.categoria != categoria.descricao
    );
  }

  selecionarServicos(categoria: any) {
    const categoriaSelecionada = this.categoriaSelect.options.find(
      (f) => f.value.categoria === categoria.categoria
    ).selected;

    if (categoriaSelecionada) {
      this.adicionarServicos(categoria);
    } else {
      this.removerServicos(categoria);
    }
  }

  //#endregion

  //#region Estados
  private carregarEstados() {
    return new Observable((observable) => {
      observable.next([
        {
          id: 35,
          sigla: "SP",
          nome: "SP",
        },
      ]);
      observable.complete();
    });
  }

  manipularSelecaoTodosEstados() {
    this.todosEstadosSelecionados = !this.todosEstadosSelecionados;

    if (this.todosEstadosSelecionados) {
      this.estadoSelect.options.forEach((item: MatOption) => {
        item.select();

        if (typeof item.value !== "string") {
          this.carregarCidades(item.value);
        }
      });
    } else {
      this.estadoSelect.options.forEach((item: MatOption) => {
        item.deselect();
        this.removerCidades(item.value);
      });
    }
  }

  selecionarEstado(estado: any) {
    const selecionado = this.pesquisaFornecedorForm.value.estadoCtrl.find(
      (f) => f.id === estado.id
    );
    if (selecionado) {
      this.carregarCidades(estado);
    } else {
      this.removerCidades(estado);
    }
  }

  private obterEstadosSelecionados() {
    let estadosSelecionados = [];
    if (this.pesquisaFornecedorForm.value.estadoCtrl) {
      estadosSelecionados = this.pesquisaFornecedorForm.value.estadoCtrl.filter(
        (x) => x !== "-1"
      );
    }
    return estadosSelecionados;
  }
  //#endregion

  //#region Cidades
  private carregarCidades(estado: any) {
    if (estado === "-1") {
      return;
    }

    this.cidades = [];

    const promise = this.localidadeService
      .obterCidades(estado.id)
      .toPromise()
      .then((response) => {
        this.cidades.push({
          estado: estado.nome,
          cidades: response.dados,
        });

        this.cidadesGruposFiltradas.next(
          this.copiarCidadesGrupos(this.cidades)
        );
      });

    this.cidadesGruposFiltradas.next(this.copiarCidadesGrupos(this.cidades));

    return promise;
  }

  removerCidades(estado: any) {
    this.cidades = this.cidades.filter((f) => f.estado !== estado.nome);
    this.cidadesGruposFiltradas.next(this.copiarCidadesGrupos(this.cidades));
  }

  protected copiarCidadesGrupos(cidadesGrupos: any[]) {
    const cidadesGruposCopia = [];
    cidadesGrupos.forEach((cidadeGrupo) => {
      cidadesGruposCopia.push({
        estado: cidadeGrupo.estado,
        cidades: cidadeGrupo.cidades.slice(),
      });
    });
    return cidadesGruposCopia;
  }

  // https://stackblitz.com/github/bithost-gmbh/ngx-mat-select-search-example?file=src%2Fapp%2Fexamples%2F04-option-groups-example%2Foption-groups-example.component.ts
  protected filtrarCidadesGrupos() {
    if (!this.cidades) {
      return;
    }

    // get the search keyword
    let search = this.cidadesGruposFiltradasCtrl.value;
    const cidadesGruposCopia = this.copiarCidadesGrupos(this.cidades);
    if (!search) {
      this.cidadesGruposFiltradas.next(cidadesGruposCopia);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.cidadesGruposFiltradas.next(
      cidadesGruposCopia.filter((cidadeGrupo) => {
        const exibirCidadeGrupo =
          cidadeGrupo.estado.toLowerCase().indexOf(search) > -1;
        if (!exibirCidadeGrupo) {
          cidadeGrupo.cidades = cidadeGrupo.cidades.filter(
            (cidade) => cidade.nome.toLowerCase().indexOf(search) > -1
          );
        }
        return cidadeGrupo.cidades.length > 0;
      })
    );
  }

  //#endregion

  private criarFormulario() {
    this.pesquisaFornecedorForm = this.formBuilder.group({
      categoriaCtrl: [""],
      servicoCtrl: [""],
      estadoCtrl: [""],
      enderecoCtrl: [""],
    });
  }

  obterDadosPesquisa(paginaAtual, totalPorPagina) {
    const dadosPesquisa: FornecedorPesquisarVmModel = {
      paginaAtual: paginaAtual,
      totalPorPagina: totalPorPagina,
      categorias: this.obterCategoriasSelecionadas(),
      servico: this.obterServicosSelecionados(),
      estados: this.obterEstadosSelecionados(),
      cidades: this.cidadesGruposCtrl.value,
      cidade: "",
      zona: "",
    };
    return dadosPesquisa;
  }

  executarPesquisa() {
    const data = {} as {
      cidadeId?: number;
      categoriaId?: number;
      nomeServico?: string;
      zonaRegiao?: string;
    };

    data.categoriaId = 0;
    data.cidadeId = 0;
    data.nomeServico = "";

    var pesquisaCategoria = this.pesquisaFornecedorForm.value.servicoCtrl.toLowerCase().replace(" ", "").split(",");

    if (pesquisaCategoria.length == 2)
    {
      data.nomeServico = pesquisaCategoria[1];
    }

    const idCategoria = this.categorias.find(
      (categoria) =>
        categoria.descricao.toLowerCase() === pesquisaCategoria[0]
    ) as { descricao: string; categoria: number };

    var pesquisaEndereco = this.pesquisaFornecedorForm.value.enderecoCtrl.toLowerCase().split(",");
    var cidadePesquisada = "";
    var estadoPesquisado = "";
    var zonaPesquisada = "";

    if (pesquisaEndereco.length == 1)
    {
      estadoPesquisado = pesquisaEndereco[0];      
      cidadePesquisada = pesquisaEndereco[0];
    }
    else if (pesquisaEndereco.length == 2)
    {
      estadoPesquisado = pesquisaEndereco[0];
      cidadePesquisada = pesquisaEndereco[1];
    }
    else if (pesquisaEndereco.length == 3)
    {
      estadoPesquisado = pesquisaEndereco[0];      
      cidadePesquisada = pesquisaEndereco[1];
      zonaPesquisada = pesquisaEndereco[2];
    }

    // Seleciona ID do Estado
    const estadoId = this.estados.find(
      (estado) => estado.nome.toLocaleLowerCase() == estadoPesquisado.toLowerCase() 
    );

    const cidadeId = this.cidades[0].cidades.find(
      (cidade) =>
        cidade.nome.toLowerCase() === cidadePesquisada.toLowerCase().trim() && cidade.idEstado === estadoId.id
    );
    if (idCategoria) data.categoriaId = idCategoria.categoria;
    if (cidadeId) data.cidadeId = cidadeId.id;
    if (zonaPesquisada) data.zonaRegiao = zonaPesquisada;

    if (this.redirect) {
      this.router.navigate(["/fornecedores"], {
        queryParams: { categoria: data.categoriaId, servico: data.nomeServico, localizacao: data.cidadeId, zonaRegiao: data.zonaRegiao },
      });
    } else {
      this.fornecedorService
        .getObterPorCidadeServico(data)
        .subscribe((response) => {
          this.fornecedores.emit(response);
          this.categoria.emit(data.categoriaId);
        });
    }
  }
}
