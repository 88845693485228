import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ToastaService } from 'ngx-toasta';
import { BaseService } from './base.service';
import { AuthenticationHelper } from './helpers/authentication-helper.service';
import { RespostaModel } from '../Shared/Models/Base/resposta.model';
import { ClienteBaseModel } from '../Shared/Models/Base/cliente-base.model';
import { TipoUsuarioEnum } from '../Shared/Models/Base/Enums/role.enum';
import { FornecedorAvaliacaoNodeModel } from '../Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model';
import { AvaliacaoAtualizacaoModel } from '../Shared/Models/Fornecedores/avaliacao-atualizacao.model';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable()
export class ClienteBaseService extends BaseService {

    private readonly url = environment.endpoints.backend;

    constructor(
        private http: HttpClient,
        authenticationHelper: AuthenticationHelper,
        toastaService: ToastaService,
        private loader: LoadingBarService
    ) {
        super(toastaService, authenticationHelper );
    }

    obterPorEmail(email: string, tipoUsuarioEnum: TipoUsuarioEnum): Observable<RespostaModel<ClienteBaseModel>> {

        if (tipoUsuarioEnum == TipoUsuarioEnum.Master || tipoUsuarioEnum == TipoUsuarioEnum.Administrador) {
            return this.http.get<RespostaModel<ClienteBaseModel>>(`${this.url}/gestor?email=${email}`);
        } else if (tipoUsuarioEnum == TipoUsuarioEnum.Fornecedor) {
            return this.http.get<RespostaModel<ClienteBaseModel>>(`${this.url}/fornecedor?email=${email}`);
        }

        return this.http.get<RespostaModel<ClienteBaseModel>>(`${this.url}/cliente?email=${email}`);
    }

    adicionarAvaliacao(avaliacao: FornecedorAvaliacaoNodeModel) {
        return new Promise((resolve, reject) => {
            return this.http.post(`${this.url}/cliente/AdicionarAvaliacao`, avaliacao)
                .subscribe((response: any) => {
                    resolve(response.dados);
                },
                error => {
                    this.manipuladorDeErro(error);
                    reject();
            });
        });
    }

    obterAvaliacaoPorUsuario(id: string): Observable<RespostaModel<Array<FornecedorAvaliacaoNodeModel>>> {

        return this.http.get<RespostaModel<Array<FornecedorAvaliacaoNodeModel>>>(`${this.url}/cliente/${id}/avaliacoes`);
    }
    
    obterClienteInfo(id: string, anuncioId:string, fornecedorId:string): Observable<RespostaModel<ClienteBaseModel>> {

        return this.http.get<RespostaModel<ClienteBaseModel>>(`${this.url}/cliente/${id}/obter-info?anuncioId=${anuncioId}&fornecedorId=${fornecedorId}`);
    }

    atualizarAvaliacao(avaliacoes: AvaliacaoAtualizacaoModel) {
        return new Promise<FornecedorAvaliacaoNodeModel[]>((resolve, reject) => {
            return this.http
                .post<RespostaModel<FornecedorAvaliacaoNodeModel[]>>(`${this.url}/cliente/AtualizarAvaliacao`, avaliacoes)
                    .subscribe((response) => {
                        this.toastOptions.title = 'Sucesso!';
                        this.toastOptions.msg = 'Resposta enviada com sucesso!';
                        this.toastaService.success(this.toastOptions);
                        this.loader.complete();
                        resolve(response.dados);
                    }, error => {
                        this.loader.complete();
                        this.manipuladorDeErro(error);
                        reject();
                })
            });
    }

}
