import { EntidadeModel } from './entidade.model';
import { IdentidadeModel } from './identidade.model';
import { TelefoneModel } from './telefone.model';
import { EnderecoModel } from './endereco.model';
import { SexoEnum } from './Enums/sexo.enum';
import { MeuPlanoModel } from './meu-plano.model';
import { TipoUsuarioEnum } from './Enums/role.enum';

export class ClienteBaseModel extends EntidadeModel<string> {

    public nome: string;
    public dataNascimento: Date;
    public email: string;
    public tipoUsuario: TipoUsuarioEnum;
    public identidade: IdentidadeModel;
    public telefone: TelefoneModel;
    public endereco: EnderecoModel;
    public aceiteTermo: boolean;
    public dataCriacao: Date;
    public sexo: SexoEnum;
    public meuPlano: MeuPlanoModel;
    public hashCartaoCredito: string;

    constructor(init?: Partial<ClienteBaseModel>) {
        super();
        Object.assign(this, init);
    }
}