import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BidSocialShareDialogModel } from 'src/app/Shared/Models/Base/bid-social-share-dialog.model';
import { BidSocialShareDialogComponent } from '../Widget/PopUp/bid-social-share-dialog/bid-social-share-dialog.component';

@Directive({
  selector: '[appBidSocialShare]'
})
export class BidSocialShareDirective {

  @Input() dados: BidSocialShareDialogModel;

  @HostListener('click') onClick() {
    let dialogRef : MatDialogRef<BidSocialShareDialogComponent>;
		dialogRef = this.dialog.open(BidSocialShareDialogComponent, {
      data: this.dados,
      autoFocus: false
    });
		return dialogRef.afterClosed();
  }

  constructor(
    private dialog: MatDialog
  ) { }

}
