import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { pluck } from "rxjs/operators";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { UserService } from "src/app/Services/user.service";
import { AnuncioModel } from "src/app/Shared/Models/Anuncios/anuncio.model";
import { StatusAnuncio } from "src/app/Shared/Models/Base/Enums/status-anuncio.enum";
import { TipoVisualizacaoAnuncioEnum } from "src/app/Shared/Models/Base/Enums/tipo-visualizacao-anuncio.enum";
import { ServicoEmAndamentoModel } from "src/app/Shared/Models/Servicos/servico-em-andamento.model";
enum MenuEnum {
  EmNegociacao = 1,
  Encerrados = 2,
  MeusOrcamentos = 3,
  AbrirBid = 4,
}
@Component({
  selector: "app-meus-fornecedores",
  templateUrl: "./meus-fornecedores.component.html",
  styleUrls: ["./meus-fornecedores.component.scss"],
})
export class MeusFornecedoresComponent implements OnInit {
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }
  menuEnum = MenuEnum;
  selectedTab = 1;
  selectedMenu = 1;
  mobileView: boolean;
  user: SignInModel;
  inProgress = {
    categoria: 2,
    dataTerminoBid: "12/10/2023",
    id: "123",
    nomeServico: "teste",
    precoAbaixoDe: 40,
    primeiraImagem: "asd",
    primeiraImagemDescricao: "erwer",
    status: 2,
  } as ServicoEmAndamentoModel;
  meusOrcamentos: Array<AnuncioModel> = [];
  statusAnuncio = StatusAnuncio;
  bids: Array<AnuncioModel> = [];
  lastStatus: StatusAnuncio;

  constructor(
    private anuncioService: AnuncioService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.verifyScreenSize();
    this.buscaBidsEmAndamentoStatus(StatusAnuncio.emprogresso);
  }

  verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }

  getOrcamentosPorId() {
    this.anuncioService
      .buscarOrcamentosPorFornecedorId(this.user.id, this.selectedTab === 1)
      .subscribe((response) => {
        if (response.length > 0) {
          this.meusOrcamentos = response;
        }
      });
  }

  buscaBidsEmAndamentoStatus(status: StatusAnuncio) {
    const data = {
      clienteId: this.user.id,
      statusAnuncio: status,
      tipoVisualizacaoAnuncio: TipoVisualizacaoAnuncioEnum.total,
      ultimoMes: this.selectedTab === 1,
    };
    this.lastStatus = status;

    this.anuncioService
      .obterPorStatus(data)
      .pipe(pluck("dados"))
      .subscribe((response: Array<AnuncioModel>) => {
        this.bids = response;
      });
  }

  abrirDetalhesDoBid(id: string) {
    this.router.navigate(["detalhes-bid"], {
      queryParams: { id: id, route: "perfil-fornecedor" },
    });
  }

  changeSelectionOfTabGroup() {
    if (this.selectedMenu === this.menuEnum.MeusOrcamentos) {
      this.getOrcamentosPorId();
    } else {
      this.buscaBidsEmAndamentoStatus(this.lastStatus);
    }
  }

  abrirHomeClienteParaFornecedor() {
    window.open(`${window.location.origin}/home?isClientUserParam=true`);
  }

  redirecionarParaOrcamento() {
    this.router.navigate(["/perfil/orcamento"]);
  }

  redirectToNewBid() {
    this.router.navigate(["/perfil/novo-bid"]);
  }
}
