export interface NewUserModel {
  aceiteTermo: boolean;
  cidade: string;
  cidadeId: number;
  complemento: string;
  confirmarSenha: string;
  dataNascimento: string;
  email: string;
  estado: string;
  estadoId: number;
  nome: string;
  nomeFantasia: string;
  // numeroIdentidade: string;
  numeroTelefone: string;
  rua: string;
  senha: string;
  // sexo: Sexo;
  // tipoIdentidade: TipoIdentidade;
  tipoTelefone: TipoTelefone;
  tipoUsuario: TipoUsuario;
  userName: string;
}

export enum TipoUsuario {
  Cliente = 1,
  Fornecedor = 2,
  Administrador = 876,
  Master = 999,
}

enum TipoIdentidade {
  Cpf = 1,
  Cnpj = 2,
  Nenhum = 3,
}

enum Sexo {
  Feminino = 1,
  Masculino = 2,
}

export enum TipoTelefone {
  Celular = 1,
  Telefone = 2,
  Nenhum = 3,
}
