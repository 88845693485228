import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessaoService } from 'src/app/Services/sessao.service';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { TokenModel } from 'src/app/Shared/Models/Base/token.model';

@Component({
	selector: 'embryo-header-user-profile',
	templateUrl: './HeaderUserProfileDropdown.component.html',
	styleUrls: ['./HeaderUserProfileDropdown.component.scss']
})

export class HeaderUserProfileDropdownComponent implements OnInit {

	urlProfileImage = '';

	constructor(
		private sessaoService: SessaoService,
		private router: Router,
		private authenticationHelper: AuthenticationHelper
	) { }

	ngOnInit() {
		this.urlProfileImage = this.authenticationHelper.obterImagemPerfil();
		this.authenticationHelper
			.changeProfileImageEvent
			.subscribe(res => {
				this.urlProfileImage = res;
			});
	}

	goToSettings() {
		const token: TokenModel = this.authenticationHelper.obterTokenModel();
		if (token && this.authenticationHelper.estaLogado()) {
			this.router.navigate(['/profile', token.cliente]);
		}
	}

	logout() {
		this.sessaoService.sair();
	}
}
