import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { AuthenticationHelper } from './authentication-helper.service';
import { TipoPlanoEnum } from 'src/app/Shared/Models/Planos/Enums/tipo-plano.enum';

@Injectable()
export class BidAccessControlGuardService implements CanActivate {

    constructor(
        private authenticationHelper: AuthenticationHelper) 
    {
    }
    
    canActivate(route: ActivatedRouteSnapshot, 
        routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
        
        if (this.authenticationHelper.obterTipoPlano() == TipoPlanoEnum.Gratis) {
            this.authenticationHelper.exibirDialogAcesso();
            return of(false);
        }
        
        return of(true);
    }

}