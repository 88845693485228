import { TipoAcessoEnum } from './Enums/tipo-acesso.enum';

export class PainelGestaoModel {

    public fornecedorId: string;
    public tipoAcesso: TipoAcessoEnum;

    constructor(init?: Partial<PainelGestaoModel>) {
        Object.assign(this, init);
    }
}