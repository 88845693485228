
import { throwError as observableThrowError, Observable } from 'rxjs';
import { AuthenticationHelper } from './authentication-helper.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationHelper: AuthenticationHelper,
    private loader: LoadingBarService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loading = req.headers.get('loading') ? req.headers.get('loading') === "true" : true;

    if (loading) {
      this.loader.start();
    }

    const copiedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.authenticationHelper.obterToken()}`)
    });

    return next.handle(copiedReq).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.loader.complete();
      }
    },
      (err: any) => {
        this.loader.complete();
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.authenticationHelper.removerToken();
          this.loader.complete();
        }
      },
      () => {
        this.loader.complete();
      }
    ));
  }


  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   this.loader.start();

  //   const copiedReq = req.clone({
  //     headers: req.headers.set('Authorization', `Bearer ${this.authenticationHelper.obterToken()}`)
  //   });
  //   return next.handle(copiedReq)
  //     .do((ev: HttpEvent<any>) => {
  //       this.loader.complete();
  //     })
  //     .catch(response => {
  //       if (response instanceof HttpErrorResponse) {

  //         if (response.status === 401) {
  //           this.sessionService.clienteEstaAutenticado.next(false);
  //           this.authenticationHelper.removerToken();
  //           this.router.navigate(['/session/signin']);
  //         }

  //         this.loader.complete();

  //       }
  //       return observableThrowError(response);
  //     });
  // }

}
