import { Directive, OnInit, OnDestroy, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TipoAcessoEnum } from 'src/app/Shared/Models/PainelGestao/Enums/tipo-acesso.enum';
import { PainelGestaoModel } from 'src/app/Shared/Models/PainelGestao/painel-gestao.model';
import { PainelGestaoService } from 'src/app/Services/painel-gestao.service';

@Directive({
  selector: '[appBidPainelGestao]'
})
export class BidPainelGestaoDirective implements OnInit, OnDestroy {

  private clicks = new Subject();
  private subscription: Subscription;

  @Input() fornecedorId: string;
  @Input() tipoAcesso: TipoAcessoEnum;

  @Output() bidClick = new EventEmitter();

  @HostListener('click', ['$event']) clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    
    // ENVIAR PARA O BACK END O ACESSO
    const dadosAcesso = new PainelGestaoModel({
      fornecedorId: this.fornecedorId,
      tipoAcesso: this.tipoAcesso
    });

    this.painelGestaoService.registrarAcesso(dadosAcesso);
    this.clicks.next(event);
  }

  constructor(
    private painelGestaoService: PainelGestaoService
  ) 
  { }

  ngOnInit(): void {
    this.subscription = this.clicks
      .subscribe(e => this.bidClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
