import { ToastaService, ToastOptions } from "ngx-toasta";
import { HttpClient } from "@angular/common/http";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";

export class BaseService {
  toastOptions: ToastOptions = {
    title: "",
    msg: "",
    showClose: true,
    timeout: 5000,
    theme: "material",
  };

  constructor(
    protected toastaService: ToastaService,
    protected authenticationHelper: AuthenticationHelper
  ) {}

  obterArquivoDoUri(uri) {
    return new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", uri);

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send();
    });
  }

  manipuladorDeErro(error, isLoginPage = false) {
    if (error.status > 0 && error.status < 500) {
      if (error.status === 401) {
        this.authenticationHelper.removerToken();
        this.toastOptions.title = "Atenção!";
        if (isLoginPage === true) {
          this.toastOptions.msg = "Usuário ou senha invalidos.";
        } else {
          this.toastOptions.msg = "Realize o login.";
        }
        this.toastaService.warning(this.toastOptions);
      }

      if (
        error.status === 400 ||
        error.status === 404 ||
        error.status === 403
      ) {
        if (error.status === 403) {
          this.authenticationHelper.exibirDialogAcesso(error.error.mensagem);
        } else {
          this.toastOptions.title = "Atenção!";
          this.toastOptions.msg = error.error.mensagem;
          this.toastaService.warning(this.toastOptions);
        }
      }
    } else {
      this.toastOptions.title = "Erro!";
      this.toastOptions.msg =
        "Ocorreu um erro inesperado, tente novamente mais tarde.";
      this.toastaService.error(this.toastOptions);
    }
  }
}
