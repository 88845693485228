import { NgModule } from "@angular/core";
import { DetalhesFornecedorModule } from "./pages/detalhes-fornecedor/detalhes-fornecedor.module";
import { CommonModule } from "@angular/common";
import { HomeRoutingModule } from "./home-routing.module";

@NgModule({
  imports: [DetalhesFornecedorModule, CommonModule, HomeRoutingModule],
  declarations: [],
  exports: [DetalhesFornecedorModule],
})
export class HomeModule {}
