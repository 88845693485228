import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BidMensagemDialogModel } from 'src/app/Shared/Models/Base/bid-mensagem-dialog.model';

@Component({
  selector: 'app-bid-message-dialog',
  templateUrl: './bid-message-dialog.component.html',
  styleUrls: ['./bid-message-dialog.component.css']
})
export class BidMessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<BidMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BidMensagemDialogModel
  ) {}

}
