import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TipoAnuncioEnum } from 'src/app/Shared/Models/Base/Enums/tipo-anuncio.enum';

@Component({
   selector: 'app-BidDetailCard',
   templateUrl: './BidDetailCard.component.html',
   styleUrls: ['./BidDetailCard.component.scss']
})
export class BidDetailCardComponent implements OnInit {

   @Input() anuncio: any;
   @Input() donoDoAnuncio: boolean;
   @Output() fazerProposta = new EventEmitter();

   imagens = [];
   tipoAnuncioOrcamento: TipoAnuncioEnum = TipoAnuncioEnum.Orcamento;

   constructor() { }

   ngOnInit() {
   }

   obterDataTerminoBid(dataTerminoBid) {
      if (dataTerminoBid) {
         return new Date(dataTerminoBid);
      }

      return;
   }

   adicionaProposta() {
      this.fazerProposta.emit();
   }

   public getOfferImagePath(imgPath: any, index: number) {
      document.querySelector('.border-active').classList.remove('border-active');
      this.anuncio.primeiraImagem = imgPath;
      document.getElementById(index + '_img').className += " border-active";
   }

}
