import { ImagemModel } from "../Base/imagem.model";
import { CategoriaServicoEnum } from "./Enums/categoria-servico.enum";

export class ServicoModel {
  categoria: CategoriaServicoEnum;
  nome: string;
  id: number;
  imagens: Array<ImagemModel>;
  descricao: string;
  valor: number;
  mostrarMais = false;
  constructor(init?: Partial<ServicoModel>) {
    Object.assign(this, init);
  }
}
