import { Component, OnInit, HostListener } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable, of as observableOf, interval, Subscription } from "rxjs";
import {
  map,
  take,
  delay,
  withLatestFrom,
  finalize,
  tap,
} from "rxjs/operators";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { EmbryoService } from "../Services/Embryo.service";
import { MenuItems } from "../Core/menu/menu-items/menu-items";
import { Directionality } from "@angular/cdk/bidi";
import { MediaChange } from "@angular/flex-layout";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-main",
  templateUrl: "./Main.component.html",
  styleUrls: ["./Main.component.scss"],
})
export class MainComponent implements OnInit {
  timer = 0;
  isRtl: any;
  private _dirChangeSubscription = Subscription.EMPTY;
  currentUrl: any;
  showHeader: boolean;
  showFooter: boolean;

  constructor(
    private loader: LoadingBarService,
    public embryoService: EmbryoService,
    public menuItems: MenuItems,
    dir: Directionality,
    private router: Router,
    meta: Meta,
    title: Title,
    private activatedRoute: ActivatedRoute
  ) {
    title.setTitle("Bid Kids Party - Melhor plataforma de festas infantis");

    meta.addTags([
      { name: "author", content: "The IRON Network" },
      {
        name: "keywords",
        content:
          " angular, angular 2, angular 6, angular 7, angular material, clean, creative, ecommerce, frontend, online store, shop, shopping, store, typescript, ui framework ",
      },
      {
        name: "description",
        content:
          "Embryo is an E-Commerce angular 7 based template with material design. It also comes with Angular cli. Now you have all the power to maintain your ecommerce site. Responsive design gives your user to use in any modern devices. Clean Code gives you the power to customize the code as per as your requirments. Embryo has all the basics functionality which is required in ecommerce site. Rtl design makes the multi-language support with more easy way.",
      },
    ]);

    if (this.embryoService.isDirectionRtl) {
      this.isRtl = "rtl";
    } else {
      this.isRtl = "ltr";
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const routesToDontShowHeader = [
          "/home-two",
          "/home-three",
          "/session/signin",
          "/session/customer-type",
        ];

        const routesToDontShowFooter = [
          "/session/signin",
          "/session/customer-type",
        ];

        this.showHeader = !routesToDontShowHeader.includes(
          event.url.split("?")[0]
        );
        this.showFooter = !routesToDontShowFooter.includes(
          event.url.split("?")[0]
        );
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit() {
    document.getElementById("html").classList.remove("admin-panel");
    document.getElementById("html").classList.add("user-end");
  }

  public hideSideNav() {
    this.embryoService.sidenavOpen = false;
  }

  public changeDirection() {
    if (this.isRtl == "rtl") {
      this.isRtl = "ltr";
      this.embryoService.isDirectionRtl = false;
    } else {
      this.isRtl = "rtl";
      this.embryoService.isDirectionRtl = true;
    }

    this.embryoService.featuredProductsSelectedTab = 0;
    this.embryoService.newArrivalSelectedTab = 0;
  }

  /**
   * On window scroll add class header-fixed.
   */
  @HostListener("window:scroll", ["$event"])
  onScrollEvent($event) {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollTop >= 200) {
      document.querySelector("app-main").classList.add("header-fixed");
    } else {
      document.querySelector("app-main").classList.remove("header-fixed");
    }
  }

  /**
   *As router outlet will emit an activate event any time a new component is being instantiated.
   */
  onActivate(e) {
    window.scroll(0, 0);
  }
}
