import { StatusAnuncio } from "src/app/Shared/Models/Base/Enums/status-anuncio.enum";

export interface OrcamentoModel {
  bairro: string;
  bairroId: number;
  categoriaServico: number;
  cidade: string;
  cidadeId: number;
  clienteId: string;
  complemento: string;
  dataEvento: string;
  descricaoEvento: string;
  estado: string;
  estadoId: number;
  horarioEvento: string;
  idadeContratante: number;
  nomeContratante: string;
  nomeServico: string;
  numeroBid?: string | null;
  numeroConvidados: number;
  precoAbaixoDe: number;
  primeiraImagem: string;
  primeiraImagemDescricao: string;
  rua: string;
  segundaImagem: string;
  segundaImagemDescricao: string;
  servicoId: number;
  status: StatusAnuncio;
  temaEvento: string;
  tempoBid?: number | null;
  terceiraImagem: string;
  terceiraImagemDescricao: string;
  quartaImagem: string;
  quartaImagemDescricao: string;
  tipoAnuncio: TipoAnuncio;
  zona?: string;
  zonaId?: number;
  aniversariante: string;
}

export interface Endereco {
  rua: string;
  estado: CidadesEstadoModel;
  cidade: CidadesEstadoModel;
  complemento: string;
}

export interface CidadesEstadoModel {
  id: number;
  nome: string;
}

export enum TipoAnuncio {
  Anuncio = 0,
  Orcamento = 1,
}

export interface Imagem {
  url: string;
  nome: string;
  formato: string;
  descricao: string;
}

export const CategoriasServico = [
  {
    nome: "Assessoria",
    id: 1,
  },
  {
    nome: "Aluguel",
    id: 2,
  },
  {
    nome: "Bar",
    id: 3,
  },
  {
    nome: "Barraquinhas",
    id: 4,
  },
  {
    nome: "Doces",
    id: 5,
  },
  {
    nome: "Buffet",
    id: 6,
  },
  {
    nome: "Cozinheiros",
    id: 7,
  },
  {
    nome: "Decoração",
    id: 8,
  },
  {
    nome: "Estrutura",
    id: 9,
  },
  {
    nome: "Espaços",
    id: 10,
  },
  {
    nome: "FotoFilmagem",
    id: 11,
  },
  {
    nome: "Lanches",
    id: 12,
  },
  {
    nome: "Papelaria",
    id: 13,
  },
  {
    nome: "Entretenimento",
    id: 14,
  },
];
