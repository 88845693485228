import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'embryo-AppLogo',
  templateUrl: './AppLogo.component.html',
  styleUrls: ['./AppLogo.component.scss']
})
export class AppLogoComponent implements OnInit {

  @Input() imageWidth = 25;

  constructor() { }

  ngOnInit() {
  }

}
