import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  NgZone,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { EmbryoService } from "../../../Services/Embryo.service";
import { AuthenticationHelper } from "src/app/Services/helpers/authentication-helper.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenModel } from "src/app/Shared/Models/Base/token.model";
import { NotificacaoService } from "src/app/Services/notificacao.service";
import { NotificacaoModel } from "src/app/Shared/Models/Notificacoes/notificacao.model";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { UserService } from "src/app/Services/user.service";
import { TipoUsuario } from "src/app/Pages/Session/customer-type/models/new-user.model";

@Component({
  selector: "HeaderOne",
  templateUrl: "./HeaderOne.component.html",
  styleUrls: ["./HeaderOne.component.scss"],
})
export class HeaderOneComponent implements OnInit, OnDestroy {
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyMobileButtonSize();
  }

  toggleActive = false;
  cartProducts: any;
  popupResponse: any;
  wishlistProducts: any;
  estaAutenticado = false;
  estaAutenticadoSubscription: Subscription;
  tokenData = new TokenModel();
  qtdNotificacoes = 0;
  notificacoes = new Array<NotificacaoModel>();
  mostrarNotificacao = false;
  buttonSize: "medium" | "small" = "medium";
  user: SignInModel;
  isClientUser: boolean;
  showHeaderActions = true;
  homeActionByLogo = true;
  isClientUserParam = false;

  constructor(
    public embryoService: EmbryoService,
    private authenticationHelper: AuthenticationHelper,
    private notificacaoService: NotificacaoService,
    private router: Router,
    private ngZone: NgZone,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.tokenData = this.authenticationHelper.obterTokenModel();
    this.estaAutenticado = this.authenticationHelper.estaLogado();

    this.user = this.userService.user;

    this.estaAutenticadoSubscription =
      this.authenticationHelper.clienteEstaAutenticado.subscribe(
        (estaAutenticado) => {
          this.estaAutenticado = estaAutenticado;
          this.tokenData = this.authenticationHelper.obterTokenModel();

          if (this.estaAutenticado) {
            this.obterNotificacoes();
          }
        }
      );

    setTimeout(() => {
      if (this.estaAutenticado) {
        this.obterNotificacoes();
      }
    }, 500);

    setInterval(() => {
      if (this.estaAutenticado) {
        this.obterNotificacoes();
      }
    }, 60000);

    this.verifyMobileButtonSize();
    if (this.user) {
      this.userService.isClientUser = this.user.tipoUsuario === 1;
    }
    this.userService.isClientUserObservable.subscribe(
      (client) => (this.isClientUser = client)
    );
    this.isClientUserParam =
      this.activatedRoute.snapshot.queryParams["isClientUserParam"] || false;

    const possibleRoutes = ["/session/terms"];

    this.homeActionByLogo = !possibleRoutes.includes(
      this.router.url.split("?")[0]
    );
    this.showHeaderActions = this.homeActionByLogo && !this.user;
  }

  ngOnDestroy(): void {
    this.estaAutenticadoSubscription.unsubscribe();
  }

  public obterNotificacoes() {
    this.notificacaoService
      .obterNotificacao(this.tokenData.cliente)
      .subscribe((x) => {
        this.notificacoes = x.dados.notificacoes;
        this.qtdNotificacoes = x.dados.total;
        this.mostrarNotificacao = true;
      });
  }

  public toggleSearch() {
    document.querySelector("app-main").classList.toggle("form-open");
  }

  public toggleSidebar() {
    this.embryoService.sidenavOpen = !this.embryoService.sidenavOpen;
  }

  public openConfirmationPopup(value: any) {
    let message = "Are you sure you want to delete this product?";
    this.embryoService.confirmationPopup(message).subscribe(
      (res) => {
        this.popupResponse = res;
      },
      (err) => console.log(err),
      () => this.getPopupResponse(this.popupResponse, value, "cart")
    );
  }

  public getPopupResponse(response: any, value: any, type) {
    if (response) {
      if (type == "cart") {
        this.embryoService.removeLocalCartProduct(value);
      } else {
        this.embryoService.removeLocalWishlistProduct(value);
      }
    }
  }

  public addAllWishlistToCart(values: any) {
    this.embryoService.addAllWishListToCart(values);
  }

  public openWishlistConfirmationPopup(value: any) {
    let message = "Are you sure you want to add all products?";
    this.embryoService.confirmationPopup(message).subscribe(
      (res) => {
        this.popupResponse = res;
      },
      (err) => console.log(err),
      () => this.getPopupResponse(this.popupResponse, value, "wishlist")
    );
  }

  public selectedCurrency(value) {
    this.embryoService.currency = value;
  }

  public selectedLanguage(value) {
    this.embryoService.language = value;
  }

  public addToCart(value) {
    this.embryoService.addToCart(value, "wishlist");
  }

  private verifyMobileButtonSize() {
    if (window.innerWidth < 468) this.buttonSize = "small";
    else this.buttonSize = "medium";
  }

  redirectToLogin() {
    this.ngZone.run(() => {
      this.router.navigate(["/session/signin"]);
    });
  }
  redirectToRegister() {
    this.ngZone.run(() => {
      this.router.navigate(["/session/customer-type"]);
    });
  }
  redirectToUserTerms() {
    this.ngZone.run(() => {
      this.router.navigate(["/session/terms"]);
    });
  }

  setValueToIsClientUser(newValue: boolean) {
    this.userService.isClientUser = newValue;
  }

  redirectToHome() {
    this.router.navigate(["/home"]);
  }

  redirectToProfile() {
    if (this.user.tipoUsuario === TipoUsuario.Cliente)
      this.router.navigate(["/perfil"]);
    else this.router.navigate(["/perfil-fornecedor"]);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.redirectToLogin();
  }
}
