import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { finalize } from 'rxjs/operators';
import { FornecedorDialogComponent } from 'src/app/Pages/Fornecedores/fornecedor-lista/fornecedor-dialog/fornecedor-dialog.component';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { OrcamentoService } from 'src/app/Services/orcamento.service';
import { EnviarOrcamentoVmModel } from 'src/app/Shared/Models/Anuncios/ViewModels/enviar-orcamento-vm.model';
import { ObterOrcamentoVmModel } from 'src/app/Shared/Models/Anuncios/ViewModels/obter-orcamento-vm.model';
import { BidBudgetsDialogModel } from 'src/app/Shared/Models/Base/bid-budgets-dialog.model';
import { TokenModel } from 'src/app/Shared/Models/Base/token.model';
import { CategoriaServicoEnum } from 'src/app/Shared/Models/Servicos/Enums/categoria-servico.enum';

@Component({
  selector: 'app-bid-budgets-dialog',
  templateUrl: './bid-budgets-dialog.component.html',
  styleUrls: ['./bid-budgets-dialog.component.css']
})
export class BidBudgetsDialogComponent implements OnInit {
  
  toastOptions: ToastOptions = {
    title: '',
    msg: '',
    showClose: true,
    timeout: 5000,
    theme: 'material'
  };

  displayedColumns: string[] = ['servico', 'descricao', 'data'];
  orcamentoDataSource = new MatTableDataSource<any>([]);
  linhaSelecionada = new SelectionModel<any>(false, []);

  tokenModel: TokenModel;
  isLoading = true;

  constructor(
    public dialogRef : MatDialogRef<BidBudgetsDialogComponent>,
    private fornecedorDialogRef: MatDialogRef<FornecedorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BidBudgetsDialogModel,
    private orcamentoService: OrcamentoService,
    private authenticationHelper: AuthenticationHelper,
    private toastaService: ToastaService,
    private router: Router
  ) { }

  ngOnInit() {
    this.orcamentoDataSource = new MatTableDataSource<any>([]);
    this.tokenModel = this.authenticationHelper.obterTokenModel();

    let obterOrcamento = new ObterOrcamentoVmModel({
      clienteId: this.tokenModel.cliente,
      fornecedorId: this.data.fornecedorId
    });

    this.orcamentoService.obterOrcamentos(obterOrcamento, false)
      .pipe(finalize(() => this.isLoading = false ))
      .subscribe(resposta => {
        let orcamentos: any[] = [];

        resposta.dados.map(orcamento => {

          orcamentos.push({
            anuncioId: orcamento.id,
            nomeServico: CategoriaServicoEnum[orcamento.categoriaServico],
            descricaoEvento: (orcamento.descricaoEvento.trim().length > 80) ? 
              orcamento.descricaoEvento.trim().substr(0, 80) + '...' :
              orcamento.descricaoEvento.trim(),
            dataEvento: orcamento.dataEvento,
            horarioEvento: orcamento.horarioEvento.trim().substr(0, 5)
          })
        });

        this.orcamentoDataSource = new MatTableDataSource<any>(orcamentos);
      });
  }

  temOrcamentos() {
    return this.orcamentoDataSource && this.orcamentoDataSource.data.length > 0;
  }

  habilitarBotaoEnviar() {
    return !this.linhaSelecionada.hasValue();
  }

  criarOrcamento() {
    this.dialogRef
      .beforeClosed()
      .subscribe(() => {
        this.fornecedorDialogRef.close();    
        this.router.navigateByUrl('orcamentos/novo');
      });

    this.dialogRef.close();
  }

  enviarOrcamento() {
    const orcamento: EnviarOrcamentoVmModel = 
      new EnviarOrcamentoVmModel({
        anuncioId: this.linhaSelecionada.selected[0].anuncioId,
        clienteId: this.tokenModel.cliente,
        clienteEmail: this.tokenModel.email,
        fornecedorId: this.data.fornecedorId,
        tipoUsuario: this.authenticationHelper.obterTipoUsuario()
      });

    this.orcamentoService.enviarOrcamento(orcamento)
      .then(resposta => {
        this.toastOptions.title = 'Sucesso';
        this.toastOptions.msg = 'Orçamento enviado com sucesso!';
        this.toastaService.success(this.toastOptions);
        this.dialogRef.close();
      });
  }

}
