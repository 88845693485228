import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-aba-localizacao',
  templateUrl: './aba-localizacao.component.html',
  styleUrls: ['./aba-localizacao.component.scss']
})
export class AbaLocalizacaoComponent implements OnInit {

  @Input()
  fornecedorId: string;
  constructor() { }

  ngOnInit() {
  }

}
