import { Pipe, PipeTransform } from "@angular/core";
import { CategoriaServicoEnum } from "../Models/Servicos/Enums/categoria-servico.enum";

@Pipe({
  name: "categoriaNome",
})
export class GetCategoriaPorIdPipe implements PipeTransform {
  transform(categoria: number) {
    const categoriaEnum = CategoriaServicoEnum;
    const keys = Object.keys(categoriaEnum)
      .map((key) => categoriaEnum[key])
      .filter((value) => typeof value === "string") as string[];
    return keys[categoria - 1];
  }
}
