import { TipoUsuarioEnum } from './Enums/role.enum';
import { TipoPlanoEnum } from '../Planos/Enums/tipo-plano.enum';

export class TokenModel {

    public email: string;
    public name: string;
    public nomeFantasia: string;
    public accessToken: string;
    public cliente: string;
    public plano: TipoPlanoEnum;
    public role: TipoUsuarioEnum;
    public imagemPerfil: string;
    public imagemPerfilCapa: string;

    set salvarImagemPerfil(url) {
        this.imagemPerfil = url;
    }

    set salvarImagemPerfilCapa(url) {
        this.imagemPerfilCapa = url;
    }

    constructor(values?: TokenModel) {
        Object.assign(this, values);
    }
}
