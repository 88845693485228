import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';

@Component({
  selector: 'app-criar-bid',
  templateUrl: './criar-bid.component.html',
  styleUrls: ['./criar-bid.component.css']
})
export class CriarBidComponent {

  constructor(
    private authenticationHelper: AuthenticationHelper,
    private router: Router 
  ) {}

  redicionarParaCriarBid() {
    if (this.authenticationHelper.estaLogado()) {
       this.router.navigate(['/bids/novo']);
    }
    else {
       this.router.navigate(['/bids/novo-nao-logado']);
    }

 }

}