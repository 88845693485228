import { HttpClient } from "@angular/common/http";
import { OrcamentoModel } from "./models/orcamento.model";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { pluck } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrcamentoService {
  constructor(private http: HttpClient) {}

  postCriarNovoAnuncio(anuncio: OrcamentoModel) {
    return this.http.post(`${environment.endpoints.backend}/anuncio`, anuncio);
  }

  postGetBairros(
    cidadeIds: number[]
  ): Observable<Array<{ id: number; nome: string; idCidade: number }>> {
    return this.http
      .post(`${environment.endpoints.backend}/localidades/bairros`, cidadeIds)
      .pipe(pluck("dados"));
  }
}
