import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-bid-video-player',
  templateUrl: './bid-video-player.component.html',
  styleUrls: ['./bid-video-player.component.css']
})
export class BidVideoPlayerComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<BidVideoPlayerComponent>,
      @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit() {
  }

}
