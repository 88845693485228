import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PainelGestaoModel } from '../Shared/Models/PainelGestao/painel-gestao.model';
import { Observable } from 'rxjs';
import { RespostaModel } from '../Shared/Models/Base/resposta.model';
import { PainelGestaoTotalModel } from '../Shared/Models/PainelGestao/painel-gestao-total.model';

@Injectable()
export class PainelGestaoService {

  private readonly url = environment.endpoints.backend + '/PainelGestao';
  private httpHeaders: HttpHeaders = new HttpHeaders({ loading: 'false' });

  constructor(
    private http: HttpClient
  ) { }

  registrarAcesso(dadosAcesso: PainelGestaoModel) {
    this.http
      .post(`${this.url}`, dadosAcesso, 
        { 
          headers: this.httpHeaders 
        })
        .subscribe();
  }

  obterTotais(fornecedorId: string): Observable<RespostaModel<PainelGestaoTotalModel>> {
    return this.http
      .get<RespostaModel<PainelGestaoTotalModel>>
      (`${this.url}/ObterTotais/${fornecedorId}`);
  }

}
