import {
  Component,
  Inject,
  Input,
  OnInit
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-modal",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"],
})
export class LoginModalComponent implements OnInit {
  link: string = window.location.href;
  @Input()
  redirect: string = "";

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      redirect: string;
    }
  ) {
    this.redirect = data.redirect;
  }

  ngOnInit() {
  }

  goToLogin() {
    this.router.navigate(["/session/signin"], {queryParams: {redirect: this.redirect}});
    this.closeModal();
  }

  goToRegister() {
    this.router.navigate(["/session/customer-type"], {queryParams: {redirect: this.redirect}});
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
