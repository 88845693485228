import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ToastaService } from "ngx-toasta";
import { BaseService } from "./base.service";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { Observable } from "rxjs";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { Router } from "@angular/router";
import { AnuncioVmModel } from "../Shared/Models/Anuncios/ViewModels/anuncio-vm.model";
import { StatusAnuncio } from "../Shared/Models/Base/Enums/status-anuncio.enum";
import { TipoVisualizacaoAnuncioEnum } from "../Shared/Models/Base/Enums/tipo-visualizacao-anuncio.enum";
import { PropostaModel } from "../Shared/Models/Anuncios/proposta.model";
import { FornecedorMelhorAvaliadoVmModel } from "../Shared/Models/Fornecedores/ViewModels/fornecedor-melhor-avaliado-vm.model";
import { pluck, shareReplay } from "rxjs/operators";
import { AnuncioModel } from "../Shared/Models/Anuncios/anuncio.model";
import { FinalizarPropostaModel } from "../Shared/Models/Anuncios/finalizar-proposta.model";
import {
  AnuncioFornecedorStatus,
  OrcamentoFornecedorStatus,
} from "../Pages/perfil-fornecedor/components/meus-clientes/utils/anuncio-fornecedor-status.utils";
import { TipoAnuncio } from "../Pages/profile/components/orcamento/models/orcamento.model";
import { CategoriaServicoEnum } from "../Shared/Models/Servicos/Enums/categoria-servico.enum";

@Injectable()
export class AnuncioService extends BaseService {
  private readonly anuncioChaveLocalstorage = "_@nV#_N$10_";
  private readonly url = environment.endpoints.backend + "/anuncio";

  constructor(
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    toastaService: ToastaService,
    private router: Router
  ) {
    super(toastaService, authenticationHelper);
  }

  obterMenu(): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(`${this.url}/menu`);
  }

  obterPorId(
    id: string,
    detalhesProposta: boolean = false
  ): Observable<RespostaModel<AnuncioVmModel>> {
    return this.http.get<RespostaModel<AnuncioVmModel>>(
      `${this.url}/${id}?detalhesPropostas=${detalhesProposta}`
    );
  }

  obterBidsPorInteresse(fornecedorId): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(
      `${this.url}/interesse?fornecedorId=${fornecedorId}`
    );
  }

  obterBidsQueGanhei(fornecedorId): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(
      `${this.url}/ganhador?fornecedorId=${fornecedorId}`
    );
  }

  obterBidsQueParticipo(fornecedorId): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(
      `${this.url}/participante?fornecedorId=${fornecedorId}`
    );
  }

  obterPorStatus(data: {
    clienteId: string;
    statusAnuncio: StatusAnuncio;
    tipoVisualizacaoAnuncio: TipoVisualizacaoAnuncioEnum;
    ultimoMes: boolean;
  }): Observable<RespostaModel<any>> {
    let params = new HttpParams();
    params = params.set("clienteId", data.clienteId);
    params = params.set("statusAnuncio", data.statusAnuncio.toString());
    params = params.set(
      "tipovisualizacaoanuncio",
      data.tipoVisualizacaoAnuncio.toString()
    );
    params = params.set("ultimoMes", data.ultimoMes.toString());
    return this.http.get<RespostaModel<any>>(`${this.url}`, { params });
  }

  finalizarBid(anuncioId: string, propostasVm: Array<PropostaModel>, propostasVmSelecionadas: Array<PropostaModel>) {
    let finalizar = new FinalizarPropostaModel();
    finalizar.propostasVm = propostasVm;
    finalizar.propostasVmSelecionadas = propostasVmSelecionadas;    

    return new Promise((resolve, reject) => {
      this.http
        .put<RespostaModel<any>>(
          `${this.url}/${anuncioId}/finalizar`,
          finalizar
        )
        .subscribe(
          (res) => {
            resolve(res.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  cadastro(anuncioModel: AnuncioVmModel) {
    this.http
      .post<RespostaModel<AnuncioVmModel>>(`${this.url}`, anuncioModel)
      .subscribe(
        (res) => {
          this.toastOptions.title = "Sucesso!";

          anuncioModel = res.dados;
          if (anuncioModel.status === 0) {
            this.toastOptions.msg = "Bid salvo como rascunho.";
            this.toastaService.success(this.toastOptions);
            this.router.navigate(["/bids/meus-bids/rascunho"]);
          } else if (anuncioModel.status === 6) {
            this.toastOptions.msg = "Orçamento salvo com sucesso.";
            this.toastaService.success(this.toastOptions);
            this.router.navigate(["/orcamentos/meus-orcamentos"]);
          } else {
            this.toastOptions.msg = "Bid registrado com sucesso.";
            this.toastaService.success(this.toastOptions);
            this.router.navigate(["/bids/meus-bids/emprogresso"]);
          }
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }

  atualizar(anuncioModel: AnuncioVmModel) {
    this.http
      .put<RespostaModel<AnuncioVmModel>>(
        `${this.url}/${anuncioModel.id}`,
        anuncioModel
      )
      .subscribe(
        (res) => {
          this.toastOptions.title = "Sucesso!";

          anuncioModel = res.dados;
          if (anuncioModel.status === 0) {
            this.toastOptions.msg = "Bid salvo como rascunho.";
            this.toastaService.success(this.toastOptions);
            this.router.navigate(["/bids/meus-bids/rascunho"]);
          } else {
            this.toastOptions.msg = "Bid registrado com sucesso.";
            this.toastaService.success(this.toastOptions);
            this.router.navigate(["/bids/meus-bids/emprogresso"]);
          }
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }

  excluir(id) {
    this.http
      .delete<RespostaModel<AnuncioVmModel>>(`${this.url}/${id}`)
      .subscribe(
        (res) => {
          this.toastOptions.title = "Sucesso!";
          this.toastOptions.msg = "BID excluído sucesso!";
          this.router.navigate(["/bids"]);
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }

  gravarAnuncioSessao(anuncio) {
    localStorage.setItem(
      this.anuncioChaveLocalstorage,
      JSON.stringify(anuncio)
    );
  }

  cadastrarAnuncioSessao() {
    let anuncio = JSON.parse(
      localStorage.getItem(this.anuncioChaveLocalstorage)
    ) as AnuncioVmModel;
    if (anuncio) {
      this.cadastro(anuncio);
      localStorage.removeItem(this.anuncioChaveLocalstorage);
      return true;
    }

    return false;
  }

  //#region  Fornecedor

  /**
   * OBS IMPORTANTE: POR ALGUM MOTIVO, AO TENTAR IMPORTAR O SERVIÇO DO FORNECEDOR
   * NA HOME, OCORREM ALGUNS ERROS PELA FALTA DA IMPORTAÇÃO DE OUTROS SERVIÇOS,
   * MESMO IMPORTANDO, OUTROS ERROS VÃO APARECENDO. (DEVE SER TEMPORÁRIO)
   */

  buscarFornecedoresMelhoresAvaliados() {
    const urlFornecedor =
      environment.endpoints.backend + "/Fornecedor/ObterMelhoresAvaliados";
    return this.http.get<RespostaModel<FornecedorMelhorAvaliadoVmModel[]>>(
      urlFornecedor
    );
  }

  buscarOrcamentosPorFornecedorId(
    fornecedorId: string,
    ultimoMes: boolean
  ): Observable<AnuncioModel[]> {
    const params = new HttpParams().set("ultimoMes", ultimoMes.toString());

    return this.http
      .get<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${fornecedorId}/buscarOrcamentosPorId`,
        { params }
      )
      .pipe(shareReplay(), pluck("dados"));
  }
  buscarOrcamentosPorClienteId(
    clienteId: string,
    ultimoMes: boolean
  ): Observable<AnuncioModel[]> {
    const params = new HttpParams().set("ultimoMes", ultimoMes.toString());
    return this.http
      .get<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${clienteId}/cliente/buscarOrcamentosPorId`,
        { params }
      )
      .pipe(shareReplay(), pluck("dados"));
  }
  //
  buscarBidsPorFornecedorIdEStatus(data: {
    fornecedorId: string;
    status: AnuncioFornecedorStatus;
    ultimoMes: boolean;
  }) {
    let params = new HttpParams();
    params = params.set("status", data.status.toString());
    params = params.set("ultimoMes", data.ultimoMes.toString());

    return this.http
      .get<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${data.fornecedorId}/fornecedor/buscarBidsPorFornecedorId`,
        { params }
      )
      .pipe(pluck("dados"));
  }

  buscarOrcamentosEnviadosPorFornecedorId(data: {
    fornecedorId: string;
    ultimoMes: boolean;
  }) {
    let params = new HttpParams();
    params = params.set("ultimoMes", data.ultimoMes.toString());

    return this.http
      .get<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${data.fornecedorId}/fornecedor/buscarOrcamentosEnviadosPorFornecedorId`,
        { params }
      )
      .pipe(pluck("dados"));
  }

  buscarOrcamentosPorClienteIdECategoria(clienteId: string, servicoId: number) {
    let params = new HttpParams();
    params = params.set("servicoId", servicoId.toString());
    return this.http
      .get<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${clienteId}/fornecedor/buscarOrcamentosPorClientedECategoria`,
        { params }
      )
      .pipe(pluck("dados"));
  }

  buscarOrcamentosPorClienteIdEFornecedorId(clienteId: string, fornecedorId: string) {
    let params = new HttpParams();
    params = params.set("fornecedorId", fornecedorId.toString());
    return this.http
      .get<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${clienteId}/fornecedor/buscarOrcamentosPorClienteIdEFornecedorId`,
        { params }
      )
      .pipe(pluck("dados"));
  }  

  atualizarPropostas(anuncioId: string, anuncio: AnuncioVmModel) {
    return this.http
      .post<RespostaModel<AnuncioModel[]>>(
        `${environment.endpoints.backend}/anuncio/${anuncioId}/cliente/atualizarPropostas`,
        anuncio
      )
      .pipe(pluck("dados"));
  }
}
