import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { ToastaService } from "ngx-toasta";
import { HttpClient } from "@angular/common/http";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { Router } from "@angular/router";
import { SessaoService } from "./sessao.service";
import { pluck } from "rxjs/operators";
import { LocalizacaoModel } from "../Shared/Models/Base/localizacao.mode";

@Injectable({ providedIn: "root" })
export class FornecedorLocalizacaoService extends BaseService {
  private readonly url = environment.endpoints.backend + "/PerfilLocalizacao";

  constructor(
    toastaService: ToastaService,
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private router: Router,
    private sessaoService: SessaoService
  ) {
    super(toastaService, authenticationHelper);
  }

  buscarLocalizacao(usuarioId: string): Promise<LocalizacaoModel> {
    return new Promise((resolve, reject) => {
      this.http
        .get<LocalizacaoModel>(`${this.url}/${usuarioId}/fornecedor`)
        .pipe(pluck("dados"))
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject(null);
          }
        );
    });
  }

  gravar(
    usuarioId: string,
    localizacao: LocalizacaoModel
  ): Promise<LocalizacaoModel> {
    return new Promise((resolve, reject) => {
      this.http
        .post<LocalizacaoModel>(
          `${this.url}/${usuarioId}/fornecedor`,
          localizacao
        )
        .subscribe(
          (response: any) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Localização cadastrada com sucesso!";
            this.toastaService.success(this.toastOptions);
            resolve(response);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject(null);
          }
        );
    });
  }
}
