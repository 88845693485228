import { ClienteBaseModel } from '../Base/cliente-base.model';
import { PerfilModel } from './perfil.model';
import { FornecedorAvaliacaoNodeModel } from './ViewModels/fornecedor-avaliacao-node.model';
import { FornecedorServicoExtraVmModel } from './ViewModels/fornecedor-servico-extra-vm.model';
import { LocalizacaoModel } from '../Base/localizacao.mode';

export class FornecedorModel extends ClienteBaseModel {

    private _avaliacoes: Array<FornecedorAvaliacaoNodeModel> =
        new Array<FornecedorAvaliacaoNodeModel>();
    private _mediaAvaliacao = 0;
    private _totalAvaliacao = 0;

    public perfil: PerfilModel;
    public nomeFantasia: string;
    public localizacao: LocalizacaoModel;
    public servicosExtras: FornecedorServicoExtraVmModel[];

    public estaSelecionado: any;
    public totalBidsFechados = 0;

    constructor(init?: Partial<FornecedorModel>) {
        super();
        Object.assign(this, init);
    }

    private calcularMediaAvliacoes(): void {
        if (!this._avaliacoes) {
            this._totalAvaliacao = 0;
            this._mediaAvaliacao = 0;
            return;
        }

        this._totalAvaliacao = this._avaliacoes.length;
        const notas = this._avaliacoes.map(i => i.nota);

        const soma = notas.reduce((a, b) => a + b, 0);
        this._mediaAvaliacao = (soma / this._totalAvaliacao);
        if (isNaN(this._mediaAvaliacao))
            this._mediaAvaliacao = 0;
    }

    get avaliacoes(): Array<FornecedorAvaliacaoNodeModel> {
        return this._avaliacoes;
    }

    set avaliacoes(value: Array<FornecedorAvaliacaoNodeModel>) {
        this._avaliacoes = value;
        this.calcularMediaAvliacoes();
    }

    get mediaAvaliacao(): number {
        return this._mediaAvaliacao;
    }

    get totalAvaliacao(): number {
        return this._totalAvaliacao;
    }
}
