import { TipoUsuarioEnum } from "../../Base/Enums/role.enum";

export class EnviarOrcamentoVmModel {
  clienteId: string;
  clienteEmail: string;
  fornecedorId: string;
  anuncioId: string;
  tipoUsuario: TipoUsuarioEnum;

  constructor(init?: Partial<EnviarOrcamentoVmModel>) {
    Object.assign(this, init);
  }
}
