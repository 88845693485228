import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { ClienteBaseService } from "src/app/Services/cliente.servico";
import { FornecedorLocalizacaoService } from "src/app/Services/fornecedor.localizacao.service";
import { PerfilService } from "src/app/Services/perfil.service";
import { UserService } from "src/app/Services/user.service";
import { RedesSociaisEnum } from "src/app/Shared/Models/Base/Enums/rede-social.enum";
import { TipoUsuarioEnum } from "src/app/Shared/Models/Base/Enums/role.enum";
import { EnderecoModel } from "src/app/Shared/Models/Base/endereco.model";
import { IdentidadeModel } from "src/app/Shared/Models/Base/identidade.model";
import { RedeSocialModel } from "src/app/Shared/Models/Base/rede-social.model";
import { FornecedorAvaliacaoNodeModel } from "src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model";
import { TipoPlanoEnum } from "src/app/Shared/Models/Planos/Enums/tipo-plano.enum";
import { ServicoEmAndamentoModel } from "src/app/Shared/Models/Servicos/servico-em-andamento.model";
import { CidadeSp, EstadoSp } from "src/app/Shared/utils/cidade-estado.utils";
import Swal from "sweetalert2";
import { DadosPerfilModel } from "../perfil-fornecedor/dados-perfil.model";
import { ImagemModel } from "../../../../Shared/Models/Base/imagem.model";
import { AuthenticationHelper } from "src/app/Services/helpers/authentication-helper.service";
enum MenuEnum {
  InfoPerfil = 1,
  Fotos = 2,
  Servicos = 3,
  PerguntasFrequentes = 4,
  ProdutosServicos = 5,
  Avaliacoes = 6,
  Plano = 7,
  DadosBancarios = 8,
}
@Component({
  selector: "app-meu-perfil",
  templateUrl: "./meu-perfil.component.html",
  styleUrls: ["./meu-perfil.component.scss"],
})
export class MeuPerfilComponent implements OnInit {
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }

  isPremium = false;
  panelOpenState = false;
  menuEnum = MenuEnum;
  questao: any;
  selectedTab = 1;
  inProgress = {
    categoria: 2,
    dataTerminoBid: "12/10/2023",
    id: "123",
    nomeServico: "teste",
    precoAbaixoDe: 40,
    primeiraImagem: "asd",
    primeiraImagemDescricao: "erwer",
    status: 2,
  } as ServicoEmAndamentoModel;
  selectedMenu = 1;
  mobileView: boolean;
  infoForm!: FormGroup;
  eventListenerToImg = [];
  imgControler = [];
  user: SignInModel;
  fotos: Array<ImagemModel> = [];
  profileData = {} as DadosPerfilModel;
  countOfPickturesByUserPlan: number;
  documentMask: string;
  avaliacoes: Array<FornecedorAvaliacaoNodeModel> | any = [];

  constructor(
    private formBuilder: FormBuilder,
    private perfilService: PerfilService,
    private userService: UserService,
    private localizacaoService: FornecedorLocalizacaoService,
    private clienteService: ClienteBaseService,
    private authenticationHelper: AuthenticationHelper,
    private cdrf: ChangeDetectorRef
  ) {
    this.user = userService.user;
    this.infoForm = formBuilder.group({
      nomeFantasia: [],
      apresentacao: [],
      tipoIdentidade: [],
      numeroIdentidade: [],
      facebook: [],
      instagram: [],
      whatsapp: [],
      localizacao: [],
    });
  }

  ngOnInit() {
    this.verifyScreenSize();
    this.obterFotos();
    this.obtemDadosPerfil();
    this.obtemRedesSociais();

    this.localizacaoService
      .buscarLocalizacao(this.user.id)
      .then((localizacao) => {});

    this.infoForm.get("tipoIdentidade").valueChanges.subscribe((changes) => {
      switch (changes) {
        case 1:
          this.documentMask = "000.000.000-00";
          break;
        case 2:
          this.documentMask = "00.000.000/0000-00";
          break;
      }
    });
  }

  changeResposta(idx: any) {
    const el = document.getElementById("questao-" + idx);
    if (el) {
      const resposta = el["value"];
      this.avaliacoes[idx].respostas = [
        {
          autor: this.user.nomeUsuario,
          autorId: this.user.id,
          data: new Date(),
          texto: resposta,
          usuarioId: this.user.id,
          nota: this.avaliacoes[idx].nota,
          estaLogado: this.avaliacoes[idx].estaLogado,
          email: this.user.nomeUsuario,
        },
      ];

      this.perfilService
        .adicionarResposta(this.user.id, this.avaliacoes)
        .subscribe((resp) => {
          this.obtemDadosPerfil();
        });
    }
  }

  recount(idx: any): number {
    const el = document.getElementById("questao-" + idx);
    if (el) return el["textLength"];
    return 0;
  }

  public validateLink(link: any, validationType = "instagram"): boolean {
    console.log('Link: ', link);
    if (!link) return true;
    if (link.length === 0) return true;
    
    if (typeof link != "string") {
      link = link[0];
    }

    if (!link.includes(validationType) || !link.includes("https")) {
      return false;
    }

    return true;
  }

  private verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }
  imageCropped(event: any, key: number) {
    this.imgControler[key] = {
      name: this.eventListenerToImg[key].target.files[0].name,
      base64: event.original.base64,
    };
  }
  setImage(event, key: number) {
    this.eventListenerToImg[key] = event;
    console.log("this.eventListenerToImg", this.eventListenerToImg);
    this.uploadImagensFornecedor(event.target.files[0]);
  }

  uploadImagensFornecedor(file: any) {
    let data = new FormData();
    // let imgPosition = 0;

    // if (this.fotos.length) {
    //   this.fotos.forEach((foto) => {
    //     imgPosition++;
    //     data.set(`img${imgPosition}`, foto);
    //   });
    // }
    data.set(`img0;`, file);

    // this.eventListenerToImg.forEach((event) => {
    //   if (event) {
    //     imgPosition++;
    //   }
    // });

    this.setMaxOfPickturesByPlan();

    this.perfilService
      .uploadImagensFornecedor(this.user.id, data)
      .then((resp) => {
        this.obterFotos();
      });
  }
  removePicture(foto: ImagemModel) {
    this.perfilService
      .removerFotoPorFornecedorId(this.user.id, foto)
      .then((resp) => {
        this.obterFotos();
      });
  }
  obtemDadosPerfil() {
    this.perfilService
      .obterDadosPerfil(this.user.id, TipoUsuarioEnum.Fornecedor)
      .then((resp: DadosPerfilModel) => {
        this.profileData = resp;
        this.setMaxOfPickturesByPlan();
        this.avaliacoes = this.profileData.avaliacoes;
        this.isPremium = this.profileData.meuPlano.tipoPlano !== TipoPlanoEnum.Gratis;
        this.infoForm.patchValue({
          nomeFantasia: this.profileData.nomeFantasia,
          apresentacao: this.profileData.titulo || "",
          localizacao: this.profileData.endereco.rua,
          tipoIdentidade: this.profileData.identidade.tipo || "",
          numeroIdentidade: this.profileData.identidade.numero || "",
        });
      });
  }
  obterFotos() {
    this.perfilService
      .obterFotos(this.user.id)
      .then((resp: Array<ImagemModel>) => {
        this.fotos = resp;
      });
  }
  obtemRedesSociais() {
    this.perfilService.obterRedesSociais(this.user.id).then((response: any) => {
      if (!response) return;
      const redes = response.redeSociais || [];

      this.infoForm.patchValue({
        instagram: redes!.filter((r) => r.tipoRedeSocial === 2)![0].link || '',
        whatsapp: redes!.filter((r) => r.tipoRedeSocial === 6)[0].link || ''
      });
    });
  }

  gravarRedesSociais() {
    const redesSociais: RedeSocialModel[] = [
      {
        tipoRedeSocial: RedesSociaisEnum.Facebook,
        link: this.infoForm.controls["facebook"].value || "",
      },
      {
        tipoRedeSocial: RedesSociaisEnum.Instagram,
        link: this.infoForm.controls["instagram"].value || "",
      },
      {
        tipoRedeSocial: RedesSociaisEnum.WhatsApp,
        link: this.infoForm.controls["whatsapp"].value || "",
      },
    ];

    const dados = {
      website: "",
      redeSociais: redesSociais,
    };

    this.perfilService.gravarRedesSociais(this.user.id, dados).subscribe(
      () => {
        Swal.fire(
          "Sucesso",
          "Redes sociais atualizadas com sucesso",
          "success"
        );
      },
      (error) => {
        Swal.fire("Erro", error.error.mensagem, "error");
      }
    );
  }

  putAtualizarDadosPerfil() {
    const endereco = {
      cidade: CidadeSp,
      complemento: "",
      estado: EstadoSp,
      rua: this.infoForm.get("localizacao").value,
    } as EnderecoModel;

    const data = {
      nomeFantasia: this.infoForm.get("nomeFantasia").value,
      titulo: this.infoForm.get("apresentacao").value,
      endereco,
      identidade: {
        numero: this.infoForm.get("numeroIdentidade").value,
        tipo: this.infoForm.get("tipoIdentidade").value,
      } as IdentidadeModel,
    };
    // if (this.profileData.meuPlano.tipoPlano !== 1) {

    if (!this.validateLink(this.infoForm.controls["instagram"].value)) {
      Swal.fire("Erro", "Digite um link do instagram válido", "error");
      return;
    }

    this.gravarRedesSociais();
    // }

    this.perfilService
      .putAtualizarDadosPerfil(this.user.id, data)
      .subscribe(() => {
        Swal.fire("Sucesso", "Dados atualizados com sucesso", "success");
        this.obtemDadosPerfil();
      });
  }

  buscarAvaliacoesPorClienteId() {
    return this.clienteService
      .obterAvaliacaoPorUsuario(this.user.id)
      .subscribe((response) => {
        if (response) {
          this.avaliacoes = response.dados;
        }
      });
  }

  private setMaxOfPickturesByPlan() {
    this.profileData.meuPlano.tipoPlano !== TipoPlanoEnum.Gratis
      ? (this.countOfPickturesByUserPlan = 20)
      : (this.countOfPickturesByUserPlan = 5);

    for (let i = 0; i < this.countOfPickturesByUserPlan; i++) {
      this.imgControler.push(null);
      this.eventListenerToImg.push(null);
      this.cdrf.markForCheck();
    }
  }
}
