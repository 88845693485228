import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { FornecedorModel } from "../Shared/Models/Fornecedores/fornecedor.model";
import { BaseService } from "./base.service";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { Router } from "@angular/router";
import { ToastaService } from "ngx-toasta";

@Injectable()
export class GetNetService extends BaseService {
  private readonly url = environment.endpoints.backend + "/getnet";

  constructor(
    toastaService: ToastaService,
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private router: Router
  ) {
    super(toastaService, authenticationHelper);
  }

  gerarOrdem(ordem: any): Observable<RespostaModel<any>> {
    return this.http.post<RespostaModel<any>>(
      `${this.url}/geracao-ordem`,
      ordem
    );
  }
}
