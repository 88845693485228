import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { ServicosOfertadosModel } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/servicos-ofertados.model";
import { DadosPerfilModel } from "src/app/Pages/perfil-fornecedor/components/perfil-fornecedor/dados-perfil.model";
import { ImagemModel } from "src/app/Shared/Models/Base/imagem.model";
import { CategoriaServicoEnum } from "src/app/Shared/Models/Servicos/Enums/categoria-servico.enum";
import { DetalhesFornecedorService } from "../../../../detalhes-fornecedor.service";
import { FornecedorService } from "../../../../../../../../Services/fornecedor.service";
import { CidadeModel } from "../../../../../../../../Shared/Models/Base/cidade.model";
import { TipoPlanoEnum } from "src/app/Shared/Models/Planos/Enums/tipo-plano.enum";
import { MatDialog } from "@angular/material";
import { CompartilharModalModalComponent } from "./../../components/compartilhar-modal/compartilhar-modal.component";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { LoginModalComponent } from "./../../components/login-modal/login-modal.component";
import { AvaliacaoModalComponent } from "./../../components/avaliacao-modal/avaliacao-modal.component";
import { PerfilService } from "../../../../../../../../Services/perfil.service";
import { RedesSociaisEnum } from "src/app/Shared/Models/Base/Enums/rede-social.enum";

@Component({
  selector: "app-aba-perfil",
  templateUrl: "./aba-perfil.component.html",
  styleUrls: ["./aba-perfil.component.scss"],
})
export class AbaPerfilComponent implements OnInit, OnChanges {
  @Input()
  fornecedorId: string;

  @Input()
  dadosPerfil = {} as DadosPerfilModel;

  @Input()
  servicos: Array<ServicosOfertadosModel>;

  fotos: Array<ImagemModel> = [];
  fotosHighlight: Array<ImagemModel> = [];
  servicosEnum = CategoriaServicoEnum;
  servicosOferdadosLista: Array<CategoriaServicoEnum>;
  cidadesDisponiveis: Array<string>;

  showGallery: boolean = false;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  cidadesFornecedor: CidadeModel[];
  fornecedorDetalhes = {} as DadosPerfilModel;
  isPremium = false;
  user: SignInModel;
  mobileView: boolean;
  imagens: Array<string> = [];

  redesSociais = {} as {
    Instagram: string;
    WhatsApp: string;
  };

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
  };

  constructor(
    private service: DetalhesFornecedorService,
    private fornecedorService: FornecedorService,
    public dialog: MatDialog,
    private perfilService: PerfilService
  ) {}

  ngOnInit() {
    this.obtemFotos();
    this.buscarCidadesAtuacao();
    this.buscaDetalhesFornecedor();
    this.verifyScreenSize();
    this.obterRedesSociais();
  }

  private verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["servicos"]) {
      this.servicos = changes["servicos"].currentValue;
      this.setServicosOfertados();
      this.setCidades();
    }
  }

  private obtemFotos() {
    this.service
      .buscaFotosPorFornecedorId(this.fornecedorId)
      .subscribe((response) => {
        this.fotos = response;
        if (this.fotos) {
          this.imagens = this.fotos.map((imagem) => imagem.url);

          this.fotosHighlight =
            this.fotos.length > 5 ? this.fotos.slice(0, 4) : this.fotos;

          this.galleryOptions = [
            {
              width: "100%",
              height: "500px",
              thumbnailsColumns: 4,
              imageAnimation: NgxGalleryAnimation.Fade,
              preview: true,
              imageSize: "contain",
              thumbnailSize: "cover",
              previewZoom: true,
              imageSwipe: true,
              imageInfinityMove: true,
              previewSwipe: true,
              previewFullscreen: true,
              previewAnimation: true,
              thumbnailsArrows: true,
              previewArrows: true,
              previewRotate: true,
              previewInfinityMove: true,
            },
            {
              breakpoint: 1515,
              width: "100%",
              height: "500px",
              imagePercent: 80,
              thumbnailsPercent: 20,
              thumbnailsMargin: 20,
              thumbnailMargin: 20,
              preview: false,
            },
            // max-width 800
            {
              breakpoint: 800,
              width: "100%",
              height: "600px",
              imagePercent: 80,
              thumbnailsPercent: 20,
              thumbnailsMargin: 20,
              thumbnailMargin: 20,
              preview: false,
            },
            // max-width 400
            {
              breakpoint: 400,
              preview: false,
            },
          ];

          this.galleryImages = this.fotos.map((ft) => {
            return { small: ft.url, medium: ft.url, big: ft.url };
          });
        }
      });
  }

  changeShowGallery(showGallery: boolean) {
    this.showGallery = showGallery;
  }

  private setServicosOfertados() {
    let servicosOfertados: CategoriaServicoEnum[] = [];
    if (this.servicos)
      this.servicos.forEach((service) => {
        service.servicos.forEach((ser) => {
          servicosOfertados.push(ser.categoria);
        });
      });

    this.servicosOferdadosLista = servicosOfertados.filter((element, index) => {
      return servicosOfertados.indexOf(element) === index;
    });
  }

  private setCidades() {
    let cidades: string[] = [];
    this.servicos.forEach((service) => {
      service.regiao.cidades.forEach((cidade) => {
        cidades.push(cidade.nome);
      });
    });

    this.cidadesDisponiveis = cidades.filter((element, index) => {
      return cidades.indexOf(element) === index;
    });
  }

  private buscarCidadesAtuacao() {
    this.fornecedorService
      .obterCidadesAtuacao(this.fornecedorId)
      .subscribe((resp) => {
        this.cidadesFornecedor = resp;
      });
  }

  private buscaDetalhesFornecedor() {
    this.service
      .buscaDetalhesPorFornecedorId(this.fornecedorId)
      .subscribe((response) => {
        this.fornecedorDetalhes = response;
        this.isPremium = this.fornecedorDetalhes.tipoPlano !== TipoPlanoEnum.Gratis;
      });
  }
  
  openCompartilharModal() {
    this.dialog.open(CompartilharModalModalComponent, {
      width: "250px",
      data: {
        fornecedor: this.fornecedorDetalhes!.nomeFantasia || "",
        link: window.location.href,
        user: this.user,
      },
    });
  }

  validarPermissaoAvaliar(): boolean {
    if (this.user && this.fornecedorDetalhes.avaliacoes) {
      const avaliacao = this.fornecedorDetalhes.avaliacoes.find(
        (x) => x.email === this.user.email
      );

      let jaAvaliado = !!avaliacao;
      return !jaAvaliado;
    } else {
      return true;
    }
  }

  private obterRedesSociais() {
    this.perfilService.obterRedesSociais(this.fornecedorId).then((resp) => {
      console.log("resp", resp);
      this.redesSociais.Instagram =
        resp.redeSociais.find(
          (rede) => rede.tipoRedeSocial === RedesSociaisEnum.Instagram
        ).link || "";

      this.redesSociais.WhatsApp =
        "https://wa.me/+55" +
          resp.redeSociais.find(
            (rede) => rede.tipoRedeSocial === RedesSociaisEnum.WhatsApp
          ).link || "";
    });
  }  
  openInstagram() {
    console.log(this.redesSociais);
    window.open(this.redesSociais.Instagram, "blank");
  }

  openWhatsApp() {
    window.open(this.redesSociais.WhatsApp, "blank");
  }  
  
  openAvaliacaoModal() {
    if (!this.user) {
      this.dialog.open(LoginModalComponent, {
        width: "200px",
        data: {
          redirect: window.location.pathname,
        },
      });
    } else {
      this.dialog
        .open(AvaliacaoModalComponent, {
          data: {
            fornecedor: this.fornecedorDetalhes,
            usuarioLogado: this.user,
            fornecedorId: this.fornecedorId,
          },
        })
        .beforeClosed()
        .subscribe((response) => {
          this.buscaDetalhesFornecedor();
          this.validarPermissaoAvaliar();
        });
    }
  }  
}
