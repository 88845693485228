import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PerguntasFrequentesModel } from "../../models/perguntas-frequentes.model";
import { PerfilService } from "src/app/Services/perfil.service";
import { UserService } from "src/app/Services/user.service";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { finalize } from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: "app-perguntas-frequentes",
  templateUrl: "./perguntas-frequentes.component.html",
  styleUrls: ["./perguntas-frequentes.component.scss"],
})
export class PerguntasFrequentesComponent implements OnInit {
  perguntasFrequentesForm!: FormGroup;
  user: SignInModel;
  cadastrarNovaPergunta = false;
  perguntasFrequentes: Array<PerguntasFrequentesModel> = [];
  editarPerguntaFrequenteForm!: FormGroup;
  editQuestionFlag = false;

  constructor(
    private formBuilder: FormBuilder,
    private perfilService: PerfilService,
    private userService: UserService
  ) {
    this.perguntasFrequentesForm = formBuilder.group({
      pergunta: ["", Validators.required],
      resposta: ["", Validators.required],
    });
    this.editarPerguntaFrequenteForm = formBuilder.group({
      pergunta: ["", Validators.required],
      resposta: ["", Validators.required],
      id: [""],
    });
    this.user = userService.user;
  }

  ngOnInit() {
    this.obterPerguntasFrequentes();
  }

  salvarPerguntaFrequente() {
    const form = this.perguntasFrequentesForm.value;

    const pergunta = {
      pergunta: form.pergunta,
      resposta: form.resposta,
    } as PerguntasFrequentesModel;

    this.perfilService
      .adicionarPerguntaFrequente(this.user.id, pergunta)
      .pipe(
        finalize(() => {
          this.perguntasFrequentesForm.reset(" ");
          this.cadastrarNovaPergunta = false;
        })
      )
      .subscribe(
        () => {
          Swal.fire("Sucesso", "Pergunta adicionada com sucesso!", "success");
          this.obterPerguntasFrequentes();
        },
        (error) => {
          this.perfilService.manipuladorDeErro(error);
        }
      );
  }

  editarPergunta(data: PerguntasFrequentesModel) {
    this.editarPerguntaFrequenteForm.patchValue({
      pergunta: data.pergunta,
      resposta: data.resposta,
      id: data.id,
    });

    this.editQuestionFlag = true;
  }

  editarPerguntaPorId() {
    const form = this.editarPerguntaFrequenteForm.value;
    const pergunta = {
      pergunta: form.pergunta,
      resposta: form.resposta,
    } as PerguntasFrequentesModel;
 
    this.perfilService
      .atualizarPerguntasFrequentes(this.user.id, form.id, pergunta)
      .subscribe((resp) => {
        Swal.fire("Sucesso", "Pergunta atualizada com sucesso!", "success");
        this.editQuestionFlag = false;
        this.obterPerguntasFrequentes();
      });
  }

  removerPergunta(perguntaId: string) {
    Swal.fire({
      icon: "warning",
      title: "Remoção de pergunta frequente",
      text: "Deseja realmente remover esta pergunta frequente?",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Não, manter pergunta",
      confirmButtonText: "Sim, remover",
    }).then((action) => {
      if (action.isConfirmed) {
        this.perfilService
          .removerPerguntaFrequente(this.user.id, perguntaId)
          .subscribe(() => {
            Swal.fire("Sucesso", "Pergunta removida com sucesso!", "success");
            this.obterPerguntasFrequentes();
          });
      }
    });
  }

  private obterPerguntasFrequentes() {
    this.perfilService
      .obterPerguntasFrequentes(this.user.id)
      .then((resp: PerguntasFrequentesModel[]) => {
        if (resp) this.perguntasFrequentes = resp;
      });
  }
}
