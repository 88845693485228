import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { PropostaService } from 'src/app/Services/proposta.service';

@Component({
   selector: 'embryo-SelectedPropostaView',
   templateUrl: './SelectedPropostaView.component.html',
   styleUrls: ['./SelectedPropostaView.component.scss']
})
export class SelectedPropostaViewComponent implements OnInit {

   toastOptions: ToastOptions = {
      title: '',
      msg: '',
      showClose: true,
      timeout: 5000,
      theme: 'material'
   };
   @Input() proposta;
   @Input() index;
   @Input() possuiFavorito;
   @Output() desmarcarOuMarcarFavorito = new EventEmitter();
   @Output() desmarcarSelecionada = new EventEmitter();
   constructor(private propostaService: PropostaService,
      private toastaService: ToastaService) { }

   ngOnInit() {
   }


   desmarcarOuMarcarDoFavorito() {
      const favorita = !this.proposta.favorita;;

      if (favorita && this.possuiFavorito) {
         this.toastOptions.msg = 'Somente uma proposta pode ser marcada como favorita.';
         this.toastaService.warning(this.toastOptions);
         return;
      }

      this.proposta.favorita = favorita;

      this.desmarcarOuMarcarFavorito.emit();
   }

   desmarcarDaSelecao() {
      this.proposta.selecionada = false;
      this.proposta.favorita = false;
      this.desmarcarSelecionada.emit();
   }

   downloadAnexo($event, url, nomeArquivo) {

      $event.preventDefault();
      $event.stopPropagation();

      this.propostaService.downloadAnexo(url, nomeArquivo);
   }
}
