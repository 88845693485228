import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { NotificacaoService } from 'src/app/Services/notificacao.service';
import { TipoUsuarioEnum } from 'src/app/Shared/Models/Base/Enums/role.enum';
import { AnuncioNotificacaoModel } from 'src/app/Shared/Models/Notificacoes/anuncio-notificacao.model';
import { AvaliacaoNotificacaoModel } from 'src/app/Shared/Models/Notificacoes/avaliacao-notificacao.model';
import { TipoNotificacaoEnum } from 'src/app/Shared/Models/Notificacoes/Enums/tipo-notificacao.enum';
import { NotificacaoModel } from 'src/app/Shared/Models/Notificacoes/notificacao.model';
import { OrcamentoNotificacaoModel } from 'src/app/Shared/Models/Notificacoes/orcamento-notificacao.model';

@Component({
   selector: 'embryo-notifications',
   templateUrl: './NotificationsCard.component.html',
   styleUrls: ['./NotificationsCard.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsCardComponent implements OnInit, OnChanges {

   @Input() count: any;
   @Input() notificacoes = new Array<NotificacaoModel>();

   mobWidth: any;
   mobScreenSize: number = 767;


   hiddenBadge = true;
   constructor(
      private router: Router,
      private authenticationHelper: AuthenticationHelper,
      private notificacaoService: NotificacaoService) {
      this.mobWidth = window.screen.width;
   }

   ngOnChanges() {
      if(this.count && this.count != 0) {
         this.hiddenBadge = false;
      } else {
         this.hiddenBadge = true;
      }
   }

   ngOnInit() {
   }

   obterClienteId() {
      return this.authenticationHelper.obterClienteId();
   }

   redirecionar(notificacao: NotificacaoModel){
      this.notificacaoService.redirecionar(notificacao);
   }
}