import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { pluck } from "rxjs/operators";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { UserService } from "src/app/Services/user.service";
import { AnuncioVmModel } from "src/app/Shared/Models/Anuncios/ViewModels/anuncio-vm.model";
import { ServicoEmAndamentoModel } from "src/app/Shared/Models/Servicos/servico-em-andamento.model";
import { CategoriaServicoEnum } from "src/app/Shared/Models/Servicos/Enums/categoria-servico.enum";

@Component({
  selector: "app-oportunidades",
  templateUrl: "./oportunidades.component.html",
  styleUrls: ["./oportunidades.component.scss"],
})
export class OportunidadesComponent implements OnInit {
  // inProgress = {
  //   categoria: 2,
  //   dataTerminoBid: "12/10/2023",
  //   id: "123",
  //   nomeServico: "teste",
  //   precoAbaixoDe: 40,
  //   primeiraImagem: "asd",
  //   primeiraImagemDescricao: "erwer",
  //   status: 2,
  // } as ServicoEmAndamentoModel;
  @Input()
  service: ServicoEmAndamentoModel;

  @Input()
  isVertical = false;
  
  @Output()
  clickEvent = new EventEmitter();

  user: SignInModel;

  imagens: Array<string> = [];
  categoriaServicoEnum = CategoriaServicoEnum;


  constructor(
    private anuncioService: AnuncioService,
    private userService: UserService,
    private router: Router
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.preparaImagensParaCarrousel();
  }

  private preparaImagensParaCarrousel() {
    if (this.service.imagens) {
      this.imagens = this.service.imagens.map((imagem) => imagem.url);
    } else {
      const imagensPossiveisKeys = [
        "primeiraImagem",
        "segundaImagem",
        "terceiraImagem",
        "quartaImagem"
      ];

      imagensPossiveisKeys.forEach((key) => {
        if (this.service[key]) {
          this.imagens.push(this.service[key]);
        }
      });
    }
  }
}
