import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-premium-banner',
  templateUrl: './premium-banner.component.html',
  styleUrls: ['./premium-banner.component.css']
})
export class PremiumBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
