import { Pipe, PipeTransform } from "@angular/core";
import { ServicoModel } from "src/app/Shared/Models/Servicos/servico.model";

@Pipe({ name: "buscarMenorValor" })
export class BucarMenorValorServicoPipe implements PipeTransform {
  transform(servicos: Array<ServicoModel>) {
    var minorValue = 0;

    servicos.forEach((ser, index) => {
      if (index === 0) minorValue = ser.valor;

      if (ser.valor < minorValue) minorValue = ser.valor;
    });

    return minorValue;
  }
}
