import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { ServicoOfertadoVmModel } from "../Shared/Models/ServicosOfertados/ViewModels/servico-ofertado-vm.model";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { BaseService } from "./base.service";
import { ToastaService } from "ngx-toasta";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class ServicoOfertadoService extends BaseService {
  private readonly url = environment.endpoints.backend + "/fornecedor";

  constructor(
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    toastaService: ToastaService,
    private router: Router
  ) {
    super(toastaService, authenticationHelper);
  }

  obterServicosOfertados(
    id: string
  ): Observable<RespostaModel<ServicoOfertadoVmModel[]>> {
    return this.http.get<RespostaModel<ServicoOfertadoVmModel[]>>(
      `${this.url}/${id}/servicoofertado`
    );
  }

  atualizarServicosOfertados(
    id: string,
    servicosOfertadosVmModel: ServicoOfertadoVmModel[]
  ) {
    return this.http
      .put<RespostaModel<ServicoOfertadoVmModel>>(
        `${this.url}/${id}/servicoofertado`,
        servicosOfertadosVmModel
      )
      .subscribe(
        () => {
          this.toastOptions.title = "Sucesso!";
          this.toastOptions.msg = "Serviço atualizados.";
          this.toastaService.success(this.toastOptions);
          this.router.navigate(["/servicos/meus-servicos"]);
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }

  cadastro(id: string, castroServicoOfertado: ServicoOfertadoVmModel) {
    return this.http
      .post<RespostaModel<ServicoOfertadoVmModel>>(
        `${this.url}/${id}/servicoofertado`,
        castroServicoOfertado
      )
      .subscribe(
        () => {
          this.toastOptions.title = "Sucesso!";
          this.toastOptions.msg = "Serviço cadastrado.";
          this.toastaService.success(this.toastOptions);
          this.router.navigate(["/servicos/meus-servicos"]);
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }
}
