import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import GLightbox from 'glightbox';
import { Lightbox } from 'ngx-lightbox';
import { FornecedorServicoExtraVmModel } from '../../Shared/Models/Fornecedores/ViewModels/fornecedor-servico-extra-vm.model';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationPopupComponent } from '../ConfirmationPopup/ConfirmationPopup.component';
import { FornecedorServicoExtraService } from '../../Services/fornecedor.servico.extra.service';
import { AuthenticationHelper } from '../../Services/helpers/authentication-helper.service';
import { ServicoExtraAtualizadoEvent } from '../../Shared/Events/servico-extra-atualizado-event';

@Component({
  selector: 'app-fornecedor-servico-extra',
  templateUrl: './fornecedor-servico-extra.component.html',
  styleUrls: ['./fornecedor-servico-extra.component.css']
})
export class FornecedorServicoExtraComponent implements OnInit {

  @Input()
  servicoExtra: FornecedorServicoExtraVmModel;

  @Input()
  exibirAcoes = true;

  leiaMais = false;

  private gallery = GLightbox({
      touchNavigation: true,
      loop: true,
      startAt : 0,
      autoplayVideos: false
    });

  constructor(
      private lightbox: Lightbox,
      private dialog: MatDialog,
      protected authenticationHelper: AuthenticationHelper,
      private servicoExtraService: FornecedorServicoExtraService,
      private servicoExtraAtualizadoEvent: ServicoExtraAtualizadoEvent
  ) { }

  ngOnInit() {
  }

  exibirImagens(): void {
    const thumbs: any = [];
    const imagens = this.servicoExtra
        .imagens;

    if (imagens && imagens.length > 0) {
      imagens
          .forEach(foto => {
            thumbs.push({
                'href': foto.url,
                'type': 'image'
              });
          });
    }

    this.gallery.setElements(thumbs);
    this.gallery.open();
  }

  popupConfirmacaoExclusao(message: string) {
    let confirmationPopup: MatDialogRef<ConfirmationPopupComponent>;
    confirmationPopup = this.dialog.open(ConfirmationPopupComponent);
    confirmationPopup.componentInstance.message = message;

    return confirmationPopup.afterClosed();
  }

  excluirServico() {
      this.popupConfirmacaoExclusao('Confirma a exclusão do Produto/Serviço extra?')
      .subscribe(resposta => {
        if (resposta === 1) {
            const fornecedorId = this.authenticationHelper
                .obterClienteId();
            this.servicoExtraService
                .excluir(fornecedorId, this.servicoExtra.id)
                .then(response =>
                    this.servicoExtraAtualizadoEvent
                        .onServicoExtraAtualizado
                        .emit(true));
        }
      });
  }
}
