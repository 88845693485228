import { Injectable, EventEmitter, Output } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';
import { TokenModel } from 'src/app/Shared/Models/Base/token.model';
import { TipoUsuarioEnum } from 'src/app/Shared/Models/Base/Enums/role.enum';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TipoPlanoEnum } from 'src/app/Shared/Models/Planos/Enums/tipo-plano.enum';
import { MatDialogRef, MatDialog } from '@angular/material';
import { BidAccessControlDialogComponent } from 'src/app/AdminPanel/Widget/PopUp/BidAccessControlDialog/BidAccessControlDialog.component';
import { BidAccessControlDialogModel } from 'src/app/Shared/Models/Base/bid-access-control-dialog.model';

export const TOKEN_NAME = '7d25d0bc';

const REMEMBER_ME_NAME = '_R$m342me';

@Injectable()
export class AuthenticationHelper {


  constructor(
    private router: Router,
    private dialog: MatDialog) {

  }

  clienteEstaAutenticado = new Subject<boolean>();

  public changeProfileImageEvent: EventEmitter<string> = new EventEmitter();

  obterToken(): string {

    if (this.obterLembrarMe() === 'true') {
      return localStorage.getItem(TOKEN_NAME);
    } else {
      return sessionStorage.getItem(TOKEN_NAME);
    }
  }

  obterTipoUsuario(): TipoUsuarioEnum {
    const token = this.obterTokenModel();

    if ((token) && (this.estaLogado())) {
      return token.role;
    }

    return TipoUsuarioEnum.Nenhum;
  }

  obterTipoPlano(): TipoPlanoEnum {
    const token = this.obterTokenModel();

    if ((token) && (this.estaLogado())) {
      return token.plano;
    }

    return TipoPlanoEnum.Gratis;
  }

  removerToken(): void {
    if (this.obterLembrarMe() === 'true') {
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }
    this.clienteEstaAutenticado.next(false);
    this.router.navigate(['/session/signin']);
  }

  marcarParaLembrar(rememberMe: string) {
    localStorage.setItem(REMEMBER_ME_NAME, rememberMe);
  }

  obterLembrarMe(): string {
    return localStorage.getItem(REMEMBER_ME_NAME);
  }

  salvarToken(token: string): void {

    if (this.obterLembrarMe() === 'true') {
      localStorage.setItem(TOKEN_NAME, token);
    } else {
      sessionStorage.setItem(TOKEN_NAME, token);
    }
    this.clienteEstaAutenticado.next(true);
  }

  salvarImagemPerfil(url: string) {
    const token = this.obterTokenModel();

    if ((token) && (this.estaLogado())) {
      token.imagemPerfil = url;
      this.changeProfileImageEvent.emit(url);
    }
  }

  salvarImagePerfilCapa(url: string) {
    const token = this.obterTokenModel();

    if ((token) && (this.estaLogado())) {
      token.imagemPerfilCapa = url;
    }
  }

  estaLogado(ignorarRemocaoToken = false): boolean {

    const token = this.obterToken();
    if (token) {
      const expirado = this.estaExpirado(token);

      if (expirado) {

        if (!ignorarRemocaoToken) {
          this.removerToken();
        }
        return false;
      }

      return true;
    }

    return false;

  }

  obterTokenModel(): TokenModel {
    const token = this.obterToken();

    if (token) {
      const isTokenExpired = this.estaExpirado(token);

      if (!isTokenExpired) {

        const jwtHelper: JwtHelper = new JwtHelper();
        const decoded = jwtHelper.decodeToken(token);

        return new TokenModel(decoded);

      } else {
        this.removerToken();
      }
    }

    return null;
  }

  obterImagemPerfil() {
    const token = this.obterTokenModel();

    if ((token) && (token.imagemPerfil) && (this.estaLogado())) {
      return token.imagemPerfil;
    }

    return 'assets/images/user.jpg';
  }

  obterImagemPerfilCapa() {
    const token = this.obterTokenModel();

    if ((token) && (token.imagemPerfilCapa) && (this.estaLogado())) {
      return token.imagemPerfilCapa;
    }

    return null;
  }

  obterClienteId() {
    const token = this.obterTokenModel();

    if ((token) && (this.estaLogado())) {
      return token.cliente;
    }

    return null;
  }

  exibirDialogAcesso(mensagem?: string){
		let dialogRef : MatDialogRef<BidAccessControlDialogComponent>;
		dialogRef = this.dialog.open(BidAccessControlDialogComponent, {
      data: new BidAccessControlDialogModel({
        mensagem: mensagem
      })
    });
		return dialogRef.afterClosed();
	}

  private estaExpirado(token: string): boolean {
    if (token) {
      const jwtHelper: JwtHelper = new JwtHelper();
      return jwtHelper.isTokenExpired(token);
    }

    return true;
  }
}
