export class AvaliacaoMediaAtualizacaoModel {

    public fornecedorId: string;
    public media: number;
    public totalAvaliacao: number;

    constructor(init?: Partial<AvaliacaoMediaAtualizacaoModel>) {
        Object.assign(this, init);
    }

}