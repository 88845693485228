import { BidiModule } from "@angular/cdk/bidi";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorIntl,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { DeviceDetectorModule } from "ngx-device-detector";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ToastaModule } from "ngx-toasta";

import { MatCarouselModule } from "@ngmodule/material-carousel";
import { MenuItems } from "./Core/menu/menu-items/menu-items";
import { GlobalModule } from "./Global/Global.module";
import { FooterOneComponent } from "./Layouts/Footer/FooterOne/FooterOne.component";
import { FixedHeaderComponent } from "./Layouts/Header/FixedHeader/FixedHeader.component";
import { HeaderOneComponent } from "./Layouts/Header/HeaderOne/HeaderOne.component";
import { MenuComponent } from "./Layouts/Menu/Menu/Menu.component";
import { SideBarMenuComponent } from "./Layouts/Menu/SidebarMenu/SidebarMenu.component";
import { PaymentDetailSideBarComponent } from "./Layouts/PaymentDetailSideBar/PaymentDetailSideBar.component";
import { MainComponent } from "./Main/Main.component";
import { CartComponent } from "./Pages/Cart/Cart.component";
import { HomeoneComponent } from "./Pages/Home/HomeOne/HomeOne.component";
import { NotFoundComponent } from "./Pages/NotFound/NotFound.component";
import { EmbryoService } from "./Services/Embryo.service";
import { TemplatesModule } from "./Templates/Templates.module";
import { AppRoutes } from "./app-routing";
import { AppComponent } from "./app.component";

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { AdminPanelModule } from "./AdminPanel/admin-panel.module"; 

import { CommonModule, registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID } from "@angular/core";
registerLocaleData(localePt, "pt");

import { BarRatingModule } from "ngx-bar-rating";
import { ImageCropperModule } from "ngx-image-cropper";
import { ClienteGuard } from "./Core/guards/cliente.guard";
import { LoggedGuard } from "./Core/guards/logged-user.guard";
import { FornecedorServicoExtraComponent } from "./Global/fornecedor-servico-extra/fornecedor-servico-extra.component";
import { HomeModule } from "./Pages/Home/home.module";
import { CriarBidComponent } from "./Pages/Home/pages/criar-bid/criar-bid.component";
import { FornecedorAlavancarComponent } from "./Pages/Home/pages/fornecedor-alavancar/fornecedor-alavancar.component";
import { FornecedorMelhorAvaliadoComponent } from "./Pages/Home/pages/fornecedor-melhor-avaliado/fornecedor-melhor-avaliado.component";
import { FornecedorPropostasComponent } from "./Pages/Home/pages/fornecedor-propostas/fornecedor-propostas.component";
import { IdeiaCardComponent } from "./Pages/Home/pages/ideia-card/ideia-card.component";
import { QueBidComponent } from "./Pages/Home/pages/que-bid/que-bid.component";
import { AnuncioService } from "./Services/anuncio.service";
import { AuthGuard } from "./Services/auth.guard";
import { CategoriaService } from "./Services/categoria.service";
import { ClienteBaseService } from "./Services/cliente.servico";
import { DynamicScriptLoaderService } from "./Services/dynamic-script-loader.service";
import { EventEmitterService } from "./Services/event-emitter.service";
import { GetNetService } from "./Services/getnet.service";
import { AuthInterceptor } from "./Services/helpers/auth.interceptor";
import { AuthenticationHelper } from "./Services/helpers/authentication-helper.service";
import { BidAccessControlGuardService } from "./Services/helpers/bid-access-control-guard.service";
import { CanDeactivateGuard } from "./Services/helpers/can-deactivate-guard.service";
import { LocalidadeService } from "./Services/localidade.service";
import { NotificacaoService } from "./Services/notificacao.service";
import { OrcamentoService } from "./Services/orcamento.service";
import { OrdensPagamentoService } from "./Services/ordenspagamento.service";
import { PainelGestaoService } from "./Services/painel-gestao.service";
import { PerfilService } from "./Services/perfil.service";
import { PlanoService } from "./Services/plano.service";
import { PropostaService } from "./Services/proposta.service";
import { ServicoOfertadoService } from "./Services/servico-ofertado.service";
import { ServicoService } from "./Services/servico.service";
import { SessaoService } from "./Services/sessao.service";
import { CustomPaginatorConfig } from "./Shared/Customs/custom-paginator-config";
import { SharedModule } from "./Shared/shared.module";
import { FornecedorGuard } from "./Core/guards/fornecedor.guard";
import { NgxGalleryModule } from "ngx-gallery";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeoneComponent,
    HeaderOneComponent,
    FooterOneComponent,
    MenuComponent,
    SideBarMenuComponent,
    CartComponent,
    NotFoundComponent,
    PaymentDetailSideBarComponent,
    FixedHeaderComponent,
    FornecedorMelhorAvaliadoComponent,
    IdeiaCardComponent,
    QueBidComponent,
    CriarBidComponent,
    FornecedorAlavancarComponent,
    FornecedorPropostasComponent,
    FornecedorServicoExtraComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: "embryo-seo-pre" }),
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
    }),
    GlobalModule,
    TemplatesModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    ToastaModule.forRoot(),
    BidiModule,
    SlickCarouselModule,
    PerfectScrollbarModule,
    DeviceDetectorModule.forRoot(),
    AdminPanelModule,
    MatCarouselModule.forRoot(),
    ImageCropperModule,
    BarRatingModule,
    MatAutocompleteModule,
    SharedModule,
    HomeModule,
    NgxGalleryModule
  ],
  providers: [
    MenuItems,
    AuthenticationHelper,
    CanDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard,
    SessaoService,
    EmbryoService,
    AuthenticationHelper,
    ClienteBaseService,
    AnuncioService,
    LocalidadeService,
    ServicoService,
    PerfilService,
    CategoriaService,
    ServicoOfertadoService,
    OrdensPagamentoService,
    GetNetService,
    DynamicScriptLoaderService,
    BidAccessControlGuardService,
    PropostaService,
    NotificacaoService,
    PainelGestaoService,
    OrcamentoService,
    PlanoService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LOCALE_ID,
      useValue: "pt",
    },
    EventEmitterService,
    {
      provide: MatPaginatorIntl,
      useValue: CustomPaginatorConfig(),
    },
    LoggedGuard,
    ClienteGuard,
    FornecedorGuard,
  ],
  exports: [RouterModule, ToastaModule, SlickCarouselModule],

  bootstrap: [AppComponent],
})
export class AppModule {}
