import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  HostListener
} from "@angular/core";
import { FornecedorMelhorAvaliadoVmModel } from "../../Models/Fornecedores/ViewModels/fornecedor-melhor-avaliado-vm.model";
import { Router } from "@angular/router";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { AnuncioModel } from "../../Models/Anuncios/anuncio.model";
import { EnviarOrcamentoVmModel } from "../../Models/Anuncios/ViewModels/enviar-orcamento-vm.model";
import { OrcamentoService } from "src/app/Services/orcamento.service";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { UserService } from "src/app/Services/user.service";
import { CategoriaServicoEnum } from "../../Models/Servicos/Enums/categoria-servico.enum";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material";
import { OrcamentosModalComponent } from "../orcamentos-modal/orcamentos-modal.component";

@Component({
  selector: "app-services-and-companies",
  templateUrl: "./services-and-companies.component.html",
  styleUrls: ["./services-and-companies.component.scss"],
})
export class ServicesAndCompaniesComponent implements OnInit, OnChanges {
  @Input()
  fornecedor: FornecedorMelhorAvaliadoVmModel;
  @Input()
  categoria: CategoriaServicoEnum;

  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }  

  orcamentos: Array<AnuncioModel> = [];
  orcamentoSelecionado: string | null = null;
  user: SignInModel;
  mobileView: boolean;

  constructor(
    private router: Router,
    private anuncioService: AnuncioService,
    private orcamentoService: OrcamentoService,
    private userService: UserService,
    public dialog: MatDialog
  ) {
    this.user = userService.user;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoria)
      this.categoria = Number(changes.categoria.currentValue);
  }

  ngOnInit() {
    this.verifyScreenSize();
  }

  private verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }  

  openDetails() {
    this.router.navigate([
      `/detalhes-fornecedor/${this.fornecedor.idFornecedor}/${this.categoria}`,
    ]);
  }

  abrirOrcamento(event) {
    event.stopPropagation();

    this.dialog.open(OrcamentosModalComponent, {
      width: "250px",
      data: {
        fornecedor: {
          id: this.fornecedor.idFornecedor,
          categoria: this.categoria,
        },
        user: this.user,
      },
    });
  }

  enviarOrcamento() {
    const data = {
      anuncioId: this.orcamentoSelecionado,
      clienteEmail: this.user.email,
      clienteId: this.user.id,
      fornecedorId: this.fornecedor.idFornecedor,
      tipoUsuario: this.user.tipoUsuario,
    } as EnviarOrcamentoVmModel;

    this.orcamentoService.enviarOrcamentoObservable(data).subscribe(
      (r) => {
        Swal.fire("Sucesso", "Orçamento enviado com sucesso!", "success");
      },
      (error) => this.orcamentoService.manipuladorDeErro(error)
    );
  }
}
