import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { RequestMethod } from "@angular/http";
import { Router } from "@angular/router";
import { ToastaService } from "ngx-toasta";
import { Observable } from "rxjs";
import { pluck, shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ProdutosServicosExtras } from "../Pages/perfil-fornecedor/components/meu-perfil/components/produtos-servicos/models/produtos-servicos.model";
import { DadosSercundariosModel } from "../Pages/profile/components/profile/dados-perfi/models/dados-secundarios.model";
import { TipoUsuarioEnum } from "../Shared/Models/Base/Enums/role.enum";
import { EnderecoModel } from "../Shared/Models/Base/endereco.model";
import { TokenModel } from "../Shared/Models/Base/token.model";
import { FornecedorAvaliacaoNodeModel } from "../Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model";
import { PerfilAtualizarImagemVmModel } from "../Shared/Models/Fornecedores/ViewModels/perfil-atualizar-imagem-vm.model";
import { PerfilCadastroVmModel } from "../Shared/Models/Fornecedores/ViewModels/perfil-cadastro-vm.model";
import { BaseService } from "./base.service";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { SessaoService } from "./sessao.service";
import {
  BancosModel,
  DadosBancariosModel,
} from "../Pages/perfil-fornecedor/components/meu-perfil/components/dados-bancarios/models/dados-bancarios.model";
import { IdentidadeModel } from "../Shared/Models/Base/identidade.model";
import { FornecedorMelhorAvaliadoVmModel } from "../Shared/Models/Fornecedores/ViewModels/fornecedor-melhor-avaliado-vm.model";
import { ServicoModel } from "../Shared/Models/Servicos/servico.model";
import {
  CadastrarServicoAoServicoOfertadoModel,
  EditarServicoAoServicoOfertadoModel,
} from "../Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/cadastrar-servico.model";
import { RedesSociaisEnum } from "../Shared/Models/Base/Enums/rede-social.enum";
import { PerguntasFrequentesModel } from "../Pages/perfil-fornecedor/components/meu-perfil/models/perguntas-frequentes.model";
import { ImagemModel } from "../Shared/Models/Base/imagem.model";
export enum RedesSociais {
  Linkedin,
  Facebook,
  Instagram,
  GooglePlus,
  Twitter,
  YouTube,
  WhatsApp,
}
export interface RedesSociaisModel {
  redeSociais: { link: string; tipoRedeSocial: RedesSociaisEnum }[];
  website: string;
}

@Injectable({
  providedIn: "root",
})
export class PerfilService extends BaseService {
  private readonly url = environment.endpoints.backend + "/perfil";

  @Output() uploadFotoCompletoEvent = new EventEmitter<string>();
  @Output() atulizacaoDadosPerfilEvent = new EventEmitter<string>();

  constructor(
    toastaService: ToastaService,
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private router: Router,
    private sessaoService: SessaoService
  ) {
    super(toastaService, authenticationHelper);
  }

  criarPerfil(fornecedorId: string, perfil: PerfilCadastroVmModel) {
    this.http
      .post<any>(`${this.url}/${fornecedorId}/fornecedor/CriarPerfil`, perfil)
      .subscribe(
        (response) => {
          this.toastOptions.title = "Sucesso!";
          this.toastOptions.msg = "Perfil cadastrado.";
          this.toastaService.success(this.toastOptions);
          this.router.navigate(["/session/signin"]);
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }

  obterUsuarioPorId(usuarioId: string) {
    return new Promise((resolve, reject) => {
      const token: TokenModel = this.authenticationHelper.obterTokenModel();
      if (!token) {
        reject();
      } else {
        const dados = {
          id: usuarioId,
          nome: token.name,
          email: token.email,
          tipoUsuario: Number(token.role),
        };
        resolve(dados);
      }
    });
  }

  atualizarImagemPerfil(
    usuario: any,
    dadosImagem: PerfilAtualizarImagemVmModel
  ) {
    let urlTipoUsuario = "fornecedor";
    if (TipoUsuarioEnum.Cliente === usuario.tipoUsuario)
      urlTipoUsuario = "cliente";

    return new Promise((resolve, reject) => {
      this.http
        .put<any>(
          `${this.url}/${usuario.id}/${urlTipoUsuario}/AtualizarImagemPerfil`,
          dadosImagem
        )
        .subscribe(
          (response: any) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Imagem do perfil atualizado.";
            this.toastaService.success(this.toastOptions);
            resolve(true);
            this.authenticationHelper.changeProfileImageEvent.emit(
              response.dados
            );
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject(false);
          }
        );
    });
  }

  atualizarImagemPerfilCapa(
    usuario: any,
    dadosImagem: PerfilAtualizarImagemVmModel
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .put<any>(
          `${this.url}/${usuario.id}/fornecedor/AtualizarImagemPerfilCapa`,
          dadosImagem
        )
        .subscribe(
          (response: any) => {
            this.toastOptions.title = "Sucesso!";
            this.toastOptions.msg = "Imagem da capa atualizada.";
            this.toastaService.success(this.toastOptions);
            resolve(response);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject(null);
          }
        );
    });
  }
  obterTodosOsFornecedores(): Observable<FornecedorMelhorAvaliadoVmModel[]> {
    return this.http
      .get<FornecedorMelhorAvaliadoVmModel[]>(
        `${environment.endpoints.backend}/fornecedor/ObterTodos`
      )
      .pipe(pluck("dados"));
  }

  getObterPorCidadeServico(dataToSearch: {
    categoriaId?: number;
  }): Observable<Array<FornecedorMelhorAvaliadoVmModel>> {
    let params = new HttpParams();

    if (dataToSearch.categoriaId)
      params = params.set("categoriaId", dataToSearch.categoriaId.toString());

    return this.http
      .get<Array<FornecedorMelhorAvaliadoVmModel>>(`${environment.endpoints.backend}/fornecedor/ObterPorCidadeServico`, {
        params,
      })
      .pipe(pluck("dados"));
  }  

  obterDadosPerfil(id: string, tipoUsuario: TipoUsuarioEnum) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.url}/${id}/${TipoUsuarioEnum[tipoUsuario]}/ObterDadosPerfil`
        )
        .pipe(shareReplay())
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  obterRedesSociais(fornecedorId: string): Promise<RedesSociaisModel> {
    return new Promise((resolve, reject) => {
      this.http
        .get<RedesSociaisModel>(
          `${this.url}/${fornecedorId}/fornecedor/ObterRedesSociais`
        )
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  gravarDadosPerfil(
    id: string,
    dadosPerfil: any,
    tipoUsuario: TipoUsuarioEnum
  ) {
    this.http
      .put<any>(
        `${this.url}/${id}/${TipoUsuarioEnum[tipoUsuario]}/GravarDadosPerfil`,
        dadosPerfil
      )
      .subscribe(
        (response: any) => {
          this.sessaoService.gerarNovoToken();

          if (TipoUsuarioEnum.Fornecedor == tipoUsuario)
            this.atulizacaoDadosPerfilEvent.emit(dadosPerfil.nomeFantasia);

          this.toastOptions.title = "Sucesso!";
          this.toastOptions.msg = "Dados do perfil atualizado.";
          this.toastaService.success(this.toastOptions);
        },
        (error) => {
          this.manipuladorDeErro(error);
        }
      );
  }

  gravarRedesSociais(fornecedorId: string, redesSociais: any) {
    return this.http.put<any>(
      `${this.url}/${fornecedorId}/fornecedor/GravarRedesSociais`,
      redesSociais
    );
  }

  obterPerguntasFrequentes(fornecedorId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.url}/${fornecedorId}/fornecedor/ObterPerguntasFrequentes`)
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  adicionarPerguntaFrequente(fornecedorId: string, dados: any) {
    return this.http.put<any>(
      `${this.url}/${fornecedorId}/fornecedor/AtualizarPerguntasFrequentes`,
      dados
    );
  }

  atualizarPerguntasFrequentes(
    fornecedorId: string,
    perguntaId: string,
    dados: PerguntasFrequentesModel
  ) {
    return this.http.put<any>(
      `${this.url}/${fornecedorId}/fornecedor/AtualizarPerguntasFrequentesPorId/${perguntaId}`,
      dados
    );
  }

  removerPerguntaFrequente(fornecedorId: string, perguntaId: string) {
    return this.http.delete<any>(
      `${this.url}/${fornecedorId}/fornecedor/DeletarPerguntaFrequente/${perguntaId}`
    );
  }

  obterFotos(fornecedorId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.url}/${fornecedorId}/fornecedor/ObterFotos`)
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  excluirFoto(fornecedorId: string, url: string) {
    return new Promise((resolve, reject) => {
      const corpo = {
        urlFoto: url,
      };

      const options = {
        body: corpo,
        method: RequestMethod.Delete,
      };

      this.http
        .request(
          "DELETE",
          `${this.url}/${fornecedorId}/fornecedor/ExcluirFoto`,
          options
        )
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  obterAvaliacoes(fornecedorId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.url}/${fornecedorId}/fornecedor/ObterAvaliacoes`)
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  adicionarResposta(
    fornecedorId: string,
    avaliacoes: Array<FornecedorAvaliacaoNodeModel>
  ) {
    return this.http.post(
      `${this.url}/${fornecedorId}/fornecedor/AdicionarResposta`,
      avaliacoes
    );
  }

  uploadImagensFornecedor(fornecedorId: string, data: FormData) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.url}/${fornecedorId}/fornecedor/GravarFotos`, data)
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  removerFotoPorFornecedorId(fornecedorId: string, imagem: ImagemModel) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.url}/${fornecedorId}/fornecedor/RemoverFoto`, 
          imagem,
        )
        .subscribe(
          (response: any) => {
            resolve(response.dados);
          },
          (error) => {
            this.manipuladorDeErro(error);
            reject();
          }
        );
    });
  }

  putAtualizarDadosPerfil(
    fornecedorId: string,
    dadosperfil: {
      nomeFantasia: string;
      titulo: string;
      endereco: EnderecoModel;
      identidade: IdentidadeModel;
    }
  ) {
    return this.http.put(
      `${this.url}/${fornecedorId}/fornecedor/AtualizarDadosPerfil`,
      dadosperfil
    );
  }

  atualizarServicosExtras(
    fornecedorId: string,
    servicos: ProdutosServicosExtras
  ) {
    return this.http.post(
      `${this.url}/${fornecedorId}/fornecedor/GravarServicosExtra`,
      servicos
    );
  }

  buscaProgressoDoPerfilPorId(fornecedorId: string): Observable<number> {
    return this.http
      .get<number>(
        `${this.url}/${fornecedorId}/fornecedor/BuscarProgressoPerfil`
      )
      .pipe(pluck("dados"), shareReplay<number>());
  }

  gravarDadosSecundarios(
    clienteId: string,
    dadosSecundarios: DadosSercundariosModel
  ) {
    return this.http
      .put<number>(
        `${this.url}/${clienteId}/cliente/GravarDadosSecundariosPerfil`,
        dadosSecundarios
      )
      .pipe(pluck("dados"));
  }
  obterDadosSecundarios(clienteId: string): Observable<DadosSercundariosModel> {
    return this.http
      .get<number>(
        `${this.url}/${clienteId}/cliente/ObterDadosSecundariosPerfil`
      )
      .pipe(pluck("dados"));
  }

  obterListaDeBancos(fornecedorId: string): Observable<BancosModel> {
    return this.http
      .get(`${this.url}/${fornecedorId}/fornecedor/buscarBancosDisponiveis`)
      .pipe(pluck("dados"));
  }

  postAdicionarDadosBancarios(
    fornecedorId: string,
    dadosbancarios: DadosBancariosModel
  ) {
    return this.http
      .post(
        `${this.url}/${fornecedorId}/fornecedor/adicionarDadosBancarios`,
        dadosbancarios
      )
      .pipe(pluck("dados"));
  }
  obterDadosBancarios(fornecedorId: string): Observable<DadosBancariosModel> {
    return this.http
      .get(`${this.url}/${fornecedorId}/fornecedor/ObterDadosBancarios`)
      .pipe(pluck("dados"));
  }

  adicionarServicoAoServicoOfertado(
    fornecedorId: string,
    categoriaId: number,
    serviceId: number,
    servico: CadastrarServicoAoServicoOfertadoModel
  ) {
    return this.http.post(
      `${this.url}/${fornecedorId}/fornecedor/AdicionarServico/${categoriaId}/${serviceId}`,
      servico
    );
  }

  atualizarServicoAoServicoOfertado(
    fornecedorId: string,
    categoriaId: number,
    serviceId: number,
    servico: EditarServicoAoServicoOfertadoModel
  ) {
    return this.http.post(
      `${this.url}/${fornecedorId}/fornecedor/AtualizarServico/${categoriaId}/${serviceId}`,
      servico
    );
  }

  deletarServicoAoServicoOfertado(
    fornecedorId: string,
    serviceId: number,
    servico: ServicoModel,
    removeAll?: boolean
  ) {
    return this.http.post(
      `${
        this.url
      }/${fornecedorId}/fornecedor/DeletarServico/${serviceId}?deletarTudo=${
        removeAll ? removeAll : false
      }`,
      servico
    );
  }
}
