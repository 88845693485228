import { ImageCarouselComponent } from "./image-carousel/image-carousel.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import {
  MatButtonModule,
  MatBadgeModule,
  MatCardModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatExpansionModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatTabsModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatSliderModule,
  MatRadioModule,
  MatDialogModule,
  MatGridListModule,
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BarRatingModule } from "ngx-bar-rating";
import { AgmCoreModule } from "@agm/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SlickCarouselModule } from "ngx-slick-carousel";

import { BrandslogoComponent } from "./BrandsLogo/BrandsLogo.component";
import { SalesComponent } from "./Sales/Sales.component";
import { FeaturesComponent } from "./Features/Features.component";
import { SubscribeOneComponent } from "./Subscribe/SubscribeOne/SubscribeOne.component";
import { DealOfTheDayComponent } from "./DealOfTheDay/DealOfTheDay.component";
import { SocialShareComponent } from "./SocialShare/SocialShare.component";
import { RatingComponent } from "./Rating/Rating.component";
import { AddToCardButtonComponent } from "./AddToCardButton/AddToCardButton.component";
import { ReviewPopupComponent } from "./ReviewPopup/ReviewPopup.component";
import { HeaderCartComponent } from "./HeaderCart/HeaderCart.component";
import { WishListComponent } from "./WishList/WishList.component";
import { ConfirmationPopupComponent } from "./ConfirmationPopup/ConfirmationPopup.component";
import { PageTitleComponent } from "./PageTitle/PageTitle.component";
import { HomePageOneSliderComponent } from "./Slider/HomePageOneSlider/HomePageOneSlider.component";
import { TimerCountDownComponent } from "./TimerCountDown/TimerCountDown.component";
import { MapComponent } from "./Map/Map.component";
import { CurrencyDropDownComponent } from "./CurrencyDropDown/CurrencyDropDown.component";
import { TestimonialComponent } from "./Testimonial/Testimonial.component";
import { TeamComponent } from "./Team/Team.component";
import { ContactFormComponent } from "./ContactForm/ContactForm.component";
import { MissionVisionComponent } from "./MissionVision/MissionVision.component";
import { AboutInfoComponent } from "./AboutInfo/AboutInfo.component";
import { ImgZoomComponent } from "./ImgZoom/ImgZoom.component";
import { CommonSignInComponent } from "./CommonSignIn/CommonSignIn.component";
import { ProductCardComponent } from "./ProductCard/ProductCard.component";
import { HeaderUserProfileDropdownComponent } from "./HeaderUserProfileDropdown/HeaderUserProfileDropdown.component";
import { AppLogoComponent } from "./AppLogo/AppLogo.component";
import { LighteningDealsComponent } from "./LighteningDeals/LighteningDeals.component";
import { TopProductsComponent } from "./TopProducts/TopProducts.component";
import { SubscribeTwoComponent } from "./Subscribe/SubscribeTwo/SubscribeTwo.component";
import { HomePageTwoSliderComponent } from "./Slider/HomePageTwoSlider/HomePageTwoSlider.component";
import { CTAGroupComponent } from "./CallToAction/CTA-Group/CTA-Group.component";
import { CTATwoComponent } from "./CallToAction/CTA-Two/CTA-Two.component";
import { CollectionGalleryComponent } from "./CollectionGallery/CollectionGallery.component";
import { ProductCategoryCardComponent } from "./ProductCategoryCard/ProductCategoryCard.component";
import { CTASingleBannerComponent } from "./CallToAction/CTA-SingleBanner/CTA-SingleBanner.component";
import { DownloadAppSectionComponent } from "./DownloadAppSection/DownloadAppSection.component";
import { HomePageThreeSliderComponent } from "./Slider/HomePageThreeSlider/HomePageThreeSlider.component";
import { NewProductsCardComponent } from "./NewProductsCard/NewProductsCard.component";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { BidCardComponent } from "./BidCard/BidCard.component";
import { BidUploadComponent } from "./bid-upload/bid-upload.component";
import { BidDetailCardComponent } from "./BidDetailCard/BidDetailCard.component";
import { FornecedorAvaliacaoComponent } from "./fornecedor-avaliacao/fornecedor-avaliacao.component";
import { FornecedorService } from "../Services/fornecedor.service";
import { AvaliacaoAtualizadaEvent } from "../Shared/Events/avaliacao-atualizada-event";
import { AvaliacaoMediaAtualizadaEvent } from "../Shared/Events/avaliacao-media-atualizada-event";
import { BidAccessControlDirective } from "./Directives/bid-access-control.directive";
import { BidPainelGestaoDirective } from "./Directives/bid-painel-gestao.directive";
import { BidSocialShareDialogComponent } from "./Widget/PopUp/bid-social-share-dialog/bid-social-share-dialog.component";
import { BidSocialShareDirective } from "./Directives/bid-social-share.directive";
import { ShareModule } from "@ngx-share/core";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { BidBudgetsDialogComponent } from "./Widget/PopUp/bid-budgets-dialog/bid-budgets-dialog.component";
import { PremiumBannerComponent } from "./premium-banner/premium-banner.component";
import { NotificationsCardComponent } from "./NotificationsCard/NotificationsCard.component";
import { NotificacaoMarcarLidoDirective } from "./Directives/notificacao-marcar-lido.directive";
import { PremiumServicesComponent } from "./premium-services/premium-services.component";
import { BidMessageDialogComponent } from "./Widget/PopUp/bid-message-dialog/bid-message-dialog.component";
import { NgxMaskModule } from "ngx-mask";
import { BidVideoPlayerComponent } from "./bid-video-player/bid-video-player.component";
import { SharedModule } from "../Shared/shared.module";
import { PagamentoPixComponent } from "./premium-services/components/pagamento-pix/pagamento-pix.component";
import { PagamentoCartaoComponent } from "./premium-services/components/pagamento-cartao/pagamento-cartao.component";
import { CurrencyMaskModule } from "ngx-currency-mask";
import { CardModule } from "ngx-card/ngx-card";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,    
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyC9PnuRk42kbCPMOvsfHpn40r5SoyN38zI",
      libraries: ["places"],
    }),
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MatCarouselModule.forRoot(),
    HttpClientModule, // (Required) for share counts
    HttpClientJsonpModule, // (Optional) For linkedIn & Tumblr counts
    ShareModule,
    SharedModule,
    CurrencyMaskModule,
    CardModule,
  ],
  declarations: [
    BrandslogoComponent,
    SalesComponent,
    FeaturesComponent,
    SubscribeOneComponent,
    DealOfTheDayComponent,
    SocialShareComponent,
    AddToCardButtonComponent,
    ReviewPopupComponent,
    HeaderCartComponent,
    NotificationsCardComponent,
    WishListComponent,
    ConfirmationPopupComponent,
    PageTitleComponent,
    HomePageOneSliderComponent,
    TimerCountDownComponent,
    MapComponent,
    CurrencyDropDownComponent,
    TestimonialComponent,
    TeamComponent,
    ContactFormComponent,
    MissionVisionComponent,
    AboutInfoComponent,
    ImgZoomComponent,
    CommonSignInComponent,
    ProductCardComponent,
    HeaderUserProfileDropdownComponent,
    AppLogoComponent,
    LighteningDealsComponent,
    TopProductsComponent,
    SubscribeTwoComponent,
    HomePageTwoSliderComponent,
    CTAGroupComponent,
    CTATwoComponent,
    CollectionGalleryComponent,
    ProductCategoryCardComponent,
    CTASingleBannerComponent,
    DownloadAppSectionComponent,
    HomePageThreeSliderComponent,
    NewProductsCardComponent,
    ImageCarouselComponent,
    BidCardComponent,
    BidUploadComponent,
    BidDetailCardComponent,
    FornecedorAvaliacaoComponent,
    BidAccessControlDirective,
    BidPainelGestaoDirective,
    BidSocialShareDialogComponent,
    BidSocialShareDirective,
    NotificacaoMarcarLidoDirective,
    BidBudgetsDialogComponent,
    PremiumBannerComponent,
    PremiumServicesComponent,
    BidMessageDialogComponent,
    BidVideoPlayerComponent,
    PagamentoPixComponent,
    PagamentoCartaoComponent,
  ],
  exports: [
    BrandslogoComponent,
    SalesComponent,
    FeaturesComponent,
    SubscribeOneComponent,
    DealOfTheDayComponent,
    SocialShareComponent,
    AddToCardButtonComponent,
    HeaderCartComponent,
    NotificationsCardComponent,
    WishListComponent,
    PageTitleComponent,
    HomePageOneSliderComponent,
    TimerCountDownComponent,
    MapComponent,
    CurrencyDropDownComponent,
    TestimonialComponent,
    TeamComponent,
    ContactFormComponent,
    MissionVisionComponent,
    AboutInfoComponent,
    ImgZoomComponent,
    CommonSignInComponent,
    ProductCardComponent,
    HeaderUserProfileDropdownComponent,
    AppLogoComponent,
    LighteningDealsComponent,
    TopProductsComponent,
    SubscribeTwoComponent,
    HomePageTwoSliderComponent,
    CTAGroupComponent,
    CTATwoComponent,
    CollectionGalleryComponent,
    ProductCategoryCardComponent,
    CTASingleBannerComponent,
    DownloadAppSectionComponent,
    HomePageThreeSliderComponent,
    NewProductsCardComponent,
    ImageCarouselComponent,
    BidCardComponent,
    BidUploadComponent,
    BidDetailCardComponent,
    SharedModule,
    FornecedorAvaliacaoComponent,
    BidAccessControlDirective,
    BidPainelGestaoDirective,
    BidSocialShareDirective,
    NotificacaoMarcarLidoDirective,
    PremiumBannerComponent,
    PremiumServicesComponent,
    BidMessageDialogComponent,
    BidVideoPlayerComponent,
  ],
  entryComponents: [
    ReviewPopupComponent,
    ConfirmationPopupComponent,
    BidSocialShareDialogComponent,
    BidBudgetsDialogComponent,
    PremiumBannerComponent,
    PremiumServicesComponent,
    BidMessageDialogComponent,
    BidVideoPlayerComponent,
  ],
  providers: [
    AvaliacaoAtualizadaEvent,
    AvaliacaoMediaAtualizadaEvent,
    FornecedorService,
  ],
})
export class GlobalModule {}
