import { Router, Event, NavigationStart } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { TipoUsuarioEnum } from 'src/app/Shared/Models/Base/Enums/role.enum';

/*
 * Menu interface
 */
export interface Menu {
  state: string;
  name?: string;
  type?: string;
  icon?: string;
  children?: Menu[];
}

const HeaderOneItems = [
  {
    state: 'home',
    name: 'HOME',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'fornecedores',
    name: 'Fornecedores',
    type: 'link',
    icon: 'pages'
  },
  /*{
    state: 'about',
    name: 'Quem somos',
    type: 'link',
    icon: 'pages'
  },*/
  {
    state: 'contact',
    name: 'Contato',
    type: 'link',
    icon: 'perm_contact_calendar'
  }
];

const HeaderClientLoggedOneItems = [
  {
    state: 'home',
    name: 'HOME',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'fornecedores',
    name: 'Fornecedores',
    type: 'link',
    icon: 'pages'
  },
  {
    state: 'bids/meus-bids/emprogresso',
    name: 'BID',
    type: 'link',
    icon: 'party_mode'
  },
  {
    state: 'orcamentos',
    name: 'Orçamentos',
    type: 'link',
    icon: 'party_mode'
  },
  /*{
    state: 'about',
    name: 'Quem somos',
    type: 'link',
    icon: 'pages'
  },*/
  {
    state: 'contact',
    name: 'Contato',
    type: 'link',
    icon: 'perm_contact_calendar'
  }
];


const HeaderFornecedorLoggedOneItems = [
  {
    state: 'home',
    name: 'HOME',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'fornecedores',
    name: 'Fornecedores',
    type: 'link',
    icon: 'pages'
  },
  {
    state: 'bids/meus-bids/emprogresso',
    name: 'BID',
    type: 'link',
    icon: 'pages'
  },
  {
    state: 'orcamentos',
    name: 'Orçamentos',
    type: 'link',
    icon: 'party_mode'
  },
  {
    state: 'servicos/meus-servicos',
    name: 'Serviços',
    type: 'link',
    icon: 'pages'
  },
  /*{
    state: 'about',
    name: 'Quem somos',
    type: 'link',
    icon: 'pages'
  },*/
  {
    state: 'contact',
    name: 'Contato',
    type: 'link',
    icon: 'perm_contact_calendar'
  }
];

const FooterOneItems = [
  {
    state: '',
    name: 'ABOUT',
    type: 'sub',
    icon: '',
    children: [
      {
        state: 'about',
        name: 'ABOUT',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'term-condition',
        name: 'TERM AND CONDITION',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'privacy-policy',
        name: 'PRIVACY POLICY',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'faq',
        name: 'FAQ',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'contact',
        name: 'CONTACT US',
        type: 'link',
        icon: 'perm_contact_calendar'
      }
    ]
  },
  {
    state: '',
    name: 'SESSION',
    type: 'sub',
    icon: '',
    children: [
      {
        state: 'session/signin',
        name: 'SIGN IN',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'session/signup',
        name: 'REGISTER',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'session/forgot-password',
        name: 'FORGET PASSWORD',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'session/thank-you',
        name: 'THANK YOU',
        type: 'link',
        icon: 'arrow_right_alt'
      }
    ]
  },
  {
    state: '',
    name: 'CATEGORIES',
    type: 'sub',
    icon: '',
    children: [
      {
        state: 'products/women',
        name: 'WOMEN',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'products/men',
        name: 'MEN',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'products/accesories',
        name: 'ACCESSORIES',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'products/gadgets',
        name: 'GADGETS',
        type: 'link',
        icon: 'arrow_right_alt'
      }
    ]
  },
  {
    state: '',
    name: 'SOCIAL',
    type: 'sub',
    icon: '',
    children: [
      {
        state: 'https://www.facebook.com/',
        name: 'Facebook',
        type: 'social_link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'https://twitter.com/',
        name: 'Twitter',
        type: 'social_link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'https://www.youtube.com/',
        name: 'Youtube',
        type: 'social_link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'https://plus.google.com',
        name: 'Google Plus',
        type: 'social_link',
        icon: 'arrow_right_alt'
      }
    ]
  }
];

const HeaderOneBusinessArea = [
  {
    state: 'empresa',
    name: 'Acesso Empresa',
    type: 'link',
    icon: 'arrow_right_alt'
  },
  {
    state: 'empresa/sobre-site',
    name: 'Conheça o site',
    type: 'link',
    icon: 'arrow_right_alt'
  },
  {
    state: 'empresa/servicos-premium',
    name: 'Serviços Premium',
    type: 'link',
    icon: 'arrow_right_alt'
  }
];

@Injectable()
export class MenuItems {

  constructor(
    private router: Router,
    private authenticationHelper: AuthenticationHelper
  ) { }

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {

    if (this.router.url.includes('/empresa')) {
      return HeaderOneBusinessArea;
    }

    if (!this.authenticationHelper.estaLogado()) {
      return HeaderOneItems;
    }

    const tipoUsuario = this.authenticationHelper.obterTipoUsuario();

    if (tipoUsuario == TipoUsuarioEnum.Cliente) {
      return HeaderClientLoggedOneItems;
    } else if (tipoUsuario == TipoUsuarioEnum.Fornecedor) {
      return HeaderFornecedorLoggedOneItems;
    }
  }

  /*
   * Get all footer menu
   */
  getFooterOneMenu(): Menu[] {
    return FooterOneItems;
  }
}
