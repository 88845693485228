import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { ToastOptions, ToastaService } from "ngx-toasta";
import { RatingComponent } from "src/app/Global/Rating/Rating.component";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { ClienteBaseService } from "src/app/Services/cliente.servico";
import { FornecedorService } from "src/app/Services/fornecedor.service";
import { AvaliacaoAtualizadaEvent } from "src/app/Shared/Events/avaliacao-atualizada-event";
import { AvaliacaoMediaAtualizadaEvent } from "src/app/Shared/Events/avaliacao-media-atualizada-event";
import { FileType } from "src/app/Shared/Models/Base/Enums/file.type.enum";
import { TipoUsuarioEnum } from "src/app/Shared/Models/Base/Enums/role.enum";
import { FileRender } from "src/app/Shared/Models/Base/file.render.model";
import { ImagemModel } from "src/app/Shared/Models/Base/imagem.model";
import { TokenModel } from "src/app/Shared/Models/Base/token.model";
import { FornecedorAvaliacaoNodeModel } from "src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model";
import { AvaliacaoAtualizacaoModel } from "src/app/Shared/Models/Fornecedores/avaliacao-atualizacao.model";
import { AvaliacaoMediaAtualizacaoModel } from "src/app/Shared/Models/Fornecedores/avaliacao-media-atualizacao.model";
import { FornecedorModel } from "src/app/Shared/Models/Fornecedores/fornecedor.model";
import Swal from "sweetalert2";

@Component({
  selector: "app-avaliacao-modal",
  templateUrl: "./avaliacao-modal.component.html",
  styleUrls: ["./avaliacao-modal.component.scss"],
})
export class AvaliacaoModalComponent implements OnInit {
  link: string = window.location.href;
  toastOptions: ToastOptions = {
    title: "",
    msg: "",
    showClose: true,
    timeout: 5000,
    theme: "material",
  };

  fornecedor: FornecedorModel;
  fornecedorId: string;
  usuarioLogado: SignInModel;

  selectedFile: any;
  selectedFiles: FileRender[] = [];

  exibirProgressoUploadAvaliacao = false;

  questionarioForm: FormGroup;
  urlImage1 = "";
  urlImage2 = "";
  urlImage3 = "";

  file1: File | null = null;
  file2: File | null = null;
  file3: File | null = null;

  primeiraImagem: { base64: string; descricao: string };
  segundaImagem: { base64: string; descricao: string };
  terceiraImagem: { base64: string; descricao: string };

  eventListenerToImg1: any;
  eventListenerToImg2: any;
  eventListenerToImg3: any;

  @ViewChild("ratingbar", { static: false }) ratingBar: RatingComponent;
  @ViewChild("avaliacao", { static: false }) avaliacao: ElementRef;

  get fileType(): typeof FileType {
    return FileType;
  }

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<AvaliacaoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastaService: ToastaService,
    private formBuilder: FormBuilder,
    private clienteService: ClienteBaseService,
    private avaliacaoMediaAtualizadaEvent: AvaliacaoMediaAtualizadaEvent,
    private avaliacaoAtualizadaEvent: AvaliacaoAtualizadaEvent,
    private fornecedorService: FornecedorService
  ) {
    this.fornecedor = this.data.fornecedor;
    this.fornecedorId = this.data.fornecedorId;
    this.usuarioLogado = this.data.usuarioLogado;
    this.criarFormularioQuestionario();
  }

  ngOnInit() {}

  setFile1(event) {
    this.urlImage1 = "";
    this.file1 = event.target.files[0];
    this.eventListenerToImg1 = event;
  }

  setFile2(event) {
    this.urlImage2 = "";
    this.file2 = event.target.files[0];
    this.eventListenerToImg2 = event;
  }

  setFile3(event) {
    this.urlImage3 = "";
    this.file3 = event.target.files[0];
    this.eventListenerToImg3 = event;
  }

  imageCropped1(event: any) {
    this.primeiraImagem = {
      base64: event.original.base64,
      descricao: this.file1.name,
    };
  }

  imageCropped2(event: any) {
    this.segundaImagem = {
      base64: event.original.base64,
      descricao: this.file2.name,
    };
  }

  imageCropped3(event: any) {
    this.terceiraImagem = {
      base64: event.original.base64,
      descricao: this.file3.name,
    };
  }

  criarFormularioQuestionario() {
    this.questionarioForm = this.formBuilder.group({
      questao: ["", Validators.required],
    });
  }

  selecionarArquivo(event) {
    if (this.selectedFiles.length >= 4) {
      this.toastOptions.title = "Atenção";
      this.toastOptions.msg = "Limite de imagens/videos atingido.";
      this.toastaService.warning(this.toastOptions);
      return;
    }

    const mimeType = event.target.files[0].type;
    const tipoValido =
      mimeType.match(/image\/*/) != null || mimeType.match(/video\/*/) != null;

    if (!tipoValido) {
      this.toastOptions.title = "Atenção";
      this.toastOptions.msg = "Apenas imagens/videos são permitidas.";
      this.toastaService.warning(this.toastOptions);
      return;
    }

    const fileData: FileRender = {
      file: event.target.files[0],
      type: mimeType.indexOf("image") > -1 ? FileType.Image : FileType.Video,
      size: event.target.files[0].size,
    };

    let totalSizeUpload = fileData.size;
    this.selectedFiles.forEach((value) => (totalSizeUpload += value.size));

    if (totalSizeUpload > 52428800) {
      // 50mb
      this.toastOptions.title = "Atenção";
      this.toastOptions.msg =
        "Limite de 50mb no total de imagens/videos atingido.";
      this.toastaService.warning(this.toastOptions);
      return;
    }

    const fileReader = new FileReader();
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = (_event) => {
      this.exibirProgressoUploadAvaliacao = true;
      fileData.url = fileReader.result;
      this.selectedFiles.push(fileData);
    };

    fileReader.onloadend = (_event) => {
      this.exibirProgressoUploadAvaliacao = false;
    };

    this.selectedFile = null;
  }

  removerImagemAvaliacao(index: number) {
    if (index > this.selectedFiles.length) {
      return;
    }

    this.selectedFiles.splice(index, 1);
  }

  emitirEventosAtualizacao() {
    const avaliacaoMediaAtualizada = new AvaliacaoMediaAtualizacaoModel({
      fornecedorId: this.fornecedor.id,
      media: this.fornecedor.mediaAvaliacao,
      totalAvaliacao: this.fornecedor.totalAvaliacao,
    });

    this.avaliacaoMediaAtualizadaEvent.onAvaliacaoMediaAtualizada.emit(
      avaliacaoMediaAtualizada
    );

    const avaliacaoAtualizada = new AvaliacaoAtualizacaoModel({
      fornecedorId: this.fornecedor.id,
      avaliacoes: this.fornecedor.avaliacoes,
    });

    this.avaliacaoAtualizadaEvent.onAvaliacaoAtualizada.emit(
      avaliacaoAtualizada
    );
  }

  atualizarAvaliacoesAposEnvio(
    avaliacoes: Array<FornecedorAvaliacaoNodeModel>
  ) {
    this.toastOptions.title = "Sucesso";
    this.toastOptions.msg = "Sua avaliação foi enviada com sucesso!";
    this.toastaService.success(this.toastOptions);

    this.fornecedor.avaliacoes = avaliacoes;
    this.emitirEventosAtualizacao();
    // this.validarPermissaoAvaliar();
  }

  enviarQuestionario() {
    if (!this.questionarioForm.valid) {
      return;
    }

    if (!this.ratingBar.rate || this.ratingBar.rate <= 0) {
      this.toastOptions.title = "Erro";
      this.toastOptions.msg = "Necessário informar a nota da avaliação.";
      this.toastaService.error(this.toastOptions);
      return;
    }

    const pergunta = this.questionarioForm.get("questao").value;

    let imagens: ImagemModel[] = [];
    if (this.primeiraImagem) {
      imagens.push({
        descricao: this.primeiraImagem.descricao,
        formato: "",
        nome: "",
        url: this.primeiraImagem.base64,
        id: "",
      });
    }

    if (this.segundaImagem) {
      imagens.push({
        descricao: this.segundaImagem.descricao,
        formato: "",
        nome: "",
        url: this.segundaImagem.base64,
        id: "",
      });
    }

    if (this.terceiraImagem) {
      imagens.push({
        descricao: this.terceiraImagem.descricao,
        formato: "",
        nome: "",
        url: this.terceiraImagem.base64,
        id: "",
      });
    }

    const avaliacao = new FornecedorAvaliacaoNodeModel({
      fornecedorId: this.fornecedorId,
      email: this.usuarioLogado.email,
      nota: this.ratingBar.rate,
      estaLogado: true,
      usuarioId: this.usuarioLogado.id,
      autorId: this.usuarioLogado.id,
      autor: this.usuarioLogado.nomeUsuario,
      texto: pergunta,
      data: new Date(),
      imagens: imagens,
    });

    console.log(this.usuarioLogado, avaliacao);

    if (this.usuarioLogado.tipoUsuario == TipoUsuarioEnum.Cliente) {
      this.clienteService
        .adicionarAvaliacao(avaliacao)
        .then((response: Array<FornecedorAvaliacaoNodeModel>) => {
          // this.atualizarAvaliacoesAposEnvio(response);
          Swal.fire("Sucesso", "Sua avaliação foi enviada com sucesso!", "success");
          this.dialogRef.close();
        });
    } else {
      this.fornecedorService
        .adicionarAvaliacao(avaliacao)
        .then((response: Array<FornecedorAvaliacaoNodeModel>) => {
          Swal.fire("Sucesso", "Sua avaliação foi enviada com sucesso!", "success");
          this.dialogRef.close();
        });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
