import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize, map, pluck } from "rxjs/operators";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import {
  CategoriasServico,
  CidadesEstadoModel,
} from "src/app/Pages/profile/components/orcamento/models/orcamento.model";
import { OrcamentoService } from "src/app/Pages/profile/components/orcamento/orcamento.service";
import { FornecedorService } from "src/app/Services/fornecedor.service";
import { LocalidadeService } from "src/app/Services/localidade.service";
import { ServicoService } from "src/app/Services/servico.service";
import { UserService } from "src/app/Services/user.service";
import { ServicoModel } from "src/app/Shared/Models/Servicos/servico.model";
import { CidadeSp, EstadoSp } from "src/app/Shared/utils/cidade-estado.utils";
import {
  CadastrarServicoAoServicoOfertadoModel,
  CadastrarServicoModel,
} from "./models/cadastrar-servico.model";
import Swal from "sweetalert2";
import { ServicosOfertadosModel } from "./models/servicos-ofertados.model";
import { Observable, of } from "rxjs";
import { ProdutosServicosExtras } from "../produtos-servicos/models/produtos-servicos.model";
import { PerfilService } from "src/app/Services/perfil.service";
import { MatSelectChange } from "@angular/material";
import { MatDialog } from "@angular/material";
import { OrcamentosModalComponent } from "src/app/Shared/components/orcamentos-modal/orcamentos-modal.component";
import { NewServiceModalComponent } from "src/app/Shared/components/new-service-modal/new-service-modal.component";
import { CategoriaServicoEnum } from "src/app/Shared/Models/Servicos/Enums/categoria-servico.enum";

@Component({
  selector: "app-servicos",
  templateUrl: "./servicos.component.html",
  styleUrls: ["./servicos.component.scss"],
})
export class ServicosComponent implements OnInit, OnChanges {
  form!: FormGroup;
  newservice = false;
  selectAll = false;
  allOption: Array<CidadesEstadoModel> = [
    {
      id: 0,
      nome: `Todos`
    }
  ];
  servicos: Array<ServicoModel> = [];
  servicosFiltrados: Array<ServicoModel> = [];
  categorias = CategoriasServico;
  estados: Array<CidadesEstadoModel> = [EstadoSp];
  cidades$: Observable<Array<CidadesEstadoModel>>;
  bairros$: Observable<Array<CidadesEstadoModel>>;
  zonas$: Observable<Array<CidadesEstadoModel>>;
  user: SignInModel;
  servicosOfertados: ServicosOfertadosModel[] = [];

  todosBairrosValidos$: Observable<Array<CidadesEstadoModel>>;
  todasZonasValidas$: Observable<Array<CidadesEstadoModel>>;


  file1: File | null = null;
  file2: File | null = null;
  file3: File | null = null;
  eventListenerToImg1: any;
  eventListenerToImg2: any;
  eventListenerToImg3: any;
  primeiraImagem: { base64: string; descricao: string };
  segundaImagem: { base64: string; descricao: string };
  terceiraImagem: { base64: string; descricao: string };
  addingServiceFlag = false;
  formAddService!: FormGroup;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private servicoService: ServicoService,
    private orcamentoService: OrcamentoService,
    private localidadeService: LocalidadeService,
    private fornecedorService: FornecedorService,
    private userService: UserService,
    private perfilService: PerfilService,
    private cdRef : ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.form = formBuilder.group({
      categoria: ["", Validators.required],
      estado: [""],
      servico: ["", Validators.required],
      bairro: ["", Validators.required],
      cidade: ["", Validators.required],
      endereco: [""],
      zona: ["", Validators.required],
      valor: [""],
      descricao: [""],
    });
    this.formAddService = formBuilder.group({
      nome: ["", Validators.required],
      valor: ["", Validators.required],
      descricao: ["", Validators.required],
    });
    this.user = userService.user;
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('change')
    this.cdRef.detectChanges();
  }

  get categoria() {
    return this.form.get('categoria');
  }  

  filtrarServicos() {
    this.servicosFiltrados = this.servicos.filter(s => s.categoria == this.categoria.value.id);
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();    
  }  

  ngOnInit() {
    console.log('change')
    this.carregarServicos();
    this.getBairros();
    this.getCidades();
    this.getZonas();
    this.obterServicosOfertados();
  }

  private getCidades() {
    this.cidades$ = this.localidadeService
      .obterCidades(EstadoSp.id)
      .pipe(pluck("dados"));
  }
  private carregarServicos() {
    return this.servicoService
      .obterServicos()
      .pipe(pluck("dados"))
      .subscribe((response) => {
        this.servicos = response;
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
      });
  }
  private getBairros() {
    this.bairros$ = this.orcamentoService.postGetBairros([CidadeSp.id]);
  }

  private mudouCidade(event: MatSelectChange) {
    if (event.value) {
      if (event.value.length == 0) {
        console.log('false')
      }

      const idsCidade = event.value!.map(c => c.id) || [CidadeSp.id];
      this.todosBairrosValidos$ = this.orcamentoService.postGetBairros(idsCidade);
      this.todasZonasValidas$ = this.localidadeService.obterZonas().pipe(pluck("dados"));
      console.log(this.todosBairrosValidos$, this.todasZonasValidas$);
      
      if (idsCidade.length > 1) {
        this.selectAll = true;
        return;
      }

      this.zonas$ = this.todasZonasValidas$;
      this.bairros$ = this.todosBairrosValidos$;
      this.selectAll = false;
    }

    console.log(event);
  }

  private getZonas() {
    this.zonas$ = this.localidadeService.obterZonas().pipe(pluck("dados"));
  }

  async cadastrarNovoServico() {
    const form = this.form.controls;

    let todasZonas = [];
    let todosBairros = [];

    if (this.selectAll) {
      todasZonas = await this.todasZonasValidas$.toPromise();
  
      todosBairros = await this.todosBairrosValidos$.toPromise();
    }

    console.log(todasZonas, todosBairros, this.selectAll)

    const data = {
      bairros: this.selectAll ? todosBairros : [form.bairro.value],
      cidades: form.cidade.value,
      endereco: form.endereco.value,
      estado: form.estado.value || EstadoSp,
      servicos: [form.servico.value],
      zona: this.selectAll ? todasZonas[0] : form.zona.value,
      minAmount: form.valor.value,
      descricao: form.descricao.value,
    } as CadastrarServicoModel;
    data.imagens = [];
    if (this.primeiraImagem) data.imagens.push(this.primeiraImagem);

    if (this.segundaImagem) data.imagens.push(this.segundaImagem);

    if (this.terceiraImagem) data.imagens.push(this.terceiraImagem);

    this.fornecedorService
      .postCadastrarNovoServico(this.user.id, data)
      .subscribe(
        () => {
          Swal.fire("Sucesso!", "Serviço cadastrado com sucesso", "success");
          this.newservice = false;
          this.form.reset(" ");
          this.obterServicosOfertados();
        },
        (error) =>
          Swal.fire("Erro!", "Ocorreu um erro ao cadastrar serviço", "error")
      );
  }
  setFile1(event) {
    this.file1 = event.target.files[0];
    this.eventListenerToImg1 = event;
  }
  setFile2(event) {
    this.file2 = event.target.files[0];
    this.eventListenerToImg2 = event;
  }
  setFile3(event) {
    this.file3 = event.target.files[0];
    this.eventListenerToImg3 = event;
  }
  imageCropped1(event: any) {
    this.primeiraImagem = {
      base64: event.original.base64,
      descricao: this.file1.name,
    };
  }
  imageCropped2(event: any) {
    this.segundaImagem = {
      base64: event.original.base64,
      descricao: this.file2.name,
    };
  }
  imageCropped3(event: any) {
    this.terceiraImagem = {
      base64: event.original.base64,
      descricao: this.file3.name,
    };
  }

  adicionarServicoAoServicoOfertado(categoriaId: number, serviceId: number) {
    const form = this.formAddService.value as ServicoModel;

    const data = {
      categoria: categoriaId,
      descricao: form.descricao,
      nome: form.nome,
      valor: form.valor,
    } as CadastrarServicoAoServicoOfertadoModel;
    data.imagens = [];
    if (this.primeiraImagem) data.imagens.push(this.primeiraImagem);

    if (this.segundaImagem) data.imagens.push(this.segundaImagem);

    if (this.terceiraImagem) data.imagens.push(this.terceiraImagem);

    this.perfilService
      .adicionarServicoAoServicoOfertado(this.user.id, categoriaId, serviceId, data)
      .subscribe((response) => {
        this.addingServiceFlag = false;
        console.log("resposnse criar servico", response);
        this.cdRef.detectChanges();
      });
  }

  private obterServicosOfertados() {
    this.fornecedorService
      .obterServicos(this.user.email)
      .pipe(pluck("dados"))
      .subscribe((response: ServicosOfertadosModel[]) => {
        this.servicosOfertados = response;
        console.log('Servicos Ofertados: ', response);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
      });
  }

  openModal(categoriaId: number, serviceId: number) {
    this.dialog.open(NewServiceModalComponent, {
      width: "250px",
      data: {
        categoriaId: categoriaId,
        serviceId: serviceId,
        user: this.user,
        isDelete: false
      },
    }).afterClosed().subscribe(() => {
      console.log('afterClosed')
      this.carregarServicos();
      this.obterServicosOfertados();
    });
  }

  openEditModal(servico: ServicoModel, categoriaId: number, serviceId: number) {
    this.dialog.open(NewServiceModalComponent, {
      width: "250px",
      data: {
        categoriaId: categoriaId,
        serviceId: serviceId,
        user: this.user,
        servico: servico,
        isDelete: false,
        isEdit: true
      },
    }).afterClosed().subscribe(() => {
      console.log('afterClosed')
      this.carregarServicos();
      this.obterServicosOfertados();
    });
  }

  openDeleteModal(servico: ServicoModel, removeAll: boolean = false) {
    this.dialog.open(NewServiceModalComponent, {
      width: "250px",
      data: {
        serviceId: servico.id || 0,
        user: this.user,
        servico: servico,
        isDelete: true,
        removeAll: removeAll
      },
    }).afterClosed().subscribe(() => {
      console.log('afterClosed')
      this.obterServicosOfertados();
    });
  }

  openDeleteServiceModal(servico: ServicoModel) {
    this.dialog.open(NewServiceModalComponent, {
      width: "250px",
      data: {
        serviceId: servico.id,
        user: this.user,
        servico: servico,
        isDelete: true
      },
    }).afterClosed().subscribe(() => {
      console.log('afterClosed')
      this.carregarServicos();
      this.obterServicosOfertados();
    });
  }

  private obterDescricaoCidades(cidades: Array<CidadesEstadoModel> | null): string {
    return cidades!.map(c => c.nome).join(', ') || '';
  }

  private oberDescricaoCategoria(servicoId: number): string {
    return CategoriaServicoEnum[servicoId].toString();
  }
}
