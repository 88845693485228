import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";

@Injectable({ providedIn: "root" })
export class PlanoService {
  private readonly url = environment.endpoints.backend + "/plano";

  constructor(private http: HttpClient) {}

  obterPlanoPago(): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(
      `${this.url}/obter-plano-pago-recorrente`
    );
  }

  obterPlanosAtivoBoleto(): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(`${this.url}/obter-planos-boleto`);
  }
}
