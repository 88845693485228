import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from '../../environments/environment';
import { ToastaService } from 'ngx-toasta';
import { HttpClient } from '@angular/common/http';
import { AuthenticationHelper } from './helpers/authentication-helper.service';
import { Router } from '@angular/router';
import { SessaoService } from './sessao.service';
import { FornecedorServicoExtraVmModel } from '../Shared/Models/Fornecedores/ViewModels/fornecedor-servico-extra-vm.model';
import { pluck } from 'rxjs/operators';

@Injectable()
export class FornecedorServicoExtraService extends BaseService {

    private readonly url = environment.endpoints.backend + '/PerfilServicoExtra';

    constructor(
        toastaService: ToastaService,
        private http: HttpClient,
        authenticationHelper: AuthenticationHelper,
        private router: Router,
        private sessaoService: SessaoService
    ) {
        super(
            toastaService,
            authenticationHelper,
        );
    }

    buscarLista(usuarioId: string): Promise<FornecedorServicoExtraVmModel[]> {
        return new Promise((resolve, reject) => {
            this.http
                .get<FornecedorServicoExtraVmModel[]>(`${this.url}/${usuarioId}/fornecedor`)
                .pipe(pluck('dados'))
                .subscribe((response: any) => {
                        resolve(response);
                    }, error => {
                        this.manipuladorDeErro(error);
                        reject(null);
                    });
        });
    }

    gravar(usuarioId: string, servicoExtra: FornecedorServicoExtraVmModel): Promise<FornecedorServicoExtraVmModel[]> {
        return new Promise((resolve, reject) => {
            this.http
                .post<FornecedorServicoExtraVmModel[]>(`${this.url}/${usuarioId}/fornecedor`,
                    servicoExtra)
                    .subscribe((response: any) => {
                        this.toastOptions.title = 'Sucesso!';
                        this.toastOptions.msg = 'Serviço extra cadastrado com sucesso!';
                        this.toastaService.success(this.toastOptions);
                        resolve(response);
                    }, error => {
                        this.manipuladorDeErro(error);
                        reject(null);
                    });
        });
    }

    excluir(usuarioId: string, servicoExtraId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http
                .delete<boolean>(`${this.url}/${usuarioId}/fornecedor/${servicoExtraId}`)
                    .subscribe((response: any) => {
                        this.toastOptions.title = 'Sucesso!';
                        this.toastOptions.msg = 'Serviço extra excluído com sucesso!';
                        this.toastaService.success(this.toastOptions);
                        resolve(true);
                    }, error => {
                        this.manipuladorDeErro(error);
                        reject(false);
                    });
        });
    }
}
