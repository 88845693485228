import { Component, Input, OnInit } from "@angular/core";
import {
  ServicosOfertadosAgrupadosModel
} from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/servicos-ofertados.model";
import { CategoriaServicoEnum } from "../../../../../../../../Shared/Models/Servicos/Enums/categoria-servico.enum";
import { DetalhesFornecedorService } from "../../../../detalhes-fornecedor.service";

@Component({
  selector: "app-aba-produtos",
  templateUrl: "./aba-produtos.component.html",
  styleUrls: ["./aba-produtos.component.scss"],
})
export class AbaProdutosComponent implements OnInit {
  @Input()
  fornecedorId: string;
  show = false;
  servicos: Array<ServicosOfertadosAgrupadosModel> = [];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
  };
  categoriaServicoEnum = CategoriaServicoEnum;
  constructor(private service: DetalhesFornecedorService) {}

  ngOnInit() {
    this.buscaServicosExtra();
  }

  private buscaServicosExtra() {
    this.service
      .buscaServicosPorFornecedorId(this.fornecedorId)
      .subscribe((response) => {
        this.servicos = response;
      });
  }
}
