import { Component, OnInit } from '@angular/core';
import { SessaoService } from 'src/app/Services/sessao.service';
import { Router } from '@angular/router';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { TokenModel } from 'src/app/Shared/Models/Base/token.model';

@Component({
  selector: 'embryo-HeaderUserProfileDropdown',
  templateUrl: './HeaderUserProfileDropdown.component.html',
  styleUrls: ['./HeaderUserProfileDropdown.component.scss']
})
export class HeaderUserProfileDropdownComponent implements OnInit {

  urlProfileImage = '';
  tokenModel = new TokenModel();
  constructor(
    private sessaoService: SessaoService,
    private router: Router,
    private authenticationHelper: AuthenticationHelper
  ) { }

  ngOnInit() {
    this.tokenModel = this.authenticationHelper.obterTokenModel();

    this.urlProfileImage = this.authenticationHelper.obterImagemPerfil();
    this.authenticationHelper
      .changeProfileImageEvent
      .subscribe(res => {
        this.urlProfileImage = res;
      });
  }

  goToSettings() {
    if (this.tokenModel && this.authenticationHelper.estaLogado()) {
      this.router.navigate(['/conta',
        this.tokenModel.cliente,
        {
          outlets: {
            conta: ['perfil']
          }
        }
      ]);
    }
  }

  goToDashboard() {
    if (this.tokenModel && this.authenticationHelper.estaLogado()) {
      this.router.navigate(['/painel-gestao']);
    }
  }

  goToNotifications() {
    if (this.tokenModel && this.authenticationHelper.estaLogado()) {
      this.router.navigate(['/notificacoes']);
    }
  }

  goToPlans() {
    if (this.tokenModel && this.authenticationHelper.estaLogado()) {
      this.router.navigate(['/plano/meu-plano']);
    }
  }

  logout() {
    this.sessaoService.sair();
  }
}
