import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PerfilFornecedorModule } from "src/app/Pages/perfil-fornecedor/perfil-fornecedor.module";
import { SharedModule } from "src/app/Shared/shared.module";
import { AbaLocalizacaoComponent } from "./components/detalhes-fornecedor/components/aba-localizacao/aba-localizacao.component";
import { AbaPerfilComponent } from "./components/detalhes-fornecedor/components/aba-perfil/aba-perfil.component";
import { AbaPerguntasComponent } from "./components/detalhes-fornecedor/components/aba-perguntas/aba-perguntas.component";
import { AbaProdutosComponent } from "./components/detalhes-fornecedor/components/aba-produtos/aba-produtos.component";
import { DetalhesFornecedorComponent } from "./components/detalhes-fornecedor/detalhes-fornecedor.component";
import { MenorPrecoPipe } from "./components/detalhes-fornecedor/pipes/menor-preco.pipe";
import { NgxGalleryModule } from "ngx-gallery";
import { MatExpansionModule } from "@angular/material/expansion";
@NgModule({
  declarations: [
    DetalhesFornecedorComponent,
    AbaPerfilComponent,
    AbaProdutosComponent,
    AbaPerguntasComponent,
    AbaLocalizacaoComponent,
    MenorPrecoPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerfilFornecedorModule,
    AgmCoreModule,
    NgxGalleryModule,
    MatExpansionModule,
  ],
  exports: [DetalhesFornecedorComponent],
})
export class DetalhesFornecedorModule {}
