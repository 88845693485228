import { Component, HostListener, OnInit } from "@angular/core";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { PerfilService } from "src/app/Services/perfil.service";
import { UserService } from "src/app/Services/user.service";
import { TipoUsuarioEnum } from "src/app/Shared/Models/Base/Enums/role.enum";
import { RespostaModel } from "src/app/Shared/Models/Base/resposta.model";
import { PerfilAtualizarImagemVmModel } from "src/app/Shared/Models/Fornecedores/ViewModels/perfil-atualizar-imagem-vm.model";
import { DadosPerfilModel } from "./dados-perfil.model";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { StatusAnuncio } from "src/app/Shared/Models/Base/Enums/status-anuncio.enum";
import { TipoVisualizacaoAnuncioEnum } from "src/app/Shared/Models/Base/Enums/tipo-visualizacao-anuncio.enum";
import { pluck, switchMap } from "rxjs/operators";
import { AnuncioModel } from "src/app/Shared/Models/Anuncios/anuncio.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ServicoEmAndamentoModel } from "src/app/Shared/Models/Servicos/servico-em-andamento.model";

enum PrimaryMenu {
  Oportunidades = 1,
  Clientes = 2,
  Fornecedores = 3,
  Perfil = 4,
  Notificacoes = 5,
}
@Component({
  selector: "app-perfil-fornecedor",
  templateUrl: "./perfil-fornecedor.component.html",
  styleUrls: ["./perfil-fornecedor.component.scss"],
})
export class PerfilFornecedorComponent implements OnInit {
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }
  private _uploadImg = new PerfilAtualizarImagemVmModel();
  private _uploadImgBanner = new PerfilAtualizarImagemVmModel();
  anuncios: Array<ServicoEmAndamentoModel> = [];
  user: SignInModel;
  eventListenerToProfileImg: any;
  eventListenerToProfileImgBanner: any;
  selectedMenu = PrimaryMenu.Oportunidades;
  primaryMenuEnum = PrimaryMenu;
  mobileView: boolean;
  profileData = {} as DadosPerfilModel;
  amountSlides: number;
  quantidadeDeBids = {
    fechados: 0,
    emAndamento: 0,
  };
  progressoPerfil$: Observable<number>;

  constructor(
    private userService: UserService,
    private perfilService: PerfilService,
    private anuncioService: AnuncioService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.user = userService.user;
    if (this.activatedRoute.snapshot.queryParams["selectedMenu"]) {
      this.selectedMenu = Number(
        this.activatedRoute.snapshot.queryParams["selectedMenu"]
      );
    }
  }

  ngOnInit() {
    this.verifyScreenSize();
    this.obtemDadosPerfil();
    this.buscaPorStatus();
    this.getAnunciosPorInteresse();
    this.progressoPerfil$ = this.perfilService.buscaProgressoDoPerfilPorId(
      this.user.id
    );
  }
  
  private getAnunciosPorInteresse() {
    this.anuncioService
      .obterBidsPorInteresse(this.user.id)
      .pipe(pluck("dados"))
      .subscribe((response: Array<ServicoEmAndamentoModel>) => {
        this.anuncios = response;
        this.amountSlides = Math.ceil(this.anuncios.length / 4);
        console.log('Quantidade de Anuncios: ', this.amountSlides);        
      });
  }
  
  abrirDetalhesDoBid(id: string) {
    this.router.navigate(["detalhes-bid"], {
      queryParams: { id: id, route: "perfil-fornecedor" },
    });
  }  

  imageCropped(event: any) {
    this._uploadImg.imagem = event.original.base64;
    this.perfilService
      .atualizarImagemPerfil(this.user, this._uploadImg)
      .then(() => {
        this.obtemDadosPerfil();
      });
  }
  imageCroppedBanner(event: any) {
    this._uploadImgBanner.imagem = event.original.base64;
    this.perfilService
      .atualizarImagemPerfilCapa(this.user, this._uploadImgBanner)
      .then(() => {
        this.obtemDadosPerfil();
      });
  }

  uploadImg(event: any) {
    this.eventListenerToProfileImg = event;
  }
  uploadImgBanner(event: any) {
    this.eventListenerToProfileImgBanner = event;
  }

  obtemDadosPerfil() {
    this.perfilService
      .obterDadosPerfil(this.user.id, TipoUsuarioEnum.Fornecedor)
      .then((resp: DadosPerfilModel) => {
        this.profileData = resp;
      });
  }

  redirectMyPlan() {
    this.router.navigate(["/premium"]);
  }

  private buscaPorStatus() {
    let data = {
      clienteId: this.user.id,
      statusAnuncio: StatusAnuncio.emprogresso,
      tipoVisualizacaoAnuncio: TipoVisualizacaoAnuncioEnum.total,
      ultimoMes: false,
    };
    this.anuncioService
      .obterPorStatus(data)
      .pipe(
        pluck("dados"),
        switchMap((resp: Array<AnuncioModel>) => {
          this.quantidadeDeBids.emAndamento = resp.length;

          data.statusAnuncio = StatusAnuncio.completo;

          return this.anuncioService.obterPorStatus(data);
        }),
        pluck("dados")
      )
      .subscribe((response: Array<AnuncioModel>) => {
        this.quantidadeDeBids.fechados = response.length;
        console.log("response ", response);
      });
  }

  private verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }
}
