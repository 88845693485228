export const environment = {
	production: true,
	googleMapsApiKey: 'AIzaSyBkn716WTkxZUCq-NopPi6QaZf7Zoh8das',
	endpoints: {
		backend: 'https://bidkidspartyapi.azurewebsites.net/api',
		frontend: 'www.bidkidsparty.com'
	},
	planValidation: true,
	tokenJuno: '07D5C72BA78FC108A5AF336AE1958D58E2D28B6068C98ECE7C3EBC841737D90C372412150B46B730',
	firebase: {
		apiKey: "AIzaSyAWIUjgtFYTu7gnOQcHddfXhoA0Te6sAHA",
		authDomain: "embryo-angular.firebaseapp.com",
		databaseURL: "https://embryo-angular.firebaseio.com",
		projectId: "embryo-angular",
		storageBucket: "embryo-angular.appspot.com",
		messagingSenderId: "908846231613",
		appId: "1:908846231613:web:93e1be9ff2aa99739c7a54",
		measurementId: "G-LF0K3VWXS7"
	}
};
