import { Directive, HostListener, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { TipoPlanoEnum } from 'src/app/Shared/Models/Planos/Enums/tipo-plano.enum';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appBidAccessControl]'
})
export class BidAccessControlDirective implements OnInit, OnDestroy {

  private clicks = new Subject();
  private subscription: Subscription;

  @Output() bidClick = new EventEmitter();

  @HostListener('click', ['$event']) clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    if (environment.planValidation) {
      if (this.authenticationHelper.obterTipoPlano() == TipoPlanoEnum.Gratis) {
        this.authenticationHelper.exibirDialogAcesso();
        return;
      }
    }

    this.clicks.next(event);
  }

  constructor(
    private authenticationHelper: AuthenticationHelper) { }

  ngOnInit(): void {
    this.subscription = this.clicks
      .subscribe(e => this.bidClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
