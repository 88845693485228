import { FornecedorAvaliacaoNodeModel } from './ViewModels/fornecedor-avaliacao-node.model';

export class AvaliacaoAtualizacaoModel {

    public fornecedorId: string;
    public clienteId: string;
    public avaliacoes: Array<FornecedorAvaliacaoNodeModel> =
        new Array<FornecedorAvaliacaoNodeModel>();

    constructor(values?: Partial<AvaliacaoAtualizacaoModel>) {
        Object.assign(this, values);
    }

}