import { Injectable } from '@angular/core';

interface Scripts {
    name: string;
    src: string;
}

export const ScriptStore: Scripts[] = [
    {
        name: 'getnet',
        src: 'https://checkout-homologacao.getnet.com.br/loader.js'
    }
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {
    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    removeScript() {

        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });

        const elementoScript = document.getElementById('getnet-loader-script');

        if (elementoScript) {
            elementoScript.remove();
        }

        const elementoIframe = document.getElementById('getnet-checkout');

        if (elementoIframe) {
            elementoIframe.remove();
        }

        const elementoIframeCreditCardChange = document.getElementById('getnet-loader-script-creditcardchange');

        if (elementoIframeCreditCardChange) {
            elementoIframeCreditCardChange.remove();
        }

    }

    loadScript(name: string, sellerId: string, token: string, clienteId: string,
        ordemId: string, nome: string, sobrenome: string, tipoIdentificacao: string,
        numeroIdentificacao: string, planoId: string, urlCallbackSucesso: string) {
        return new Promise((resolve, reject) => {
            if (!this.scripts[name].loaded) {
                //load script
                const script = document.createElement('script');
                // script.type = '';
                script.id = 'getnet-loader-script';
                script.setAttribute('data-getnet-sellerid', sellerId);
                script.setAttribute('data-getnet-payment-methods-disabled', '["debito","boleto"]');
                script.setAttribute('data-getnet-token', token);
                script.setAttribute('data-getnet-customerid', clienteId);
                script.setAttribute('data-getnet-orderid', ordemId);
                script.setAttribute('data-getnet-button-class', 'pay-button-getnet');
                script.setAttribute('data-getnet-customer-first-name', nome);
                script.setAttribute('data-getnet-customer-last-name', sobrenome);
                script.setAttribute('data-getnet-customer-document-type', tipoIdentificacao);
                script.setAttribute('data-getnet-customer-document-number', numeroIdentificacao);
                script.setAttribute('data-getnet-url-callback', '');
                script.setAttribute('data-getnet-recurrency', 'true');
                script.setAttribute('data-getnet-recurrency-planid', planoId);



                script.src = this.scripts[name].src;
                if (script.readyState) {
                    //IE
                    script.onreadystatechange = () => {
                        if (
                            script.readyState === 'loaded' ||
                            script.readyState === 'complete'
                        ) {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) =>
                    resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }


    loadScriptCreditCardChange(name: string, sellerId: string, token: string, clienteId: string,
        subscriptionid: string) {
        return new Promise((resolve, reject) => {
            if (!this.scripts[name].loaded) {
                //load script
                const script = document.createElement('script');


                script.id = 'getnet-loader-script-creditcardchange';
                script.setAttribute('data-getnet-update-card', 'true');
                script.setAttribute('data-getnet-sellerid', sellerId);
                script.setAttribute('data-getnet-token', token);
                script.setAttribute('data-getnet-customerid', clienteId);
                script.setAttribute('data-getnet-button-class', 'pay-button-getnet');
                script.setAttribute('data-getnet-url-callback', '');
                script.setAttribute('data-getnet-recurrency', 'true');
                script.setAttribute('data-getnet-recurrency-subscriptionid', subscriptionid);

                script.src = this.scripts[name].src;
                if (script.readyState) {
                    //IE
                    script.onreadystatechange = () => {
                        if (
                            script.readyState === 'loaded' ||
                            script.readyState === 'complete'
                        ) {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) =>
                    resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }
}
