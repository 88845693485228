import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RespostaModel } from '../Shared/Models/Base/resposta.model';
import { CategoriaServicoEnum } from '../Shared/Models/Servicos/Enums/categoria-servico.enum';


@Injectable()
export class CategoriaService {

    private readonly url = environment.endpoints.backend + '/categoria';

    constructor(private http: HttpClient) { }

    obterCategorias(): Observable<RespostaModel<CategoriaServicoEnum[]>> {
        return this.http.get<RespostaModel<CategoriaServicoEnum[]>>(`${this.url}`);
    }
}