import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { ProdutosServicosExtras } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/produtos-servicos/models/produtos-servicos.model";
import { ServicosOfertadosAgrupadosModel, ServicosOfertadosModel } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/components/servicos/models/servicos-ofertados.model";
import { PerguntasFrequentesModel } from "src/app/Pages/perfil-fornecedor/components/meu-perfil/models/perguntas-frequentes.model";
import { DadosPerfilModel } from "src/app/Pages/perfil-fornecedor/components/perfil-fornecedor/dados-perfil.model";
import { ImagemModel } from "src/app/Shared/Models/Base/imagem.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DetalhesFornecedorService {
  constructor(private http: HttpClient) {}

  buscaDetalhesPorFornecedorId(
    fornecedorId: string
  ): Observable<DadosPerfilModel> {
    return this.http
      .get<DadosPerfilModel>(
        `${environment.endpoints.backend}/perfil/${fornecedorId}/fornecedor/detalhes`
      )
      .pipe(pluck("dados"));
  }

  buscaServicosPorFornecedorId(
    fornecedorId: string
  ): Observable<Array<ServicosOfertadosAgrupadosModel>> {
    return this.http
      .get<Array<ServicosOfertadosModel>>(
        `${environment.endpoints.backend}/perfil/${fornecedorId}/fornecedor/detalhes/ObterServicos`
      )
      .pipe(pluck("dados"));
  }
  
  buscaFotosPorFornecedorId(
    fornecedorId: string
  ): Observable<Array<ImagemModel>> {
    return this.http
      .get<Array<ImagemModel>>(
        `${environment.endpoints.backend}/perfil/${fornecedorId}/fornecedor/detalhes/ObterFotos`
      )
      .pipe(pluck("dados"));
  }

  buscaPerguntasPorFornecedorId(
    fornecedorId: string
  ): Observable<Array<PerguntasFrequentesModel>> {
    return this.http
      .get<Array<PerguntasFrequentesModel>>(
        `${environment.endpoints.backend}/perfil/${fornecedorId}/fornecedor/detalhes/ObterPerguntasFrequentes`
      )
      .pipe(pluck("dados"));
  }
}
