import { TipoPlanoEnum } from '../Planos/Enums/tipo-plano.enum';
import { TipoPagamentoEnum } from '../Planos/Enums/tipo-pagamento.enum';
import { MinhaFuncionalidadeModel } from './minha-funcionalidade.model';
import { TipoUsuarioEnum } from './Enums/role.enum';

export class MeuPlanoModel {

    public id: string;
    public nome: string;
    public tipoPlano: TipoPlanoEnum;
    public tipoUsuario: TipoUsuarioEnum;
    public preco: number;
    public tipoPagamento: TipoPagamentoEnum;
    public dataCriacao: string;
    public dataFimPlano: string;
    public funcionalidades: MinhaFuncionalidadeModel[];

    constructor(init?: Partial<MeuPlanoModel>) {
        Object.assign(this, init);
    }
}