import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { PremiumActiveDialogComponent } from "src/app/AdminPanel/Widget/PopUp/PremiumActiveDialog/PremiumActiveDialog.component";
import { AuthenticationHelper } from "src/app/Services/helpers/authentication-helper.service";
import { PagamentoService } from "src/app/Services/pagamento.service";
import { PlanoService } from "src/app/Services/plano.service";

@Component({
  selector: "app-pagamento-cartao",
  templateUrl: "./pagamento-cartao.component.html",
  styleUrls: ["./pagamento-cartao.component.scss"],
})
export class PagamentoCartaoComponent implements OnInit {
  @Output()
  preco = new EventEmitter<number>();
  documentMask: string;

  paymentFormOne!: FormGroup;
  planoId: string;
  emailPattern: any = /\S+@\S+\.\S+/;

  constructor(
    private formBuilder: FormBuilder,
    private planoService: PlanoService,
    private pagamentoService: PagamentoService,
    private dialog: MatDialog,
    private authenticationHelper: AuthenticationHelper,
    private router: Router
  ) {
    this.paymentFormOne = this.formBuilder.group({
      user_details: this.formBuilder.group({
        first_name: ["", [Validators.required]],
        last_name: ["", [Validators.required]],
        street_name_number: ["", [Validators.required]],
        apt: ["", [Validators.required]],
        zip_code: ["", [Validators.required]],
        city_state: ["", [Validators.required]],
        country: ["", [Validators.required]],
        mobile: ["", [Validators.required]],
        email: [
          "",
          [Validators.required, Validators.pattern(this.emailPattern)],
        ],
        share_email: ["", [Validators.pattern(this.emailPattern)]],
      }),
      offers: this.formBuilder.group({
        discount_code: [""],
        card_type: [1],
        card_type_offer_name: [null],
      }),
      payment: this.formBuilder.group({
        card_number: ["", [Validators.required]],
        user_card_name: ["", [Validators.required]],
        cpf_card_titular: ["", [Validators.required]],
        cvv: ["", [Validators.required]],
        expiry_date: ["", [Validators.required]],
        card_id: [1],
        bank_card_value: [null],
      }),
    });
  }

  ngOnInit() {
    this.planoService.obterPlanoPago().subscribe((resposta) => {
      this.planoId = resposta.dados.id;
      this.preco.emit(resposta.dados.preco);
    });
  }

  onChangeDocument(documentValue: string): void {  
    if(documentValue.length > 14)
      this.documentMask = "00.000.000/0000-00";
    else{
      this.documentMask = "000.000.000-009";
    }
  }  

  submeterPagamento() {
    const paymentGroup = this.paymentFormOne.value; 
    const expiryDate = paymentGroup.payment.expiry_date
      .replace(/\s/g, "")
      .split("/");

    const cardData = {
      holderName: paymentGroup.payment.user_card_name,
      number: paymentGroup.payment.card_number.replace(/\s/g, ""),
      cpfOwner: paymentGroup.payment.cpf_card_titular.replace(/\s/g, ""),
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
      ccv: paymentGroup.payment.cvv,
      planoId: this.planoId,
    };

    this.pagamentoService
      .realizarAssinaturaRecorrente(cardData)
      .then((resposta) => {
        this.authenticationHelper.salvarToken(resposta.dados.accessToken);
        this.exibirDialogPremiumAtivada();
        this.router.navigate(["/perfil-fornecedor"]);
      });
  }

  exibirDialogPremiumAtivada() {
    let dialogRef: MatDialogRef<PremiumActiveDialogComponent>;
    dialogRef = this.dialog.open(PremiumActiveDialogComponent, {});
    return dialogRef.afterClosed();
  }
}
