import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	MatSelectModule,
	MatInputModule,
	MatFormFieldModule,
	MatIconModule,
	MatCardModule,
	MatButtonModule,
	MatProgressSpinnerModule,
	MatCheckboxModule,
	MatMenuModule,
	MatDialogModule,
	MatDatepickerModule,
	MatTableModule
} from '@angular/material';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';;
import { FlexLayoutModule } from '@angular/flex-layout';

import { TitleComponent } from './TitleComponent/TitleComponent.component';
import { TopsideMenuComponent } from './Menu/TopsideMenu/TopsideMenu.component';
import { DeleteListDialogComponent } from './PopUp/DeleteListDialog/DeleteListDialog.component';
import { SeeListDialogComponent } from './PopUp/SeeListDialog/SeeListDialog.component';
import { AddNewUserComponent } from './PopUp/AddNewUser/AddNewUser.component';
import { HeaderUserProfileDropdownComponent } from './HeaderUserProfileDropdown/HeaderUserProfileDropdown.component';
import { RouterModule } from '@angular/router';
import { BidAccessControlDialogComponent } from './PopUp/BidAccessControlDialog/BidAccessControlDialog.component';
import { PremiumActiveDialogComponent } from './PopUp/PremiumActiveDialog/PremiumActiveDialog.component';

@NgModule({
	declarations: [
		TitleComponent,
		TopsideMenuComponent,
		DeleteListDialogComponent,
		SeeListDialogComponent,
		AddNewUserComponent,
		HeaderUserProfileDropdownComponent,
		BidAccessControlDialogComponent,
		PremiumActiveDialogComponent
	],
	imports: [
		CommonModule,
		MatSelectModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		PerfectScrollbarModule,
		MatCheckboxModule,
		MatMenuModule,
		MatDialogModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatTableModule,
		FlexLayoutModule,
		RouterModule
	],
	exports: [
		TitleComponent,
		TopsideMenuComponent,
		HeaderUserProfileDropdownComponent,
		PremiumActiveDialogComponent
	],
	entryComponents: [
		DeleteListDialogComponent,
		SeeListDialogComponent,
		AddNewUserComponent,
		BidAccessControlDialogComponent,
		PremiumActiveDialogComponent
	]
})
export class WidgetModule { }
