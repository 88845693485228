import { TipoAnuncio } from "./../../../profile/components/orcamento/models/orcamento.model";
import { Component, HostListener, OnInit } from "@angular/core";
import { pluck } from "rxjs/operators";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { AnuncioService } from "src/app/Services/anuncio.service";
import { UserService } from "src/app/Services/user.service";
import { AnuncioModel } from "src/app/Shared/Models/Anuncios/anuncio.model";
import { StatusAnuncio } from "src/app/Shared/Models/Base/Enums/status-anuncio.enum";
import { TipoVisualizacaoAnuncioEnum } from "src/app/Shared/Models/Base/Enums/tipo-visualizacao-anuncio.enum";
import { ServicoEmAndamentoModel } from "src/app/Shared/Models/Servicos/servico-em-andamento.model";
import {
  AnuncioFornecedorStatus,
  OrcamentoFornecedorStatus,
} from "./utils/anuncio-fornecedor-status.utils";
import { Router } from "@angular/router";
import { FornecedorService } from "../../../../Services/fornecedor.service";

enum MenuEnum {
  BidsEmAndamento = 1,
  BidsVencidos = 2,
  BidsEncerrados = 3,
  OrcamentoPendente = 4,
  OrcamentoEnviado = 5,
}
@Component({
  selector: "app-meus-clientes",
  templateUrl: "./meus-clientes.component.html",
  styleUrls: ["./meus-clientes.component.scss"],
})
export class MeusClientesComponent implements OnInit {
  @HostListener("window:resize")
  public onResize(): void {
    this.verifyScreenSize();
  }
  menuEnum = MenuEnum;
  selectedTab = 1;
  inProgress = {
    categoria: 2,
    dataTerminoBid: "12/10/2023",
    id: "123",
    nomeServico: "teste",
    precoAbaixoDe: 40,
    primeiraImagem: "asd",
    primeiraImagemDescricao: "erwer",
    status: 2,
  } as ServicoEmAndamentoModel;
  selectedMenu = this.menuEnum.BidsEmAndamento;
  mobileView: boolean;
  user!: SignInModel;
  statusAnunciosEnum = StatusAnuncio;
  anuncioFornecedorStatus = AnuncioFornecedorStatus;
  orcamentoFornecedorStatus = OrcamentoFornecedorStatus;
  bids: Array<AnuncioModel> = [];
  orcamentos: Array<AnuncioModel> = [];
  tipoAnuncio = TipoAnuncio;
  lastSelectedStatus: OrcamentoFornecedorStatus | AnuncioFornecedorStatus;

  constructor(
    private anuncioService: AnuncioService,
    private userService: UserService,
    private fornecedorService: FornecedorService,
    private router: Router
  ) {
    this.user = userService.user;
  }

  ngOnInit() {
    this.verifyScreenSize();
    this.buscarBidsPorFornecedorIdEStatus(
      this.anuncioFornecedorStatus.EmAndamento,
      MenuEnum.BidsEmAndamento
    );
  }

  verifyScreenSize() {
    if (window.innerWidth <= 768) this.mobileView = true;
    else this.mobileView = false;
  }

  buscarBidsPorFornecedorIdEStatus(
    status: AnuncioFornecedorStatus,
    menuEnum: MenuEnum
  ) {
    this.lastSelectedStatus = status;
    this.selectedMenu = menuEnum;
    const data = {
      fornecedorId: this.user.id,
      status: status,
      ultimoMes: this.selectedTab === 1,
    };
    this.anuncioService
      .buscarBidsPorFornecedorIdEStatus(data)
      .subscribe((response: Array<AnuncioModel>) => {
        this.bids = response;
      });
  }
  buscarOrcamentosEnviadosPorFornecedorId(
    status: OrcamentoFornecedorStatus,
    menuEnum: MenuEnum
  ) {
    this.lastSelectedStatus = status;
    this.selectedMenu = menuEnum;
    const data = {
      fornecedorId: this.user.id,
      ultimoMes: this.selectedTab === 1,
    };

    this.anuncioService.buscarOrcamentosEnviadosPorFornecedorId(data).subscribe(
      (response: Array<AnuncioModel>) => {
        this.orcamentos = response;
      },
      () => (this.orcamentos = [])
    );
  }
  buscarOrcamentosPendentesPorFornecedorId(menuEnum: MenuEnum) {
    this.selectedMenu = menuEnum;

    this.fornecedorService
      .obterOrcamentosRecebidos(this.user.id, this.selectedTab === 1)
      .subscribe(
        (response) => {
          this.orcamentos = response;
        },
        () => (this.orcamentos = [])
      );
  }

  changeSelectionOfTabGroup() {
    if (
      this.selectedMenu === this.menuEnum.BidsEmAndamento ||
      this.selectedMenu === this.menuEnum.BidsVencidos ||
      this.selectedMenu === this.menuEnum.BidsEncerrados
    ) {
      this.buscarBidsPorFornecedorIdEStatus(
        this.lastSelectedStatus as AnuncioFornecedorStatus,
        this.selectedMenu
      );
    } else {
      switch (this.selectedMenu) {
        case this.menuEnum.OrcamentoEnviado:
          this.buscarOrcamentosEnviadosPorFornecedorId(
            this.lastSelectedStatus as OrcamentoFornecedorStatus,
            this.selectedMenu
          );
          break;
        case this.menuEnum.OrcamentoPendente:
          this.buscarOrcamentosPendentesPorFornecedorId(this.selectedMenu);
          break;
      }
    }
  }

  abrirDetalhesDoBid(id: string) {
    this.router.navigate(["detalhes-bid"], {
      queryParams: { id: id, route: "perfil-fornecedor" },
    });
  }
}
