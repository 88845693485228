import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { AuthenticationHelper } from "./helpers/authentication-helper.service";
import { Router } from "@angular/router";
import { ToastaService } from "ngx-toasta";
import { FornecedorVmModel } from "../Shared/Models/Fornecedores/ViewModels/fornecedor-vm.model";
import { AnuncioService } from "./anuncio.service";

@Injectable()
export class SessaoService extends BaseService {
  private readonly url = environment.endpoints.backend + "/usuario";
  fornecedorCadastrado: FornecedorVmModel;

  constructor(
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private router: Router,
    toastaService: ToastaService,
    private anuncioService: AnuncioService
  ) {
    super(toastaService, authenticationHelper);
  }

  cadastro(
    registerModel: any,
    redirect: string = null,
    callback: Function = null
  ) {
    this.http.post(`${this.url}`, registerModel).subscribe(
      (res: any) => {
        this.toastOptions.title = "Sucesso!";
        this.toastOptions.msg = "Cadastro realizado.";
        this.toastaService.success(this.toastOptions);

        if (redirect) {
          this.router.navigate([redirect]);
          return;
        }

        if (res.dados.tipoUsuario === 2) {
          this.fornecedorCadastrado = res.dados;
          if (callback) {
            callback();
          }
          return;
        }

        this.router.navigate(["/session/signin"]);
      },
      (error) => {
        this.manipuladorDeErro(error);
      }
    );
  }

  logar(
    login: { email: string; senha: string; rememberMe: boolean },
    redirect = ""
  ) {
    this.http.post(`${this.url}/login`, login).subscribe(
      (res: any) => {
        this.authenticationHelper.marcarParaLembrar(
          login.rememberMe ? "true" : "false"
        );
        this.authenticationHelper.salvarToken(res.dados.accessToken);

        const cadastrouAnuncio = this.anuncioService.cadastrarAnuncioSessao();
        if (cadastrouAnuncio) {
          return;
        }

        if (!redirect) {
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate([redirect]);
        }
      },
      (error) => {
        this.manipuladorDeErro(error, true);
      }
    );
  }

  gerarNovoToken() {
    this.http.post(`${this.url}/novo-token`, {}).subscribe(
      (res: any) => {
        this.authenticationHelper.salvarToken(res.dados.accessToken);
      },
      (error) => {
        this.manipuladorDeErro(error, true);
      }
    );
  }

  esqueciMinhaSenha(forgotPassword: any) {
    this.http.post(`${this.url}/forgotpassword`, forgotPassword).subscribe(
      (res) => {
        this.toastOptions.title = "Sucesso!";
        this.toastOptions.msg = "E-mail enviado com sucesso.";
        this.toastaService.success(this.toastOptions);
      },
      (error) => {
        this.manipuladorDeErro(error);
      }
    );
  }

  sair() {
    this.authenticationHelper.removerToken();
  }
}
