import { MatPaginatorIntl } from '@angular/material';

const changeRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { 
    return `0 de ${length}`; 
  }
  
  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
}

export function CustomPaginatorConfig() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.firstPageLabel = "Primeira página";
  customPaginatorIntl.lastPageLabel = "Última página";
  customPaginatorIntl.nextPageLabel = "Próxima página";
  customPaginatorIntl.previousPageLabel = "Página anterior";
  customPaginatorIntl.getRangeLabel = changeRangeLabel;

  return customPaginatorIntl;
}