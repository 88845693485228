export interface BancosModel {
  COMPE: number;
  ShortName: string;
}

export enum TipoContaEnum {
  Corrente = 1,
  Pupanca = 2,
}

export interface DadosBancariosModel {
  banco: BancosModel;
  agencia: string;
  conta: string;
  chavePix: string;
  documento: string;
  nomeFavorecido: string;
}
