import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AnuncioService } from 'src/app/Services/anuncio.service';
import { RespostaModel } from 'src/app/Shared/Models/Base/resposta.model';
import { FornecedorMelhorAvaliadoVmModel } from 'src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-melhor-avaliado-vm.model';

@Component({
  selector: 'app-fornecedor-melhor-avaliado',
  templateUrl: './fornecedor-melhor-avaliado.component.html',
  styleUrls: ['./fornecedor-melhor-avaliado.component.css']
})
export class FornecedorMelhorAvaliadoComponent implements OnInit {
  
  fornecedores$: Observable<RespostaModel<FornecedorMelhorAvaliadoVmModel[]>> = new Observable;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             arrows: false,
             slidesToShow: 2,
             slidesToScroll: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             arrows: false,
             slidesToShow: 2,
             slidesToScroll: 2
          }
       },
       {
          breakpoint: 480,
          settings: {
             arrows: false,
             slidesToShow: 1,
             slidesToScroll: 1
          }
       }
    ]
  };

  constructor(
    private anuncioService: AnuncioService
  ) {
    
  }

   ngOnInit(): void {
      this.fornecedores$ = this.anuncioService
         .buscarFornecedoresMelhoresAvaliados();
   }

   obterUrlFornecedor(fornecedor: FornecedorMelhorAvaliadoVmModel) {
      const urlFornecedor = (fornecedor.nomeFantasia) ? 
         fornecedor.nomeFantasia.replace(/ /g, '-') : ''; 

      return '/fornecedores/' +  urlFornecedor;
   }

   obterImagemPerfil(fornecedor: FornecedorMelhorAvaliadoVmModel) {
      if (fornecedor.urlImagem) {
         return fornecedor.urlImagem;
      }
  
      return 'assets/images/log-in-user.jpg';
    }

}