import { Routes } from "@angular/router";

import { MainComponent } from "./Main/Main.component";
import { HomeoneComponent } from "./Pages/Home/HomeOne/HomeOne.component";
import { CartComponent } from "./Pages/Cart/Cart.component";
import { NotFoundComponent } from "./Pages/NotFound/NotFound.component";
import { DetalhesFornecedorComponent } from "./Pages/Home/pages/detalhes-fornecedor/components/detalhes-fornecedor/detalhes-fornecedor.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "home",
        component: HomeoneComponent,
      },
      {
        path: "detalhes-fornecedor/:id/:categoria",
        component: DetalhesFornecedorComponent,
        pathMatch: "full",
      },
      {
        path: "empresa",
        loadChildren: () =>
          import("./Pages/Business/Business.module").then(
            (m) => m.BusinessModule
          ),
      },
      {
        path: "premium",
        loadChildren: () =>
          import("./Pages/Premium/Premium.module").then((m) => m.PremiumModule),
      },
      {
        path: "plano",
        loadChildren: () =>
          import("./Pages/Orders/Order.module").then((m) => m.OrderModule),
      },
      {
        path: "products",
        loadChildren: () =>
          import("./Pages/Products/Products.module").then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: "cart",
        component: CartComponent,
      },
      {
        path: "not-found",
        component: NotFoundComponent,
      },
      {
        path: "session",
        loadChildren: () =>
          import("./Pages/Session/Session.module").then((m) => m.SessionModule),
      },
      {
        path: "checkout",
        loadChildren: () =>
          import("./Pages/Checkout/Checkout.module").then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./Pages/About/About.module").then((m) => m.AboutModule),
      },
      {
        path: "blogs",
        loadChildren: () =>
          import("./Pages/Blogs/Blogs.module").then((m) => m.BlogsModule),
      },
      {
        path: "bids",
        loadChildren: () =>
          import("./Pages/Bids/Bids.module").then((m) => m.BidsModule),
      },
      {
        path: "servicos",
        loadChildren: () =>
          import("./Pages/Services/Services.module").then(
            (m) => m.ServicesModule
          ),
      },
      {
        path: "conta",
        loadChildren: () =>
          import("./Pages/UserAccount/UserAccount.module").then(
            (m) => m.UserAccountModule
          ),
      },
      {
        path: "fornecedores",
        loadChildren: () =>
          import("./Pages/Fornecedores/fornecedores.module").then(
            (m) => m.FornecedoresModule
          ),
      },
      {
        path: "painel-gestao",
        loadChildren: () =>
          import("./Pages/PainelGestao/painel-gestao.module").then(
            (m) => m.PainelGestaoModule
          ),
      },
      {
        path: "orcamentos",
        loadChildren: () =>
          import("./Pages/Orcamentos/orcamentos.module").then(
            (m) => m.OrcamentosModule
          ),
      },
      {
        path: "notificacoes",
        loadChildren: () =>
          import("./Pages/Notificacoes/Notificacoes.module").then(
            (m) => m.NotificacoesModule
          ),
      },
      {
        path: "perfil",
        loadChildren: () =>
          import("./Pages/profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "perfil-fornecedor",
        loadChildren: () =>
          import("./Pages/perfil-fornecedor/perfil-fornecedor.module").then(
            (m) => m.PerfilFornecedorModule
          ),
      },
      {
        path: "detalhes-bid",
        loadChildren: () =>
          import("./Shared/components/bid-details/bid-details.module").then(
            (m) => m.BidDetailsModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "not-found",
  },
];
