import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    let user =
      localStorage.getItem("bidUser") || sessionStorage.getItem("bidUser");

      console.log('Passou por aqui !!');

    const HAVE_LOGGED_USER = user || "";
    if (!!HAVE_LOGGED_USER.length) return !!HAVE_LOGGED_USER.length;
    else {
      this.router.navigate(["/home"]);
      return false;
    }
  }
}
