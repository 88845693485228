import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpRequest, HttpClient, HttpEventType, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { FileUploadModel } from 'src/app/Shared/Models/Base/file-upload.model';
import { PerfilService } from 'src/app/Services/perfil.service';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';

@Component({
    selector: 'app-bid-upload',
    templateUrl: './bid-upload.component.html',
    styleUrls: ['./bid-upload.component.css'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 100 })),
            transition('* => void', [
                animate(300, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class BidUploadComponent implements OnInit {

    /** Link text */
    @Input() text = 'Selecionar arquivos';
    /** Name used in form which will be sent in HTTP request. */
    @Input() param = 'file';
    /** Target URL for file uploading. */
    @Input() target = ''; // 'https://file.io';
    /** File extension that accepted, same as 'accept' of <input type="file" />. 
        By the default, it's set to 'image/*'. */
    @Input() accept = '*/*';
    /** Allow you to add handler after its completion. Bubble up response text from remote. */
    @Output() complete = new EventEmitter<string>();

    files: Array<FileUploadModel> = [];

    @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

    constructor(
        private _http: HttpClient,
        private perfilService: PerfilService,
        private authenticationHelper: AuthenticationHelper
    ) { }

    ngOnInit() {
    }

    private removeFileFromArray(file: FileUploadModel) {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
        }
    }

    private uploadFiles() {
        const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
        fileUpload.value = '';

        this.files.forEach(file => {
            this.uploadFile(file);
        });
    }

    private uploadFile(file: FileUploadModel) {
        const fd = new FormData();
        fd.append(this.param, file.data);

        const req = new HttpRequest('POST', this.target, fd, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.authenticationHelper.obterToken()}`
            }),
            reportProgress: true
        });

        file.inProgress = true;
        file.sub = this._http.request(req).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        file.progress = Math.round(event.loaded * 100 / event.total);
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            tap(message => { }),
            last()
        ).subscribe(
            (event: any) => {
                if (typeof (event) === 'object') {
                    this.removeFileFromArray(file);
                    this.complete.emit(event.body);
                    this.perfilService.uploadFotoCompletoEvent.emit(event.body);
                }
            },
            error => {
                this.perfilService.manipuladorDeErro(error);
                file.progress = 0;
                file.inProgress = false;
                file.canRetry = true;
            }
        );
    }

    cancelFile(file: FileUploadModel) {
        file.sub.unsubscribe();
        this.removeFileFromArray(file);
    }

    retryFile(file: FileUploadModel) {
        this.uploadFile(file);
        file.canRetry = false;
    }


    onClick() {
        const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
        fileUpload.onchange = () => {
            for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.files.push({
                    data: file, state: 'in',
                    inProgress: false, progress: 0, canRetry: false, canCancel: true
                });
            }
            this.uploadFiles();
        };
        fileUpload.click();
    }

}
