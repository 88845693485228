import { Endereco } from "src/app/Pages/profile/components/orcamento/models/orcamento.model";
import { CategoriaServicoEnum } from "../../Servicos/Enums/categoria-servico.enum";

export class FornecedorMelhorAvaliadoVmModel {
  idFornecedor: string;
  nomeFantasia: string;
  media: number;
  totalAvaliacoes: number;
  urlImagem: string;
  totalBidsFechados: string;
  endereco: Endereco;
  tipoServico: string;
  quantidadeAvaliacoes: number;
  valorMinimo: number;
  categoria: CategoriaServicoEnum;
  
  constructor(init?: Partial<FornecedorMelhorAvaliadoVmModel>) {
    Object.assign(this, init);
  }
}
