import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { PerfilService } from "src/app/Services/perfil.service";
import {
  BancosModel,
  DadosBancariosModel,
  TipoContaEnum,
} from "./models/dados-bancarios.model";
import { UserService } from "src/app/Services/user.service";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";
import { debounceTime, map } from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: "app-dados-bancarios",
  templateUrl: "./dados-bancarios.component.html",
  styleUrls: ["./dados-bancarios.component.scss"],
})
export class DadosBancariosComponent implements OnInit {
  @Output()
  dadosBancarios = new EventEmitter<DadosBancariosModel>();

  form!: FormGroup;
  bancos$: Observable<BancosModel>;
  user: SignInModel;
  tipoContas = TipoContaEnum;
  documentMask: string;

  constructor(
    private formBuilder: FormBuilder,
    private perfilService: PerfilService,
    private userService: UserService
  ) {
    this.form = formBuilder.group({
      banco: [""],
      conta: [""],
      agencia: [""],
      pix: [""],
      documento: [""],
      nomeFavorecido: [""],
      tipoIdentidade: [""],
    });
    this.user = userService.user;
    this.bancos$ = this.perfilService.obterListaDeBancos(this.user.id);
  }

  ngOnInit() {
    this.obterDadosBancarios();

    this.form
      .get("tipoIdentidade")
      .valueChanges.subscribe((changes: number) => {
        switch (Number(changes)) {
          case 1:
            this.documentMask = "000.000.000-00";
            break;
          case 2:
            this.documentMask = "00.000.000/0000-00";
            break;
        }
      });
  }

  private obterDadosBancarios() {
    this.perfilService
      .obterDadosBancarios(this.user.id)
      .subscribe((response) => {
        this.form.patchValue({
          banco: response.banco,
          conta: response.conta,
          agencia: response.agencia,
          pix: response.chavePix,
          documento: response.documento,
          nomeFavorecido: response.nomeFavorecido,
          tipoIdentidade: response.documento.length <= 11 ? 1 : 2,
        });

        this.form.updateValueAndValidity();
      });
  }

  postAdicionarDadosBancarios() {
    const form = this.form.value;

    const data = {
      agencia: form.agencia,
      banco: form.banco,
      chavePix: form.pix,
      conta: form.conta,
      documento: form.documento,
      nomeFavorecido: form.nomeFavorecido,
    } as DadosBancariosModel;
    this.perfilService
      .postAdicionarDadosBancarios(this.user.id, data)
      .subscribe(() => {
        Swal.fire(
          "Sucesso",
          "Dados bancários atualizados com sucesso!",
          "success"
        );
      });
  }
}
