import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RespostaModel } from '../Shared/Models/Base/resposta.model';
import { BaseService } from './base.service';
import { AuthenticationHelper } from './helpers/authentication-helper.service';
import { Router } from '@angular/router';
import { ToastaService } from 'ngx-toasta';
import { TipoOrdemEnum } from '../Shared/Models/Ordens/Enums/tipo-ordem.enum';
import { OrdemServicoVmModel } from '../Shared/Models/Ordens/ViewModels/ordem-servico-vm.model';

@Injectable()
export class OrdensPagamentoService extends BaseService {

  private readonly url = environment.endpoints.backend + '/ordempagamento';

  constructor(
    toastaService: ToastaService,
    private http: HttpClient,
    authenticationHelper: AuthenticationHelper,
    private router: Router
  ) {
    super(toastaService, authenticationHelper );
  }

  obterIframeInfo(): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(`${this.url}/iframe-info`);
  }

  obterIframeInfoAlteracaoCartaoCredito(): Observable<RespostaModel<any>> {
    return this.http.get<RespostaModel<any>>(`${this.url}/iframe-info-mudanca-cartaocredito`);
  }

  gerarOrdem(ordem: any): Observable<RespostaModel<any>> {
    return this.http.post<RespostaModel<any>>(`${this.url}/geracao-ordem`, ordem);
  }

  alteracaoCartao() {
    return this.http.post<RespostaModel<any>>(`${this.url}/alterar-cartao-assinatura`, {});
  }

  cancelarAssinatura() {
    return this.http.post<RespostaModel<any>>(`${this.url}/cancelamento-assinatura`, {});
  }

  obterOrdens(clienteId: string, tipoOrdem: TipoOrdemEnum = null, encerrada: boolean = null):
    Observable<RespostaModel<OrdemServicoVmModel[]>> {

    if (tipoOrdem != null && tipoOrdem != undefined && encerrada != null && encerrada != undefined) {
      return this.http.get<RespostaModel<OrdemServicoVmModel[]>>
        (`${this.url}/${clienteId}?tipoOrdem=${tipoOrdem}&encerrada=${encerrada}`);
    } else if (encerrada !== null && encerrada !== undefined) {
      return this.http.get<RespostaModel<OrdemServicoVmModel[]>>
        (`${this.url}/${clienteId}?encerrada=${encerrada}`);
    } else {
      return this.http.get<RespostaModel<OrdemServicoVmModel[]>>
        (`${this.url}/${clienteId}`);
    }
  }

}
