import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ServicoEmAndamentoModel } from "../../Models/Servicos/servico-em-andamento.model";

@Component({
  selector: "app-in-progress-bids-horizontal",
  templateUrl: "./in-progress-bids-horizontal.component.html",
  styleUrls: ["./in-progress-bids-horizontal.component.scss"],
})
export class InProgressBidsHorizontalComponent implements OnInit {
  @Input()
  service: ServicoEmAndamentoModel;

  @Output()
  clickEvent = new EventEmitter();

  imagens: Array<string> = [];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
  };
  constructor() {}

  ngOnInit() {
    this.preparaImagensParaCarrousel();
  }

  private preparaImagensParaCarrousel() {
    if (this.service.imagens) {
      this.imagens = this.service.imagens.map((imagem) => imagem.url);
    } else {
      const imagensPossiveisKeys = [
        "primeiraImagem",
        "segundaImagem",
        "terceiraImagem",
      ];

      imagensPossiveisKeys.forEach((key) => {
        if (this.service[key]) {
          this.imagens.push(this.service[key]);
        }
      });
    }
  }
}
