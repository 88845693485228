import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ZonaModel } from "../Shared/Models/Base/zona.model";
import { EstadoModel } from "../Shared/Models/Base/estado.model";
import { CidadeModel } from "../Shared/Models/Base/cidade.model";
import { RespostaModel } from "../Shared/Models/Base/resposta.model";
import { BairroModel } from "../Shared/Models/Base/bairro.model";

@Injectable({ providedIn: "root" })
export class LocalidadeService {
  private readonly url = environment.endpoints.backend + "/localidades";
  private httpHeaders: HttpHeaders = new HttpHeaders({ loading: "false" });

  constructor(private http: HttpClient) {}

  obterZonas(): Observable<RespostaModel<ZonaModel[]>> {
    return this.http.get<RespostaModel<ZonaModel[]>>(`${this.url}/zonas`, {
      headers: this.httpHeaders,
    });
  }

  obterEstados(): Observable<RespostaModel<EstadoModel[]>> {
    return this.http.get<RespostaModel<EstadoModel[]>>(`${this.url}/estados`, {
      headers: this.httpHeaders,
    });
  }

  obterCidades(estadoId): Observable<RespostaModel<CidadeModel[]>> {
    return this.http.get<RespostaModel<CidadeModel[]>>(
      `${this.url}/cidades?estadoId=${estadoId}`,
      { headers: this.httpHeaders }
    );
  }

  obterBairros(cidadeIds): Observable<RespostaModel<BairroModel[]>> {
    return this.http.post<RespostaModel<BairroModel[]>>(
      `${this.url}/bairros`,
      cidadeIds,
      { headers: this.httpHeaders }
    );
  }
}
