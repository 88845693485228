import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { SignInModel } from "src/app/Pages/Session/SignIn/models/signin.model";

@Injectable()
export class FornecedorGuard implements CanActivate {
  constructor() {}
  canActivate(): boolean {
    let user =
      localStorage.getItem("bidUser") || sessionStorage.getItem("bidUser");

    const HAVE_LOGGED_USER = user || "";
    if (!!HAVE_LOGGED_USER) {
      const formatedUser = JSON.parse(user) as SignInModel;
      return formatedUser.tipoUsuario === 2;
    } else return false;
  }
}
