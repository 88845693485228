import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FornecedorModel } from 'src/app/Shared/Models/Fornecedores/fornecedor.model';
import { FornecedorAvaliacaoNodeModel } from 'src/app/Shared/Models/Fornecedores/ViewModels/fornecedor-avaliacao-node.model';
import { TokenModel } from 'src/app/Shared/Models/Base/token.model';
import { FornecedorService } from 'src/app/Services/fornecedor.service';
import { ClienteBaseService } from 'src/app/Services/cliente.servico';
import { AuthenticationHelper } from 'src/app/Services/helpers/authentication-helper.service';
import { AvaliacaoAtualizadaEvent } from 'src/app/Shared/Events/avaliacao-atualizada-event';
import { AvaliacaoAtualizacaoModel } from 'src/app/Shared/Models/Fornecedores/avaliacao-atualizacao.model';
import { TipoUsuarioEnum } from 'src/app/Shared/Models/Base/Enums/role.enum';
import { Lightbox } from 'ngx-lightbox';
import { MatDialog } from '@angular/material/dialog';
import GLightbox from 'glightbox';

class FornecedorAvaliacaoNodeFormatadoModel extends FornecedorAvaliacaoNodeModel {
  public exibirBotaoResponder: boolean;
}

@Component({
  selector: 'app-fornecedor-avaliacao',
  templateUrl: './fornecedor-avaliacao.component.html',
  styleUrls: ['./fornecedor-avaliacao.component.css']
})
export class FornecedorAvaliacaoComponent implements OnInit, OnChanges {

  private _fornecedor: FornecedorModel;

  get fornecedor(): FornecedorModel {
    return this._fornecedor;
  }

  @Input()
  set fornecedor(fornecedor: FornecedorModel) {
    this._fornecedor = new FornecedorModel(fornecedor);
  }

  @Input()
  exibirEstrelas = true;

  @Input()
  avaliacoes: FornecedorAvaliacaoNodeModel[] = [];
  avaliacoesFormatadas: FornecedorAvaliacaoNodeFormatadoModel[] = [];

  texto = '';
  usuarioLogado: TokenModel;
  anexos: any[];

  constructor(
    private fornecedorSerice: FornecedorService,
    private clienteService: ClienteBaseService,
    private authenticationHelper: AuthenticationHelper,
    private avaliacaoAtualizacaoEvent: AvaliacaoAtualizadaEvent,
    private lightbox: Lightbox,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.usuarioLogado = this.authenticationHelper
      .obterTokenModel();

    this.avaliacoesFormatadas = this.mapearAvaliacoes(this.fornecedor.avaliacoes);
    this.avaliacaoAtualizacaoEvent.onAvaliacaoAtualizada
      .subscribe((avaliacao: AvaliacaoAtualizacaoModel) => {

        if (this.fornecedor.id === avaliacao.fornecedorId) {
          this.avaliacoesFormatadas = this.mapearAvaliacoes(avaliacao.avaliacoes);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.usuarioLogado) {
      this.usuarioLogado = this.authenticationHelper
        .obterTokenModel();
    }

    this.avaliacoesFormatadas = this.mapearAvaliacoes(this.fornecedor.avaliacoes);
  }

  private criarThumbImagensAvaliacao(imagens: any[]): any[] {
    const urls: any = [];
    if (imagens && imagens.length > 0) {
      imagens
          .forEach(foto => {
            urls.push({
                'href': foto.url || foto,
                'type': (foto.formato === 'mp4') ? 'video' : 'image'
              });
          });
    }

    return urls;
  }

  mapearAvaliacoes(avaliacoes: any) {
    if (!avaliacoes)
      return [];

    return avaliacoes.map(e => ({
      ...e,
      thumbs: this.criarThumbImagensAvaliacao(e.imagens),
      respostas: this.mapearAvaliacoes(e.respostas),
      exibirBotaoResponder: this.exibirBotaoResposta(e)
    }));
  }

  abrirParaResposta(avaliacao: FornecedorAvaliacaoNodeModel) {
    this.texto = '';
    avaliacao.estaAbertoParaResposta = !avaliacao.estaAbertoParaResposta;
  }

  atualizarRespostaAvaliacao(currentAvaliacoes: Array<FornecedorAvaliacaoNodeModel>,
    avaliacao: FornecedorAvaliacaoNodeModel, resposta: FornecedorAvaliacaoNodeModel) {

      currentAvaliacoes.forEach((value, index) => {

        if ((value.autorId === avaliacao.autorId) &&
          (value.texto === avaliacao.texto) &&
          (value.data == avaliacao.data)) {
          if (!value.respostas) {
            value.respostas = new Array<FornecedorAvaliacaoNodeModel>();
          }

          value.respostas.push(resposta);
          return;
        }

        if (value.respostas) {
          this.atualizarRespostaAvaliacao(value.respostas, avaliacao, resposta);
        }

      });
  }

  adicionarResposta(avaliacao: FornecedorAvaliacaoNodeModel) {

    const resposta = new FornecedorAvaliacaoNodeModel({
      fornecedorId: this.fornecedor.id,
      usuarioId: avaliacao.usuarioId,
      autorId: this.usuarioLogado.cliente,
      autor: this.usuarioLogado.name,
      email: this.usuarioLogado.email,
      texto: this.texto,
      data: new Date()
    });

    if (!avaliacao.respostas) {
      avaliacao.respostas = new Array<FornecedorAvaliacaoNodeModel>();
    }

    avaliacao.respostas.push(resposta);
    avaliacao.estaAbertoParaResposta = false;
    this.texto = '';

    this.atualizarRespostaAvaliacao(this.fornecedor.avaliacoes, avaliacao, resposta);

    const avaliacaoAtualizacao = new AvaliacaoAtualizacaoModel({
      fornecedorId: this.fornecedor.id,
      clienteId: avaliacao.autorId,
      avaliacoes: this.avaliacoes
    });
    
    if (this.usuarioLogado.role == TipoUsuarioEnum.Cliente) {
      this.clienteService.atualizarAvaliacao(avaliacaoAtualizacao)
        .then((response) => {});
    }
    else {
      this.fornecedorSerice.atualizarAvaliacao(avaliacaoAtualizacao)
        .then((response) => {});
    }
  }

  exibirBotaoResposta(avaliacao: FornecedorAvaliacaoNodeModel) {
    
    if (!this.usuarioLogado) {
      return false;
    }

    if (this.fornecedor.id === this.usuarioLogado.cliente) {

      if (avaliacao.autorId === this.usuarioLogado.cliente) {
        return false;
      }

      if (avaliacao.autorId !== this.fornecedor.id) {
        return true;
      }
    }
    else {

      if ((avaliacao.autorId === this.usuarioLogado.cliente) && 
        (avaliacao.usuarioId === this.usuarioLogado.cliente)) {
          return false;
      }

      if ((avaliacao.autorId !== this.usuarioLogado.cliente) &&
        (avaliacao.usuarioId !== this.usuarioLogado.cliente)) {
          return false;
      }

      if ((avaliacao.autorId === this.fornecedor.id) &&
        (avaliacao.usuarioId === this.usuarioLogado.cliente)) {
          return true;
      }
    }

    return false;
  }

  exibirImagemAvaliacao(avaliacao: any, index: number) {
    const gallery = GLightbox({
      touchNavigation: true,
      loop: true,
      startAt : index,
      autoplayVideos: false
    });
    gallery.setElements(avaliacao.thumbs);
    gallery.open();
  }
}
